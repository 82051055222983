import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    title: {
        textAlign: 'center'
    },
    content: {
        minHeight: theme.spacing(15),
        textAlign: 'center'
    }
});

class OnboardingText extends React.Component {
    render() {
        const {
            classes,
            t,
            step
        } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h1" classes={{ root: classes.title }} gutterBottom>
                    {t(step.title)}
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    gutterBottom
                    classes={{ root: classes.content }}
                >
                    {t(step.content)}
                </Typography>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(styles)(OnboardingText))