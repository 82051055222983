export default {
    INIT:           'CAMPAIGN_INIT',
    GET_SINGLE:     'CAMPAIGN_GET_SINGLE',
    GET_LIST:       'CAMPAIGN_GET_LIST',
    REMOVE:         'CAMPAIGN_REMOVE',
    CREATE:         'CAMPAIGN_CREATE',
    UPDATE:         'CAMPAIGN_UPDATE',
    UPDATE_PRODUCTS:'CAMPAIGN_UPDATE_PRODUCTS',
    ERROR:          'CAMPAIGN_ERROR',
    LOADING:        'CAMPAIGN_LOADING',
    LOADING_PARTIAL:'CAMPAIGN_LOADING_PARTIAL',
    CLEAR:          'CAMPAIGN_CLEAR',
    GET_TEMPLATE:   'CAMPAIGN_GET_TEMPLATE',
    LANE:           'CAMPAIGN_LANE'
}
