import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../helpers';
import CampaignAction from '../../../reducers/Campaign/campaign.actions';
import LayoutBody from '../../../components/LayoutBody.component';
import TrainingsList from './components/Trainings.list.component.jsx';
import FixScrollbar from '../../../components/FixScrollbar.component';
import { withStyles } from '@material-ui/core/styles';
import StepHeader from '../../../components/StepHeader';
import CampaignHeader from '../../../components/CampaignHeader.component';
import CampaignFooter from '../../../components/CampaignFooter.component';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from '@material-ui/core';

const styles = theme => ({
    trainingsContainer: {
        marginTop: -24,
    },
    button: {
        marginLeft: theme.spacing(1), 
        margnRight: theme.spacing(1), 
    },
    link: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(4.5) ,
        cursor: 'pointer'
    }
});

class Trainings extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            CourseList: campaign.assets.CourseList,
            current: campaign.current,
            lane: campaign.lane.id,
            language: campaign.current.data.language,
            steps: campaign.lane.steps,
            material: campaign.material,
            canEdit: campaign.current.trainingsReadOnly !== '1',
            isAdmin: campaign.user.roles && campaign.user.roles.includes('admin')
        };
    }

    constructor(props) {
        super(props);

        const { current, material } = props;
        
        const courseLanguage = Core.campaignLanguage(current.data.language, 'course');
        const trainings = current.data.trainings || {};
        const filteredTrainings = {}
        Object.keys(trainings).forEach(key => {
            if (trainings[key].Language === courseLanguage || trainings[key].Language === '' ) {
                filteredTrainings[key] = trainings[key]
            }
        })

        const view = current.data.lane.id === 'campaign' ? 'presets' : 'edit';
        const required = this.getRequired();

        this.state = {
            itemToEdit: {
                trainings: filteredTrainings,
            },
            view,
            required
        }
    }

    componentDidMount() {
        const {
            current,
            onSelectStep,
            lastStep,
            activeStep
        } = this.props;
        const { itemToEdit, required } = this.state;

        if ((required === 0 && current.type === 'products') || (required === 0 && current.type === 'campaign') ) {
            const dir = lastStep > activeStep ? -1 : 1;
            onSelectStep(undefined, dir, itemToEdit, true)
        }
    }

    switchView = () => {
        this.setState({view: 'edit'})
    }

    getRequired = () => {
        const {
            current,
            material
        } = this.props;

        const products = current.data.products;
        const materialDef = Core.arrayToObject(material, 'key');

        let required = 0
        if (products) {
            Object.keys(products).forEach( product => {
                if (materialDef[product] && materialDef[product].customizeFields && materialDef[product].customizeFields.length > 0) {
                    materialDef[product].customizeFields.forEach(field =>{
                        if (field.type.split('-')[0] === 'trainings') {
                            required = 1;
                        }
                        else if (field.type.split('-')[0] === 'trainingsvariant') {
                            required = 1;
                        }
                    })
                }
            })
        }
        return required;

    }

    handleChange = (e, key) => {
        const { CourseList } = this.props;
        const { itemToEdit } = this.state;
        let trainings = itemToEdit.trainings;

        if (e && e.target.checked) {
            const training = CourseList.find((item) => item.CourseId === key && item.Language === Core.campaignLanguage(this.props.language, 'course'));

            if (training) {
                trainings = {
                    ...trainings,
                    [training.CourseId]: training
                }
            }
        } else {
            delete trainings[key]
        }

        this.setState({
            itemToEdit: { trainings }
        })
    }

    checkArray = () => {
        const {
            itemToEdit
        } = this.state;
        const {
            language
        } = this.props;

        // Filter selected trainings on campaign language
        const languageTrainings = Object.keys(itemToEdit.trainings).filter(key => itemToEdit.trainings[key].Language === Core.campaignLanguage(language, 'course') || itemToEdit.trainings[key].Language === '')

        return languageTrainings;
    }

    checkStep = (checkArray) => {
        const {
            required
        } = this.state;
        return checkArray.length >= required
    }

    /**
     * Check if all trainings saved in the campaign still exist and delelte if they don't.
     */
    removeOldTrainings = () => {
        const {
            itemToEdit
        } = this.state;
        const {
            CourseList
        } = this.props;

        const allTrainingsObject = Core.arrayToObject(CourseList, 'CourseId');
        const selTrainingsObject = itemToEdit.trainings;

        for (let t in selTrainingsObject) {
            if (!allTrainingsObject[t]) {
                delete selTrainingsObject[t];
            }
        }
        this.setState({itemtoEdit: {
            ...itemToEdit,
            trainings: selTrainingsObject
        }})
    }
    
    render () {
        const {
            classes,
            t,
            steps,
            activeStep,
            getCurrentStep,
            canEdit,
            isAdmin,
            onSelectStep,
            onClickStep,
        } = this.props;
        const {
            view,
            itemToEdit,
        } = this.state;
        const checkArray = this.checkArray();
        const stepIsCompleted = this.checkStep(checkArray);

        let bodyWidth = 'medium';

        return (
            <React.Fragment>
                <FixScrollbar />
                <StepHeader
                    itemToEdit={itemToEdit}
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={onClickStep}
                    stepIsCompleted={stepIsCompleted}
                    lane={this.props.lane}
                    helpText={t('common.header.button')}
                />
                <CampaignHeader
                    title={t('trainings.edit.title')}
                    intro={t('trainings.edit.intro')}
                />
                <LayoutBody
                    component="section"
                    width={bodyWidth}
                    textAlignCenter
                    paddingBottom
                    className={classes.trainingsContainer}
                >
                    <TrainingsList
                        view={view}
                        checkArray={checkArray}
                        selectedTrainings={itemToEdit.trainings}
                        onHandleChange={this.handleChange}
                        onRemoveOldTrainings={this.removeOldTrainings}
                    />
                </LayoutBody>
                <CampaignFooter
                    onSelectPreviousStep={() => onSelectStep(false, -1, itemToEdit, stepIsCompleted)}
                    onSelectNextStep={() => onSelectStep(false, 1, itemToEdit, stepIsCompleted)}
                    previousStepIcon={<ArrowBackIcon fontSize="small" />}
                    nextStepIcon={<ArrowForwardIcon fontSize="small" />}
                    nextStepDisabled={!stepIsCompleted}
                    getCurrentStep={getCurrentStep}
                >
                    {view === "presets" && <Link onClick={this.switchView} className={classes.link} underline="always" variant="body2">{t('common.buttons.modify')}</Link>}
                </CampaignFooter>
            </React.Fragment>
        )
    }
}

export default  withTranslation()(withStyles(styles)(
    connect(Trainings.mapStateToProps, {
        update: CampaignAction.update
    })(Trainings)
));