import { createTheme } from '@material-ui/core/styles';

// Reset shadows to fit design.
const shadows = Array(25).fill('0px 8px 8px 1px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.2)');
shadows[0] = 'none';

const textGrey = '#868686';

const heading = {
    color: '#333333',
    fontWeight: 'bold',
    lineHeight: 1.25,
    textAlign: 'left',
    fontFamily: '"Circular", Arial, Helvetica, sans-serif',
}

const title = {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    lineHeight: 1.25,
    fontFamily: '"Circular Book", Arial, Helvetica, sans-serif',
}

const caption = {
    color: textGrey,
    fontSize: '0.9rem',
    lineHeight: 1.25,
    fontFamily: '"Circular Book", Arial, Helvetica, sans-serif',
}

const body = {
    color: textGrey,
    fontSize: '1rem',
    lineHeight: 1.25,
    textAlign: 'left',
    fontFamily: '"Circular Book", Arial, Helvetica, sans-serif',
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#5A1EEC',
        },
        secondary: {
            main: '#67D8FD',
            light: '#c1f0ff',
            contrastText: '#FFFFFF'
        },
        background: {
            default: '#FAFAFA',
            dark: '#333333'
        },
        text: {
            primary: '#46403D'
        },
        custom: {
            yellow: '#F8C341',
            purple: '#5A1EEC',
            pink: '#FA0ECC',
            green: '#7AFFB9'
        }
    },
    typography: {
        useNextVariants: true,
        h1: {
            ...heading,
            fontSize: '2rem'
        },
        h2: {
            ...heading,
            fontSize: '1.25rem'
        },
        h3: {
            ...heading,
            fontSize: '1rem'
        },
        h4: {
            ...heading,
            fontSize: '0.938rem'
        },
        h5: {
            ...heading,
            fontSize: '0.813rem'
        },
        h6: {
            ...heading,
            fontSize: '0.813rem'
        },
        title: {
            ...title
        },
        caption: {
            ...caption
        },
        body1: {
            ...body,
            fontSize: '1rem',
        },
        body2: {
            ...body,
            fontSize: '0.875rem'
        },
        body3: {
            ...body,
            fontSize: '0.625rem',
            color: '#46403D',
            opacity: 0.75
        },
        subtitle1: {
            color: '#000000',
            fontSize: '1rem',
            lineHeight: 1.25,
            marginBottom: 6
        }
    },
    shadows: shadows,
    overrides: {
        MuiButton: {
            outlined: {
                color: '#000000',
                borderColor: '#000000',
                textAlign: 'center',
                fontWeight: 'bold',
                '&:hover': {
                    backgroundColor: '#3E15A5'
                }
            },
            contained: {
                backgroundColor: '#7AFFB9',
                color: '#5A1EEB',
                textAlign: 'center',
                fontWeight: 'bold',
                '&:disabled': {
                    backgroundColor: '#a188de',
                    color: '#5A1EEC'
                },
                '&:hover': {
                    backgroundColor: '#08E0A3'
                }
            },
            sizeSmall: {
                fontSize: '0.875rem',
                letterSpacing: 0.4,
                lineHeight: 1.25,
                padding: '6px 20px'
            },
            sizeLarge: {
                fontSize: '0.875rem',
                letterSpacing: 0.4,
                lineHeight: 1.25,
                padding: '12px 32px'
            }
        },
        MuiTableCell: {
            body: {
                fontSize: '0.875rem',
            },
            head: {
                color: textGrey,
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    borderRadius: 'inherit',
                    backgroundColor: 'inherit',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                color: textGrey,
            }
        }
    }
});
// console.log('EH theme', theme);
export default theme;
