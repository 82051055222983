import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../../../helpers';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import LWConnect from '../../../../../.././../_LWCore/helpers/LWConnect/lw-connect';
import LWFiles from '../../../../../../../_LWCore/helpers/LWFiles';
import ThumbNail from '../../../../../../components/ThumbNail.component';
import Paper from '../../../../../../components/Paper.component';
import axios from 'axios';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    right: {
        textAlign: 'right',
    },
    up: {
        alignSelf: 'flex-start',
        marginBottom: -theme.spacing(1.5)
    },
    hidden: {
        display: 'none',
    },
    dropzone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 120,
        height: 120,
        cursor: 'pointer'
    },
    uploading: {
        cursor: 'no-drop'
    }
});

class CustomizeImageUpload extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        const campaignLanguage = campaign.current.data.language
        return {
            ...props,
            presets: campaign.presets[Core.campaignLanguage(campaignLanguage, 'presets')],
            campaignLanguage: campaignLanguage,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            imageUrl: props.value ? props.value : undefined,
            uploading: false
        };
    }

    onDragEnter = () => {
        console.log("DragEnter")
    }

    onDragLeave = () => {
        console.log("DragLeave")
    }

    onDropAccepted = async (payload) => {
        const { prodKey, field, onHandleChangeField } = this.props;
        const imageType = LWFiles.getTypes(payload[0]);

        if (imageType.fileType === 'image') {
            this.setState({ uploading: true })
            const { error, data } = await LWConnect.get('uploadFile?filename=upload.' + imageType.fileExtension);
            if (data.uploadUrl) {
                await axios.put(data.uploadUrl, payload[0]);
                this.setState({
                    imageUrl: data.url,
                    uploading: false
                }, () => {
                    const path = prodKey + '.' + field.field;
                    onHandleChangeField(path, data.url);
                })
            }

            else {
                console.error("Image upload problem", data, error);
            }
        }
        else {
            console.error("File is not an image");
        }
    }

    onDropRejected = () => {
        console.log("onDropRejected")
    }

    render() {
        const {
            classes,
            t,
            field,
            prodKey,
            error,
            errorText,
            required,
            hidden,
        } = this.props;
        const {
            imageUrl,
            uploading
        } = this.state;

        // let helperText
        // if (error) {
        //     helperText = errorText
        // }
        // t(`material.${prodKey}.fields.${field.field}.tooltip`)
        return (
            <Grid
                container
                className={classNames({ [classes.hidden]: hidden })}
            >
                <Grid item xs={12} className={classes.root}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.up}>
                            <Typography variant="body2">
                                {t(`material.${prodKey}.fields.${field.field}.label`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={imageUrl && !uploading ? 3 : 12}>
                            <Dropzone
                                accept={{
                                    "image/*": [".jpeg", "jpg", ".png"]
                                }}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDropAccepted={this.onDropAccepted}
                                onDropRejected={this.onDropRejected}
                                noDragEventsBubbling={true}
                                disabled={uploading}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <Paper
                                            bordered
                                            noShadow
                                            className={classNames(classes.dropzone, { [classes.uploading]: uploading })}

                                        >
                                            {uploading && <CircularProgress />}
                                            {!uploading &&
                                                <React.Fragment>
                                                    <AddAPhotoIcon />
                                                    {/* <Typography>Drop file</Typography> */}
                                                </React.Fragment>
                                            }
                                        </Paper>
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                        {imageUrl && !uploading &&
                            <Grid item xs={12}>
                                <ThumbNail
                                    image={imageUrl}
                                    big
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeImageUpload.mapStateToProps, {})(CustomizeImageUpload)
));