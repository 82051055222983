import React from 'react';
import classNames from 'classnames';
import CheckBox from '../../../../../../components/CheckBox.component';
import Paper from '../../../../../../components/Paper.component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { alpha } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        marginBottom: theme.spacing(3),
        overflow: 'hidden',
    },
    header: {
        backgroundColor: theme.palette.grey[100]
    },
    headerText: {
        color: theme.palette.secondary.main,
        textTransform: 'none',
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2)
    },
    divider: {
        borderBottomColor: theme.palette.grey[200],
    },
    nodivider: {
        borderBottomColor: theme.palette.grey[100],
    },
    expandIconRoot: {
        cursor: 'pointer',
        color: theme.palette.grey[600],
        backgroundColor: alpha(theme.palette.common.white, .5),
        borderRadius: '50%',
        marginRight:  theme.spacing(1), 
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {duration: theme.transitions.duration.shortest}),
    },
    expanded: {
        transform: 'rotate(180deg)',
    }
})

class CustomizeTrainingsListCol extends React.Component {

    render() {
        const {
            classes,
            cat,
            trainings,
            catOpen,
            selected,
            checkCat,
            handleChange,
            handleCatChange,
            handleCatOpen,
        } = this.props;
        const showOpened = catOpen.indexOf(trainings[cat].id) >= 0
    
        return (
            <Paper
            bordered
            noShadow
            className={classes.paper}
            >
            <List
                disablePadding
            >
                <ListItem
                    divider
                    disableGutters
                    className={classes.header}
                    classes={{
                        divider: classNames(showOpened ? classes.divider: classes.nodivider)
                    }}
                >
                    <CheckBox
                        id={`cat-${cat}`}
                        checked={checkCat === 1}
                        indeterminate={checkCat === 0}
                        onChange={handleCatChange}
                    />
                    <ListItemText
                        primary={trainings[cat].catTitle}
                        classes={{
                            primary: classes.headerText
                        }}
                    />
                    <ListItemSecondaryAction>
                        <ExpandMoreIcon
                            className={classNames(
                                classes.expandIconRoot,
                                {
                                    [classes.expanded]: showOpened
                                }
                            )}
                            onClick={() => handleCatOpen(trainings[cat].id)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={showOpened}>
                    {trainings[cat].trainings.map( (training, key) =>
                        <ListItem
                            key={key}
                            disableGutters
                        >
                            <CheckBox
                                id={String(training.CourseId)}
                                checked={selected.includes(String(training.CourseId))}
                                onChange={handleChange}
                            />
                            <ListItemText
                                secondary={training.CourseTitle}
                            />
                        </ListItem>
                    )}           
                </Collapse>
            </List>
            </Paper>
        )
    
    }
}

export default withStyles(styles)(CustomizeTrainingsListCol);