import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Core } from "../../../helpers";
import deepmerge from "deepmerge";
import LayoutBody from "../../../components/LayoutBody.component";
import Paper from "../../../components/Paper.component";
import Textfield from "../../../components/Textfield.component";
import ExpansionPanel from "../../../components/ExpansionPanel/components/ExpansionPanel.component";
import CustomizeProduct from "./components/CustomizeProduct.component";
import CustomizePreview from "./components/CustomizePreview.component";
import FixScrollbar from "../../../components/FixScrollbar.component";
import Dialog from "../../../components/Dialog.component";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import StepHeader from "../../../components/StepHeader/components/StepHeader.component";
import CampaignHeader from "../../../components/CampaignHeader.component";
import CampaignFooter from "../../../components/CampaignFooter.component";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  formContainer: {
    marginTop: -24,
    paddingBottom: 100,
  },
  right: {
    textAlign: "right",
  },
  up: {
    alignSelf: "flex-start",
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    margnRight: theme.spacing(1),
  },
  customizeProductContainer: {
    boxShadow:
      "0 8px 8px 1px rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)",
  },
  productwrapper: {
    position: "relative",
  },
  productdelete: {
    position: "absolute",
    top: theme.spacing(1.25),
    right: theme.spacing(8),
    zIndex: 1000,
  },
  productdeleteicon: {
    color: theme.palette.common.black,
  },
});

class Customize extends React.Component {
  static mapStateToProps(state, props) {
    const { campaign } = state;
    return {
      ...props,
      material: Core.arrayToObject(campaign.material, "key"),
      products: campaign.current.data.products,
      lane: campaign.lane.id,
      productsInactive: campaign.current.data.productsInactive,
      company: campaign.current.data.company,
      language: campaign.current.data.language,
      trainings: Core.arrayToObject(campaign.assets.CourseList, "CourseId"),
      steps: campaign.lane.steps,
      canEdit: {
        products: campaign.current.productsReadOnly !== "1",
        trainings: campaign.current.trainingsReadOnly !== "1",
        texts: campaign.current.textsReadOnly !== "1",
      },
      isAdmin: campaign.user.roles && campaign.user.roles.includes("admin"),
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      openPanel: false,
      confirmDelete: false,
      itemToEdit: {
        company: props.company,
        products: props.products ? props.products : {},
        productsInactive: props.productsInactive ? props.productsInactive : {},
      },
      productsIncomplete: {},
      tempLms: props.company.lms,
    };
  }

  handleChangePanel = (e, panel) => {
    const element = document.getElementById(panel);
    element.scrollIntoView(true);

    const { openPanel, productsIncomplete, itemToEdit } = this.state;
    let newProductsIncomplete = productsIncomplete;
    let newPanel = panel === openPanel ? false : panel;
    if (openPanel) {
      // There is an open panel, check if it's complete.
      const missing = this.validateProduct(openPanel, itemToEdit);
      // if (missing.length > 0) {
      //     newPanel = openPanel;
      // }
      newProductsIncomplete = {
        ...productsIncomplete,
        [openPanel]: missing,
      };
    }

    this.setState({
      // openPanel: panel === openPanel ? false : panel,
      openPanel: newPanel,
      productsIncomplete: newProductsIncomplete,
    });
  };

  handleChangeField = (path, value) => {
    const updateProducts = (path, value) => (state) => {
      // Update products
      const itemToEdit = state.itemToEdit;
      const products = itemToEdit.products;
      const newProduct = Core.createObjectFromString(path, value);
      const overwriteMerge = (destinationArray, sourceArray, options) =>
        sourceArray;
      const newProducts = deepmerge(products, newProduct, {
        arrayMerge: overwriteMerge,
      });
      const newItemToEdit = {
        ...itemToEdit,
        products: newProducts,
      };

      // Validate products if in error state.
      let newProductsIncomplete = state.productsIncomplete;
      const prodKey = path.split(".")[0];
      if (newProductsIncomplete[prodKey]) {
        // Check if product is in error state. If so check if this update resolves that.
        newProductsIncomplete = {
          ...newProductsIncomplete,
          [prodKey]: this.validateProduct(prodKey, newItemToEdit),
        };
      }
      return {
        itemToEdit: newItemToEdit,
        productsIncomplete: newProductsIncomplete,
      };
    };

    this.setState(updateProducts(path, value));
  };

  handleChangeLms = (value) => {
    this.setState({
      tempLms: value,
    });
  };

  handleBlurLms = () => {
    const { itemToEdit, tempLms } = this.state;
    const newItemToEdit = {
      ...itemToEdit,
      company: {
        ...itemToEdit.company,
        lms: tempLms,
      },
    };
    this.setState({
      itemToEdit: newItemToEdit,
    });
  };

  validateProduct = (prodKey, itemToEdit) => {
    const { material } = this.props;
    const missing = [];
    if (material[prodKey] && itemToEdit.products[prodKey]) {
      const fields = material[prodKey].customizeFields; // This products field definitions.
      const values = itemToEdit.products[prodKey]; // This products recorded field values.
      fields.forEach((field) => {
        let value = values[field.field];
        switch (field.type) {
          case "trainings":
            // console.log("Value", value);
            const check = value.filter((v) => {
              return v !== "0";
            });
            // console.log("Check", check);
            if (
              !check ||
              check.length < field.min ||
              check.length > field.max
            ) {
              missing.push(field.field);
            }
            break;
          case "color":
            if (!value) {
              missing.push(field.field);
            }
            break;
          case "text":
          case "text-multiline":
            const strippedValue = Core.stripHtml(value);
            if (
              (field.min && strippedValue.length < field.min) ||
              (field.max && strippedValue.length > field.max)
            ) {
              missing.push(field.field);
            }
            break;
          case "company":
          case "get-value-on-export":
            break;
          default:
            if (
              !value ||
              value.length < field.min ||
              value.length > field.max
            ) {
              missing.push(field.field);
            }
        }
      });
    }
    return missing;
  };

  validateAll = () => {
    const { itemToEdit } = this.state;
    const { onSelectStep } = this.props;
    const products = itemToEdit.products;
    let newProductsIncomplete = {};
    Object.keys(products).forEach((product) => {
      const missing = this.validateProduct(product, itemToEdit);
      newProductsIncomplete[product] = missing;
    });
    const stepIsCompleted = this.checkValidation(
      itemToEdit,
      newProductsIncomplete
    );
    this.setState(
      {
        productsIncomplete: newProductsIncomplete,
      },
      () => {
        if (stepIsCompleted) {
          onSelectStep(false, 1, itemToEdit, stepIsCompleted);
        }
      }
    );
  };

  checkValidation = (itemToEdit, productsIncomplete) => {
    const result = Object.keys(productsIncomplete).every((product) => {
      return this.validateProduct(product, itemToEdit).length <= 0;
    });
    return result;
  };

  deleteProduct = (prodKey) => {
    const { itemToEdit, productsIncomplete } = this.state;

    let newItemToEdit = JSON.parse(JSON.stringify(itemToEdit));
    let newProductsIncomplete = JSON.parse(JSON.stringify(productsIncomplete));

    const thisProd = newItemToEdit.products[prodKey];
    delete newItemToEdit.products[prodKey];
    delete newProductsIncomplete[prodKey];
    newItemToEdit.productsInactive[prodKey] = thisProd;

    this.setState({
      itemToEdit: newItemToEdit,
      productsIncomplete: newProductsIncomplete,
      openPanel: false,
    });
  };

  cancelDelete = () => {
    this.setState({ confirmDelete: false });
  };

  openDelete = (prodKey) => {
    this.setState({ confirmDelete: prodKey });
  };

  render() {
    const {
      classes,
      t,
      steps,
      canEdit,
      isAdmin,
      getCurrentStep,
      activeStep,
      onSelectStep,
      onClickStep,
      material,
    } = this.props;
    const {
      openPanel,
      itemToEdit,
      tempLms,
      productsIncomplete,
      confirmDelete,
    } = this.state;
    // console.log('EH state', this.state);
    // console.log('EH props', this.props);
    const stepIsCompleted = this.checkValidation(
      itemToEdit,
      productsIncomplete
    );

    return (
      <React.Fragment>
        <FixScrollbar />
        <StepHeader
          itemToEdit={itemToEdit}
          steps={steps}
          activeStep={activeStep}
          onClickStep={onClickStep}
          stepIsCompleted={stepIsCompleted}
          lane={this.props.lane}
          helpText={t("common.header.button")}
        />
        <CampaignHeader
          title={t("customize.title")}
          intro={t("customize.intro")}
        />
        <LayoutBody
          component="section"
          width="medium"
          textAlignCenter
          paddingBottom
          style={{ position: "relative" }}
          className={classes.formContainer}
        >
          <Paper bordered noShadow padding className={classes.paper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} className={classes.up}>
                <Typography variant="h4">{t("customize.lms.label")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Textfield
                  multiline
                  value={tempLms}
                  onChange={(e) => this.handleChangeLms(e.target.value)}
                  onBlur={() => this.handleBlurLms()}
                  helperText={t("customize.lms.tooltip")}
                />
              </Grid>
            </Grid>
          </Paper>
          {Object.keys(itemToEdit.products).map((prodKey, key) => {
            if (material[prodKey]) {
              return (
                <div
                  key={prodKey}
                  className={classes.productwrapper}
                  id={prodKey}
                >
                  <ExpansionPanel
                    id={`product_${prodKey}`}
                    title={t(`material.${prodKey}.title`)}
                    expanded={openPanel === prodKey}
                    onChange={(e) => this.handleChangePanel(e, prodKey)}
                    error={
                      productsIncomplete[prodKey] &&
                      productsIncomplete[prodKey].length > 0
                    }
                    style={{ overflow: "hidden" }}
                  >
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="stretch">
                        <Grid
                          item
                          xs={6}
                          className={classes.customizeProductContainer}
                        >
                          {(canEdit.texts || isAdmin) && (
                            <CustomizeProduct
                              prodKey={prodKey}
                              products={itemToEdit.products}
                              onHandleChangeField={this.handleChangeField}
                              errors={
                                productsIncomplete[prodKey]
                                  ? productsIncomplete[prodKey]
                                  : []
                              }
                              nextProduct={
                                Object.keys(itemToEdit.products)[key + 1]
                                  ? () =>
                                      this.handleChangePanel(
                                        false,
                                        Object.keys(itemToEdit.products)[
                                          key + 1
                                        ]
                                      )
                                  : null
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {openPanel === prodKey && (
                            <CustomizePreview
                              prodKey={prodKey}
                              product={itemToEdit.products[prodKey]}
                              company={itemToEdit.company}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </ExpansionPanel>
                  {Object.keys(itemToEdit.products).length > 1 &&
                    (isAdmin || canEdit.products) && (
                      <React.Fragment>
                        <IconButton
                          onClick={() => this.openDelete(prodKey)}
                          className={classes.productdelete}
                        >
                          <DeleteIcon
                            fontSize="small"
                            className={classes.productdeleteicon}
                          />
                        </IconButton>
                        <Dialog
                          type="delete"
                          open={confirmDelete === prodKey}
                          title={t(`material.${prodKey}.title`)}
                          text={t("customize.remove")}
                          onCancel={this.cancelDelete}
                          cancelText={t("common.buttons.cancel")}
                          onConfirm={() => this.deleteProduct(prodKey)}
                          confirmText={t("common.buttons.confirm")}
                        />
                      </React.Fragment>
                    )}
                </div>
              );
            }
          })}
          {!stepIsCompleted && (
            <Typography variant="body1" color="error" gutterBottom>
              {t("customize.checkproducts")}
            </Typography>
          )}
        </LayoutBody>
        <CampaignFooter
          onSelectPreviousStep={() =>
            onSelectStep(false, -1, itemToEdit, stepIsCompleted)
          }
          // onSelectNextStep={() => onSelectStep(false, 1, itemToEdit, stepIsCompleted)}
          onSelectNextStep={() => this.validateAll()}
          previousStepIcon={<ArrowBackIcon fontSize="small" />}
          nextStepIcon={<ArrowForwardIcon fontSize="small" />}
          nextStepDisabled={!stepIsCompleted}
          getCurrentStep={getCurrentStep}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(connect(Customize.mapStateToProps, {})(Customize))
);
