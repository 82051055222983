import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    name: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold'
    }
})

class ResultsRowName extends React.Component {
    
    render () {
        const {
            t,
            classes,
            name,
            cat
        } = this.props

        return (
            <React.Fragment>
                <Typography className={classes.name}>
                    {name}
                </Typography>
                <Typography variant="caption">
                    {t(`material.${cat}.title`)}
                </Typography>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(styles)(ResultsRowName));