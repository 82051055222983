import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
    root: {
        borderColor: theme.palette.grey[200],
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderRadius: 5,
        backgroundColor: theme.palette.common.white,
        width: theme.spacing(4),
        height: theme.spacing(4),
        transition: theme.transitions.create('background-color'),
        '&:hover, &:active': {
            backgroundColor: theme.palette.grey[200],
        }
    },
    checked: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        '&:hover, &:active': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    checkedicon: {
        fill: theme.palette.common.black,
    },
    uncheckedicon: {
        fill: theme.palette.background.paper,
        '&hover, &:active': {
            backgroundColor: theme.palette.primary.main,
        }
    }
});

function CheckBig(props) {
    const {
        classes,
        checked,
        value,
        onChange,
        childrenClassName,
        ...other
    } = props;

    return (
        <MuiCheckbox
            checked={checked}
            value={value}
            onChange={onChange}
            classes={
                {
                    root: classes.root,
                    checked: classes.checked,
                }
            }
            icon={
                <CheckIcon
                    classes={{root: classes.uncheckedicon}}
                />
            }
            checkedIcon={
                <CheckIcon
                    classes={{root: classes.checkedicon}}
                />
            }
            {...other}
        />
    )

}

export default withStyles(styles)(CheckBig);
