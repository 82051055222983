import React from 'react';
import Button from './Button.component';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteSVG from '../assets/images/icons/delete.svg';
import CloseSVG from '../assets/images/icons/close.svg';

const styles = theme => ({
    paper: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(6),
        paddingBottom: theme.spacing(5)
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        backgroundColor: '#000000',
        borderRadius: '50%',
        margin: 'auto',
        marginBottom: theme.spacing(3)
    },
    avatarDelete: {
        backgroundColor: '#E80E5C'
    },
    avatarClose: {
        backgroundColor: '#5B1EEC'
    },
    title: {
        color: '#000000',
        fontFamily: '"Circular Book", Arial, Helvetica, sans-serif',
        fontSize: '1.5rem',
        lineHeight: 1.25,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 0,
        paddingBottom: theme.spacing(2)
    },
    description: {
        olor: '#868686',
        fontFamily: '"Circular Book", Arial, Helvetica, sans-serif',
        fontSize: theme.spacing(1.75),
        lineHeight: 1.25,
        textAlign: 'center',
        paddingBottom: theme.spacing(3),
        marginBottom: 0
    },
    actions: {
        display: 'flex',
        flexDirection: 'column'
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2)
    },
    confirmButton: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        marginBottom: theme.spacing(1)
    },
    deleteButton: {
        backgroundColor: '#E80E5C',
        '&:hover': {
            backgroundColor: '#cc0e52'
        }
    },
    closeButton: {
        backgroundColor: '#5B1EEC'
    },
    cancelButton: {
        textDecoration: 'underline'
    }
})

function Dialog(props) {
    const {
        classes,
        title,
        text,
        onCancel,
        cancelText,
        onConfirm,
        confirmText,
        type,
        ...other
    } = props;

    const getIcon = (type) => {
        if (type === 'delete') return DeleteSVG;
        if (type === 'close') return CloseSVG;
        return false;
    }

    return (
        <MuiDialog
            disableEscapeKeyDown
            maxWidth="xs"
            classes={{
                paper: classes.paper
            }}
            onClose={onCancel}
            {...other}
        >
            {onCancel && (
                <IconButton aria-label="Close" className={classes.closeIcon} onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            )}
            <Avatar
                className={classNames(classes.avatar, {
                    [classes.avatarDelete]: type === 'delete',
                    [classes.avatarClose]: type === 'close'
                })}
                src={getIcon(type)}
            />
            <MuiDialogTitle className={classes.title} disableTypography>{title}</MuiDialogTitle>
                <MuiDialogContent style={{ padding: 0 }}>
                    <MuiDialogContentText className={classes.description}>
                        {text}
                    </MuiDialogContentText>
                </MuiDialogContent>
                <MuiDialogActions className={classes.actions}>
                    <Button
                        primary
                        onClick={onConfirm}
                        size="large"
                        className={classNames(classes.confirmButton, {
                            [classes.deleteButton]: type === 'delete',
                            [classes.closeButton]: type === 'close',
                        })}
                    >
                        {confirmText}
                    </Button>
                    {onCancel && (
                        <Button
                            size="large"
                            onClick={onCancel}
                            className={classNames(classes.cancelButton)}
                        >
                            {cancelText}
                        </Button>
                    )}
                </MuiDialogActions>
        </MuiDialog>
    )
}

export default  withStyles(styles)(Dialog);
