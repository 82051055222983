import React from 'react';

function ProductIcon(props) {
    const { icon, className } = props;

    return (
        <img
            src={icon}
            className={className}
        />
    )
}

export default ProductIcon;