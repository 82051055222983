import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        '&:hover, &:active': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        }
    }
})

function Link(props) {
    const {
        classes,
        onClick,
        children,
        ...other
    } = props;

    return (
        <MuiLink
            underline='always'
            variant='body1'
            color='inherit'
            onClick={onClick}
            className={classes.root}
           { ...other}
        >
            {children}
        </MuiLink>
    )

}

export default withStyles(styles)(Link);