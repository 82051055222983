import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import AddIcon from '@material-ui/icons/Add';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import { formatLocaleCurrency } from 'country-currency-map';

const styles = theme => ({
    info: {
        display: 'inline-flex',
        flexDirection: 'column',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: 0,
        overflow: 'hidden',
        opacity: 0.5,
        transition: theme.transitions.create(
          ['width', 'opacity'],
          { duration: theme.transitions.duration.standard }
        ),
    },
    price: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold'
    },
    infoIcon: {
        display: 'inline-flex',
        verticalAlign: 'bottom',
        marginBottom: theme.spacing(.5),
        width: theme.spacing(1.5),
        height: theme.spacing(1.5)
    },
    open : {
        width: 'calc(100% - 200px)',
        opacity: 100
    },
    order: {
        boxShadow: 'none',
        marginRight: theme.spacing(1), 
        width: theme.spacing(3),
        height: theme.spacing(3),
        minHeight: theme.spacing(3)
    },
    icon: {
        width: theme.spacing(2),
        height: theme.spacing(2)
    }
})


class ResultsScale extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showHover: false
        }

    }

    render() {
        const {
            t,
            classes,
            onHandleQuantity,
            price,
            item
        } = this.props;
        const {
            showHover
        } = this.state;
        // console.log("ResultsEmpty props", this.props);

        return (
            <React.Fragment>
                    <div
                        className={classNames(classes.info, {
                            [classes.open]: showHover
                        })}
                    >
                        <Typography noWrap className={classes.price}>
                            {formatLocaleCurrency(price.Standard_price, price.currency)}
                        </Typography>
                        <Typography variant="caption" component="p" noWrap>
                            <LayersOutlinedIcon
                                className={classes.infoIcon}
                            /> {t('results.products.stackdiscount')}
                        </Typography>
                    </div>
                    <Fab
                        size="small"
                        color="primary"
                        className={classes.order}
                        onClick={() => onHandleQuantity(0, item, 'up')}
                        onMouseEnter={() => {this.setState({showHover: true})}}
                        onMouseLeave={() => {this.setState({showHover: false})}}
                    >
                        {showHover ?
                            <AddIcon
                                className={classes.icon}
                            />
                        :
                            <ShoppingBasketOutlinedIcon
                                className={classes.icon}
                            />
                        }
                    </Fab>
            </React.Fragment>
        )
    }

}

export default withTranslation()(withStyles(styles)(ResultsScale))