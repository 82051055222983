import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../../../helpers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    right: {
        textAlign: 'right',
    },
    up: {
        alignSelf: 'flex-start',
        marginBottom: -theme.spacing(1.5)
    },
    radioButton: {
        paddingTop: theme.spacing(.5) ,
        paddingBottom: theme.spacing(.5) ,
        color: theme.palette.grey[400]
    },
    radioButtonChecked: {
        color: theme.palette.secondary.main
    }
});

class CustomizeRadio extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            presets: campaign.presets[Core.campaignLanguage(campaign.current.data.language, 'presets')],
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            options: props.field.options ? props.field.options : [],
        }
    }

    UNSAFE_componentWillMount() {
        const {
            value,
            prodKey,
            field,
            onHandleChangeField,
            presets,
            preset,
        } = this.props;
        let options = []
        if (preset) {
            options = presets[preset];
        }
        else {
            options = field.options;
        }

        this.setState({
            options: options
        });
        const path = prodKey + '.' + field.field;
        onHandleChangeField(path, value ? value : options[0].key);
    }

    render() {
        const {
            classes,
            t,
            value,
            field,
            prodKey,
            onHandleChangeField,
        } = this.props;
        const { options } = this.state;
        // console.log('EH props', this.props);
        // console.log('EH state', this.state);

        return (
            <Grid container>
                <Grid item xs={12} className={classes.root}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.up}>
                            <Typography variant="body2">
                                {/* {field.label[language]} */}
                                {t(`material.${prodKey}.fields.${field.field}.label`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                name={field.field}
                                className={classes.group}
                                value={value ? value : options[0].key}
                                onChange={(e) => onHandleChangeField(prodKey + '.' + field.field, e.target.value)}
                            >
                                {options.map((option, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={option.key}
                                            control={<Radio
                                                classes={{
                                                    root: classes.radioButton,
                                                    checked: classes.radioButtonChecked
                                                }}
                                            />}
                                            label={
                                                option.display ?
                                                    option.display
                                                    :
                                                    t(`material.${prodKey}.fields.${field.field}.options.${option.key}`)
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeRadio.mapStateToProps, {})(CustomizeRadio)
));