import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        margin: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
        height: 'auto'
    }
})

class CustomizePreviewVideo extends React.Component {

    render() {
        const {
            classes,
            previewUrl
        } = this.props;
        // console.log('EH props', this.props);
        
        return(
            <video width="100" height="" controls className={classes.root}>
                <source src={previewUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        )
    }
}

export default withStyles(styles)(CustomizePreviewVideo);