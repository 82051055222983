import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import LayoutBody from './LayoutBody.component';
import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    header: {
        textAlign: 'center',
        color: theme.palette.common.white,
        marginBottom: theme.spacing(.5),
        marginTop: 84
    },
    text: {
        textAlign: 'center',
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4)
    }
})

class CampaignHeader extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            classes,
            title,
            intro
        } = this.props;

        return (
            <LayoutBody
                component="section"
                width="small"
                textAlignCenter
                paddingTop
                paddingBottom
                purple
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.header} variant="h1">
                            {title}
                        </Typography>
                        <Typography className={classes.text} variant="body1" paragraph>
                            {intro}
                        </Typography>
                    </Grid>
                </Grid>
            </LayoutBody>
        )
    }
}

export default withStyles(styles)(CampaignHeader);