import React from 'react';
import { withTranslation } from 'react-i18next';
import LayoutBody from '../../../components/LayoutBody.component';
import Stepper from './Stepper.component';
import { withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import { IconButton, Typography } from '@material-ui/core';
import Help from '../../Help.component';
import store from '../../../store';
import { push } from 'connected-react-router';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../../Dialog.component';
import { connect } from 'react-redux';
import CampaignActions from '../../../reducers/Campaign/campaign.actions';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 24,
        paddingRight: 24
    },
    helpButton: {
        marginRight: theme.spacing(3),
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    closeIcon: {
        color: '#000000'
    }
});

class StepHeader extends React.Component {
    static mapStateToProps(state, props) {
        return {
            ...props
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            closeCampaign: false
        }
    }

    goHome = () => {
        this.props.clear();
        store.dispatch(push('/start'));
    }

    render() {
        const {
            classes,
            t,
            lane,
            steps,
            activeStep,
            onClickStep,
            itemToEdit,
            stepIsCompleted,
            helpText
        } = this.props;

        // const stepIsCompleted = itemToEdit.language.length > 0;
        // console.log('EH langtitle state', this.state);
        // console.log('EH langtitle props', this.props);

        return (
            <MuiAppBar
                elevation={2}
                position="fixed"
                color="inherit"
                classes={{ root: classes.root }}
            >
                <Typography variant="h3">{t(`common.header.lane.${lane}`)}</Typography>

                <LayoutBody
                    component="section"
                    width="small"
                    textAlignCenter
                    paddingTopSmall
                    paddingBottomSmall
                >
                    <Stepper
                        steps={steps}
                        itemToEdit={itemToEdit}
                        activeStep={activeStep}
                        onClickStep={onClickStep}
                        complete={stepIsCompleted}
                    />
                </LayoutBody>

                <div>
                    <Help classes={{ root: classes.helpButton }} helpText={helpText} />
                    <IconButton onClick={() => this.setState({ closeCampaign: true })}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </div>

                <Dialog
                    open={this.state.closeCampaign}
                    title={t('start.archive.exit.title')}
                    text={t('start.archive.exit.description')}
                    onCancel={() => this.setState({ closeCampaign: false })}
                    cancelText={t('common.buttons.cancel')}
                    onConfirm={this.goHome}
                    confirmText={t('common.buttons.overview')}
                    type="close"
                />
            </MuiAppBar>
        );
    }
}

export default withTranslation()(withStyles(styles)(
    connect(StepHeader.mapStateToProps, {
        clear: CampaignActions.clear
    })(StepHeader)
));