import axios from 'axios';

/**
 * LWFiles class
 */
export default class LWFiles {

    static supportedTypes = { image: 'img', video: 'vid' };
    static pollInterval = 2000;

    /**
     *
     * @param file
     * @param _key
     * @returns {{fileName, 'x-amz-meta-realname', fileSize: *, displaySize: *}}
     */
    static getFileData(file, _key) {
        return {
            _key,
            fileName: file.name,
            'x-amz-meta-realname': file.name,
            fileSize: LWFiles.getSize(file),
            displaySize: LWFiles.getSize(file, true),
            ...LWFiles.getTypes(file),
        };
    }

    /**
     *
     * @param file
     * @param callback
     */
    static imageToBase64(file, callback) {

        if (typeof file === 'string') {
            callback(file);
            return;
        }

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const image = new Image();
                image.onload = () => {
                    callback({
                        base64: e.target.result,
                        width: image.naturalWidth,
                        height: image.naturalHeight
                    });
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    /**
     *
     * @param url
     * @returns {string}
     */
    static getVideoThumbnail(url = '') {
        return url.lastIndexOf('/') < 0 ? '' : `${url.substr(0, url.lastIndexOf('/'))}/thumbnail.jpg`;
    }

    /**
     *
     * @param url
     * @returns {boolean}
     */
    static urlIsVideo(url = '') {

        if (typeof url !== 'string') {
            return false;
        }
        let extension = url.match(/\.([^\./\?]+)($|\?)/);
        extension = extension ? `.${extension[1]}` : null;
        if (!extension) {
            return false;
        }
        return ['.mp4', '.mov', '.mpg', '.mpeg', '.wmv', '.avi'].indexOf(extension) > -1;
    }

    /**
     *
     * @param file
     * @returns {{fileType: *|string, fileExtension: *|string, mimeType}}
     */
    static getTypes(file) {
        if (typeof file === 'string') {
            return {
                fileType: 'image',
                fileExtension: 'jpeg',
                mimeType: 'image/jpeg'
            };
        }
        return {
            fileType: file.type.split('/')[0],
            fileExtension: file.type.split('/')[1],
            mimeType: file.type
        };
    }

    /**
     *
     * @param file
     * @param humanReadable
     * @returns {*}
     */
    static getSize(file, humanReadable = false) {
        return !humanReadable ? file.size : LWFiles.humanReadableSize(file.size);
    }

    /**
     *
     * @param bytes
     * @returns {string}
     */
    static humanReadableSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
    }

    /**
     *
     * @param _key
     * @param awsPath
     * @param callback
     */
    static ready({_key, awsPath}, callback) {
        axios.get(awsPath)
            .then(() => callback(_key))
            .catch((error) => {
                if (error.response.status !== 403) {
                    console.error(error.response);
                }
                setTimeout(() => {
                    LWFiles.ready({_key, awsPath}, callback);
                }, LWFiles.pollInterval);
            });
    }

}
