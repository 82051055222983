import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    wrapper: {
        margin: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(6)}px)`,
        overflow: 'hidden',
        position: 'relative'
    },
    iframe: {
        border: 'none',
        width: '100%',
        transformOrigin: '0 0',
        overflow: 'scroll',
    },
    loading: {
        position: 'absolute',
        zIndex: 10,
        left: theme.spacing(1), 
        top: theme.spacing(1)
    }
})


class CustomizePreviewIframe extends React.Component {


    render() {
        const {
            classes,
            prodKey,
            previewUrl,
            sizes,
            scrolling,
            loading
        } = this.props;
        
        return(
            <div
                id={`wrapper_${prodKey}`}
                className={classes.wrapper}
                style={{
                    height: Math.ceil(sizes.contentHeight * sizes.pages * sizes.scale),
                }}
            >
                {loading && <CircularProgress className={classes.loading} />}
                <iframe
                    id={`iframe_${prodKey}`}
                    name={`previewIframe_${prodKey}`}
                    title={prodKey}
                    src={previewUrl}
                    className={classes.iframe}
                    scrolling={scrolling}   
                    style={{
                        width: Math.ceil(sizes.contentWidth),
                        height: Math.ceil(sizes.contentHeight * sizes.pages) + 18,
                        transform: `scale(${sizes.scale})`
                    }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(CustomizePreviewIframe);