import React from 'react';
import ReactDOM from 'react-dom/client.js';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history} from './../src/store.js';
import Routes from './routes.jsx';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../src/theme';
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <I18nextProvider i18n={ i18n }>
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Routes store={store} />
                </ConnectedRouter>
            </Provider>
        </MuiThemeProvider>
    </I18nextProvider>
);
