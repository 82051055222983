import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';

const styles = theme => ({
    root: {
        borderRadius: theme.spacing(.5),
        backgroundColor: theme.palette.grey[100],
    },
    noBackground: {
        backgroundColor: theme.palette.common.white,
    },
    bordered: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
    },
    big: {
        width: 120,
        height: 120
    }
})

function ThumbNail(props) {
    const {
        image,
        classes,
        children,
        bordered,
        noBackground = false,
        big,
        className,
        ...other
    } = props;

    return (
        <MuiAvatar
            className={classNames(
                className,
                classes.root,
                {
                    [classes.bordered]: bordered,
                    [classes.noBackground]: noBackground,
                    [classes.big]: big
                }
            )}
            src={image}
            children={children}
        />
    )
}

export default withStyles(styles)(ThumbNail);
