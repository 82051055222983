import LWPublish from '../../../_LWCore/helpers/LWConnect/lw-publish';

class Publish {

    constructor(tasklist, jobToken = null, timeout = 2000) {
        this.timeoutProcess = null;
        this.settings = { 
            tasklist,
            jobToken,
            timeout: timeout
        };

        this.callbacks = {
            start: null,
            progress: null,
            finish: null,
            error: null
        };
    }

    on(action, callback) {
        this.callbacks[action] = callback;
    }

    broadcast(action, data) {
        if (!this.callbacks[action]) {
            console.error(`Action ${action} not found.`);
        } else {
            this.callbacks[action](data);
        }
    }

    async start() {
        try {
            const response = await LWPublish.post(`${process.env.PUBLISH_HOST}job/start`, {
                tasks: this.settings.tasklist
            });

            const { jobToken } = response.data;
            this.settings.jobToken = jobToken;

            this.broadcast('start', { jobToken, status: 'Start tasks' });
            this.poll();

            return jobToken;
        } catch (e) {
            this.broadcast('error', {
                status: 'failed',
                error: e.error,
                progress: e.message,
                logs: []
            });
        }
    }

    async poll() {
        const root = this;
        clearTimeout(this.timeoutProcess);

        try {
            const response = await LWPublish.post(`${process.env.PUBLISH_HOST}job/status`, { 
                jobToken: this.settings.jobToken
            }, {});

            if(!response.success) {
                // Catch request errors (for example, no network)
                this.broadcast('error', { jobToken: this.settings.jobToken, ...response, status: 'fail' });
            } else if (response.data.status !== 'finished' && response.data.status !== 'Finished!') {
                // No errors and still processing. Poll again in Xs
                this.broadcast('progress', { jobToken: this.settings.jobToken, ...response, status: 'progress' });
                this.timeoutProcess = setTimeout(root.poll.bind(root), this.settings.timeout);
            } else {
                // No errors and finished processing. No need to poll again
                this.broadcast('finish', { jobToken: this.settings.jobToken, ...response, status: 'success'});
            }
        } catch (e) {
            // Client side errors while making request
            clearTimeout(this.timeoutProcess);
            this.broadcast('error', {
                status: 'failed',
                error: e.error,
                progress: e.message,
                logs: []
            });
        }
    }
}

export default Publish;