import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../../../helpers';
import Select from '../../../../../../components/Select.component';
import CheckBox from '../../../../../../components/CheckBox.component';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    right: {
        textAlign: 'right',
    },
    up: {
        alignSelf: 'flex-start',
        marginBottom: -theme.spacing(1.5)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        height: 26,
        margin: 1,
    },
    close: {
        marginLeft: theme.spacing(1.5),
    },
});

class CustomizeMultiSelect extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            presets: campaign.presets[Core.campaignLanguage(campaign.current.data.language, 'presets')],
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            options: this.getOptions,
            selected: Array.isArray(props.value) ? props.value : props.value === undefined ? Array(1).fill("0") : [props.value],
            path: props.prodKey + '.' + props.field.field,
            open: false
        }
    }

    UNSAFE_componentWillMount() {
        const {
            field,
            onHandleChangeField,
            presets,
            preset,
        } = this.props;
        const {
            selected,
            path,
        } = this.state;
        let options = []
        if (preset) {
            options = presets[preset];
        }
        else {
            options = field.options;
        }

        this.setState({
            options: options
        });
        onHandleChangeField(path, selected);
    }

    getOptions = () => {
        const {
            field,
            presets,
            preset,
        } = this.props;
        let options = []
        if (preset) {
            options = presets[preset];
        }
        else if (field.options) {
            options = field.options;
        }
        return options;
    }

    handleSelect = (e) => {
        const {
            path
        } = this.state;
        const {
            onHandleChangeField
        } = this.props;
        const value = e.target.value;
        const closePosition = value.indexOf('close');

        if (closePosition >= 0) {
            this.handleClose();
            value.splice(value.indexOf('close'), 1);
        }

        this.setState({selected: value});
        onHandleChangeField(path, value);
    }

    handleOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    render() {
        const {
            classes,
            t,
            field,
            prodKey,
        } = this.props;
        const {
            options,
            selected,
            open,
        } = this.state;
        // console.log('EH props', this.props);
        // console.log('EH state', this.state);
        return (
            <Grid container>
                <Grid item xs={12} className={classes.root}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.up}>
                            <Typography variant="body2">
                                {t(`material.${prodKey}.fields.${field.field}.label`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                multiple
                                value={selected}
                                onChange={this.handleSelect}
                                open={open}
                                onOpen={this.handleOpen}
                                onClose={this.handleClose}
                                helperText={t(`material.${prodKey}.fields.${field.field}.tooltip`)}
                                renderValue={selected => (
                                    <div className={classes.chips}>
                                        {selected.map(value => (
                                            <Chip
                                                key={value}
                                                label={options[value].display}
                                                color="primary"
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                <MenuItem value='close'>
                                    <ListItemIcon
                                        className={classes.close}
                                    >
                                            <CloseIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('common.buttons.close')} />
                                </MenuItem>
                                {options.map((option, index) => (
                                    <MenuItem key={index} value={option.key} >
                                        <CheckBox checked={selected.indexOf(option.key) > -1} />
                                        <ListItemText primary={option.display} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeMultiSelect.mapStateToProps, {})(CustomizeMultiSelect)
));