import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { history } from './../src/store';
import AppRoutes from './../src/routes.jsx';

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Router history={history}>
                <Switch>
                    <AppRoutes />
                </Switch>
            </Router>
        );
    }
}

export default App;
