import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Core from '../../../helpers/Core';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        minHeight: theme.spacing(30)
    },
});

class OnboardingVideo extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            languageCode: campaign.user.LanguageCode,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            videopath: `${process.env.TEMPLATE_HOST}videos/${Core.interfaceLanguage(props.languageCode)}/${props.step.video}`,
        }
    }
    render() {
        const {
            classes,
        } = this.props;
        const {
            videopath
        } = this.state;
        return(
            <Grid container>
                <Grid item className={classes.root}>
                    <video width="100%" height="" controls autoPlay>
                        <source src={videopath} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(
    connect(OnboardingVideo.mapStateToProps, {})(OnboardingVideo)
);