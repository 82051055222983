import React from 'react';
import Paper from '../../../../components/Paper.component';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { formatLocaleCurrency } from 'country-currency-map';

const styles = theme => ({
    root: {
        display: 'inline-flex',
    },
    order: {
        boxShadow: 'none',
        margin: theme.spacing(1), 
        width: theme.spacing(3),
        height: theme.spacing(3),
        minHeight: theme.spacing(3)
    },
    icon: {
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    price: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        marginLeft: theme.spacing(1), 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: theme.spacing(8),
    },
    quantity: {
        fontSize: theme.typography.pxToRem(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: theme.spacing(7)
    }
})


class ResultsActions extends React.Component {

    render() {
        const {
            classes,
            price,
            quantity,
            item,
            onHandleQuantity,
            showPrice
        } = this.props;

        return (
            <Paper
                background='dark'
                noShadow
                className={classes.root}
            >
                {showPrice &&
                    <Typography noWrap className={classes.price}>
                        {formatLocaleCurrency(price.Standard_price, price.currency)}
                    </Typography>
                }
                <Fab
                    size="small"
                    className={classes.order}
                    onClick={() => onHandleQuantity(quantity, item, 'down')}
                >
                    <RemoveIcon
                        className={classes.icon}
                    />
                </Fab>
                <Typography noWrap className={classes.quantity}>
                    {quantity}
                </Typography>
                <Fab
                    size="small"
                    color="primary"
                    className={classes.order}
                    onClick={() => onHandleQuantity(quantity, item, 'up')}
                >
                    <AddIcon
                        className={classes.icon}
                    />
                </Fab>

            </Paper>
        )
    }

}

export default withStyles(styles)(ResultsActions)