import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from './Button.component';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    root: {
        zIndex: 1001,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5)
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
    },
    button: {
    },
    prevButton: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white
    },


    nextStepIcon: {
        marginLeft: theme.spacing(1.5),
        transform: 'translateY(1px)'
    },
    previousStepIcon: {
        marginRight: theme.spacing(1.5),
        transform: 'translateY(1px)'
    },
});

class CampaignFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    getPreviousStepText = (id) => {
        const { t } = this.props;
        let text = "";

        if (id === "titl" || id === "them") {
            text = t('common.buttons.previousStep.cancel');
        } else {
            text = t('common.buttons.previousStep.default');
        }

        return text;
    }

    getNextStepText = (id) => {
        const { t } = this.props;
        let text = "";

        if (id) {
            text = t(`common.buttons.nextStep.${id}`)
        } else {
            text = t('common.buttons.nextStep.default');
        }

        return text;
    }

    render() {
        const {
            classes,
            children,
            onSelectPreviousStep,
            onSelectNextStep,
            previousStepIcon,
            nextStepIcon,
            previousStepDisabled = false,
            nextStepDisabled,
            getCurrentStep,
            hideNextButton = false
        } = this.props;

        const id = getCurrentStep();
        const previousStepText = this.getPreviousStepText(id);
        const nextStepText = this.getNextStepText(id);

        return (
            <div className={classes.root}>
                <Button
                    disabled={previousStepDisabled}
                    classes={{
                        root: classes.prevButton
                    }}
                    onClick={onSelectPreviousStep}
                    variant="outlined"
                    size="large"
                >
                    {previousStepIcon && (id !== "titl" && id !== "them") && <div className={classes.previousStepIcon}>{previousStepIcon}</div>}
                    {previousStepText}
                </Button>

                <div>
                    {children && children}
                    {!hideNextButton && (
                        <Button
                            disabled={nextStepDisabled}
                            classes={{ root: classes.button }}
                            onClick={onSelectNextStep}
                            variant="contained"
                            size="large"
                        >
                            {nextStepText}
                            {nextStepIcon && <div className={classes.nextStepIcon}>{nextStepIcon}</div>}
                        </Button>
                    )}
                </div>
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles)(CampaignFooter));