import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Loader from '../../../components/Loader.component';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Typography } from '@material-ui/core';
import Belgium from '../../../assets/images/countries/belgium.svg';
import English from '../../../assets/images/countries/english.svg';
import France from '../../../assets/images/countries/france.svg';
import Germany from '../../../assets/images/countries/germany.svg';
import Italy from '../../../assets/images/countries/italy.svg';
import Netherlands from '../../../assets/images/countries/netherlands.svg';
import Portugal from '../../../assets/images/countries/portugal.svg';
import Spain from '../../../assets/images/countries/spain.svg';
import Austria from '../../../assets/images/countries/austria.svg';
import Swiss from '../../../assets/images/countries/swiss.svg';
import Poland from '../../../assets/images/countries/poland.svg';
import Denmark from '../../../assets/images/countries/denmark.svg';
import Sweden from '../../../assets/images/countries/sweden.svg';

const styles = theme => ({
    root: {
        borderCollapse: 'separate',
    },
    headcell: {
        padding: `${theme.spacing(.5) }px 32px ${theme.spacing(.5) }px 12px`,
        borderTopColor: theme.palette.grey[300],
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
        borderBottomWidth: 1.5,
        borderBottomStyle: 'solid',
        '&:first-child': {
            borderTopLeftRadius: theme.spacing(1), 
            borderLeftColor: theme.palette.grey[300],
            borderLeftWidth: 1,
            borderLeftStyle: 'solid'
        },
        '&:last-child': {
            borderTopRightRadius: theme.spacing(1), 
            borderRightColor: theme.palette.grey[300],
            borderRightWidth: 1,
            borderRightStyle: 'solid'
        }
    },
    cell: {
        padding: `${theme.spacing(1)}px 32px ${theme.spacing(1)}px 12px`,
        backgroundColor: theme.palette.background.paper,
        '&:first-child': {
            fontWeight: 'bold',
            fontSize: 14,
            color: '#333333',
            borderLeftColor: theme.palette.grey[300],
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
        },
        '&:last-child': {
            borderRightColor: theme.palette.grey[300],
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            paddingLeft: 0
        }
    },
    lastrow: {
        '&:first-child': {
            borderBottomLeftRadius: theme.spacing(1), 
        },
        '&:last-child': {
            borderBottomRightRadius: theme.spacing(1), 
        }
    },
    actionIcon: {
        color: '#333333'
    },
    actionIconDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'not-allowed'
    },
    language: {
        width: 16,
        height: 16
    },
    noLanguage: {
        width: 16,
        height: 16,
        backgroundColor: '#868686',
        borderRadius: '50%',
        color: 'white',
        textAlign: 'center',
        marginRight: 16 
    }
});

class StartProducts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            header: [
                {
                    label: props.t('start.archive.name'),
                    key: 'title',
                    sort: 'string',
                    dir: 'desc',
                },
                {
                    label: props.t('start.archive.language'),
                    key: 'language',
                    sort: 'string',
                    dir: 'desc',
                },
                {
                    label: props.t('start.archive.material'),
                    key: 'material',
                    sort: 'string',
                    dir: 'desc',
                },
                {
                    label: props.t('start.archive.trainings'),
                    key: 'trainings',
                    sort: 'string',
                    dir: 'desc',
                },
                {
                    label: props.t('start.archive.date'),
                    key: 'date',
                    sort: 'date',
                    dir: 'asc',
                },
                {
                    label: props.t('start.archive.actions'),
                }
            ],
            sortedCol: 4,
            sortedData: this.props.data,
        }
    }

    componentDidMount() {
        const { sortedData } = this.state;
        this.doSort(sortedData);
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data) {
            this.doSort(data);
        }
    }

    handleSort = (k) => {
        const {
            header,
            sortedCol,
            sortedData,
        } = this.state;
        // console.log('EH handleSort', header);

        let newDir = header[k].dir;
        const newHeader = [...header];
        if (sortedCol === k) {
            newDir = header[k].dir === 'desc' ? 'asc' : 'desc';
            newHeader[k] = {
                ...header[k],
                dir: newDir,
            }
        }

        this.setState({
            header: newHeader,
            sortedCol: k,
        }, () => this.doSort(sortedData))
    }

    doSort = (data) => {
        const {
            header,
            sortedCol,
        } = this.state;

        const sortDir = header[sortedCol].dir === 'desc' ? 1 : -1;
        const newSortedData = data.sort(
            (a, b) => {
                if (a[header[sortedCol].key] > b[header[sortedCol].key]) return 1 * sortDir;
                if (b[header[sortedCol].key] > a[header[sortedCol].key]) return -1 * sortDir;
                return 0;
            }
        )
        this.setState({
            sortedData: newSortedData,
        })
    }

    getLanguageIcon = (lang) => {
        const { classes } = this.props;

        switch (lang) {
            case 'nl-nl':
                return <img src={Netherlands} style={{ marginRight: 12 }} className={classes.language} />;
            case 'nl-be':
            case 'fr-be':
                return <img src={Belgium} style={{ marginRight: 12 }} className={classes.language} />;
            case 'en-gb':
                return <img src={English} style={{ marginRight: 12 }} className={classes.language} />;
            case 'fr-fr':
                return <img src={France} style={{ marginRight: 12 }} className={classes.language} />;
            case 'es-es':
                return <img src={Spain} style={{ marginRight: 12 }} className={classes.language} />;
            case 'de-de':
                return <img src={Germany} style={{ marginRight: 12 }} className={classes.language} />;
            case 'it-it':
                return <img src={Italy} style={{ marginRight: 12 }} className={classes.language} />;
            case 'pt-pt':
                return <img src={Portugal} style={{ marginRight: 12 }} className={classes.language} />;
            case 'de-at':
                return <img src={Austria} style={{ marginRight: 12 }} className={classes.language} />;
            case 'de-ch':
                return <img src={Swiss} style={{ marginRight: 12 }} className={classes.language} />;
            case 'pl-pl':
                return <img src={Poland} style={{ marginRight: 12 }} className={classes.language} />;
            case 'da-dk':
                return <img src={Denmark} style={{ marginRight: 12 }} className={classes.language} />;
            case 'sv-se':
                return <img src={Sweden} style={{ marginRight: 12 }} className={classes.language} />;
            default:
                return <div className={classes.noLanguage}>?</div>;
        }
    }

    getLanguageText = (lang) => {
        const langArray = lang.split('-');
        if (langArray.length === 2 && langArray[0] === langArray[1]) return langArray[0].toUpperCase();
        return lang.toUpperCase();
    }

    render () {
        const {
            classes,
            onGetCampaign,
            onRemoveCampaign,
            loading,
        } = this.props;
        const {
            header,
            sortedCol,
            sortedData,
        } = this.state;
        // console.log('EH props', this.props);
        // console.log('EH state', this.state);
    
        return (
            <Loader isLoading={loading}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {header.map((cell, key) => (
                                <TableCell
                                    key={key}
                                    align="left"
                                    className={classes.headcell}
                                    sortDirection={cell.dir}
                                >
                                    {cell.sort ?
                                        <TableSortLabel
                                            active={key === sortedCol}
                                            direction={cell.dir}
                                            onClick={() => this.handleSort(key)}
                                        >
                                            <Typography variant="h5">{cell.label}</Typography>
                                        </TableSortLabel>
                                    :
                                        <React.Fragment>
                                            <Typography variant="h5">{cell.label}</Typography>
                                        </React.Fragment>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((campaign, campaignKey) => (
                            <TableRow
                                key={campaign.id}
                            >
                                <TableCell
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                >
                                    <Typography variant="h4">{campaign.title}</Typography>
                                </TableCell>
                                <TableCell
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                    style={{ minWidth: 200 }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {this.getLanguageIcon(campaign.language)}
                                        <Typography variant="body2">{this.getLanguageText(campaign.language) || "N/A"}</Typography>
                                    </div>
                                </TableCell>
                                <TableCell
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                    style={{ minWidth: 200 }}
                                >
                                    <Typography variant="body2">{campaign.products || "0"}</Typography>
                                </TableCell>
                                <TableCell
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                >
                                    <Typography variant="body2">{campaign.trainings || "0"}</Typography>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                >
                                    <Typography variant="body2">{campaign.date}</Typography>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{ display: 'flex' }}
                                    className={classNames(
                                        classes.cell,
                                        {
                                            [classes.lastrow]: (campaignKey === sortedData.length - 1)
                                        }
                                    )}
                                >
                                    <IconButton
                                        onClick={(e) => onGetCampaign(e, campaign, false)}
                                        className={classes.editIcon}
                                    >
                                        <EditIcon className={classes.actionIcon} />
                                    </IconButton>
                                    <IconButton
                                        onClick={(e) => onGetCampaign(e, campaign, true)}
                                    >
                                        <FileCopyOutlinedIcon className={classes.actionIcon} />
                                    </IconButton>
                                    <IconButton
                                        disabled={campaign.isPublished !== "1"}
                                        onClick={(e) => onGetCampaign(e, campaign, false, true)}
                                    >
                                        <GetAppIcon className={classNames(classes.actionIcon, {
                                            [classes.actionIconDisabled]: campaign.isPublished !== "1"
                                        })} />
                                    </IconButton>
                                    <IconButton
                                        onClick={(e) => onRemoveCampaign(e, campaign)}
                                    >
                                        <DeleteIcon className={classes.actionIcon} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Loader>
        )
    }
}

export default withTranslation()(withStyles(styles)(StartProducts));