import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiStepLabel from '@material-ui/core/StepLabel';

const styles = theme => ({
    root: {
        fontSize: 11
    },
    alternativeLabel: {},
    active: {
        color: `${theme.palette.primary.main} !important`
    }, //needed so that the &$active tag works
    completed: {
        color: `${theme.palette.primary.main} !important`,
        // textDecoration: 'underline',
    },
    label: {
        fontSize: 11,
        lineHeight: 1.25
    }
});

function StepLabel(props) {
    const {
        children,
        classes,
        className,
        icon,
        ...other
    } = props;
  
    return (
        <MuiStepLabel
            className={classNames(classes.root, className)}
            classes={{
                active: classes.active,
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer,
                label: classes.label,
                completed: classes.completed
            }}
            icon={icon}
            {...other}
        >
            {children}
        </MuiStepLabel>
    )
}

export default withStyles(styles)(StepLabel);