import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../helpers';
import CustomizeField from './CustomizeFields';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { Divider } from '@material-ui/core';
import Button from '../../../../components/Button.component';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
    },
    gridItem: {
        padding: theme.spacing(1), 
    },
    nextButton: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        lineHeight: 2
    },
    rightArrow: {
        marginLeft: 12
    }
})

class CustomizeProduct extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            material: campaign.material,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            materialObject: Core.arrayToObject(props.material, 'key'),
            fields: []
        }
    }

    UNSAFE_componentWillMount() {
        const { prodKey } = this.props;
        const { materialObject } = this.state;
        this.setState({
            fields: materialObject[prodKey].customizeFields,
        })
    }

    render() {
        const {
            classes,
            t,
            prodKey,
            onHandleChangeField,
            products,
            errors,
            isAdmin,
            canEdit,
            nextProduct
        } = this.props;
        const {
            fields,
        } = this.state;
        // console.log('EH props', this.props);
        // console.log('EH state', this.state);

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} className={classes.root}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography variant='body1'>
                                    {/* {desc} */}
                                    {t(`material.${prodKey}.desc.long`)}
                                </Typography>
                            </Grid>
                            {errors &&
                                <Collapse in={errors.length > 0}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.gridItem}
                                    >
                                        <Typography
                                            variant='body1'
                                            color='error'
                                        >
                                            {/* {errors.length < 2 ? 'Controleer veld ' : 'Controleer velden '} */}
                                            {t('customize.checkfields', { count: errors.length })}&nbsp;
                                            {errors.map((error, index) => {
                                                const thisError = fields.find(x => x.field === error)
                                                let text = t(`material.${prodKey}.fields.${thisError.field}.label`)
                                                index < errors.length - 1 ? text += ', ' : text += '.';
                                                return text
                                            })}
                                        </Typography>
                                    </Grid>
                                </Collapse>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {fields.map((field, index) => {
                    const value = Core.getObjectKeyByString(products, prodKey + '.' + field.field);

                    return (
                        <CustomizeField
                            key={index}
                            prodKey={prodKey}
                            field={field}
                            value={value}
                            error={errors.indexOf(field.field) < 0 ? false : true}
                            errorText={field.type !== 'company' ? t(`material.${prodKey}.fields.${field.field}.error`) : ''}
                            isAdmin={isAdmin}
                            canEdit={canEdit}
                            onHandleChangeField={onHandleChangeField}
                        />
                    )
                })}
                {nextProduct && (
                    <React.Fragment>
                        <Divider />
                        <Button primary size="small" className={classes.nextButton} onClick={nextProduct}>
                            {t('customize.nextButton')}
                            <ArrowForwardIcon className={classes.rightArrow} fontSize="small" />
                        </Button>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

CustomizeProduct.defaultProps = {
    language: 'nl',
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeProduct.mapStateToProps, {})(CustomizeProduct)
));