import types from './campaign.types';

const current = {
    data: {
        lane: {
            id: '',
            steps: []
        }
    }
}


export default (state = {
    assets: {
        Company: {},
        SalesUnit: {},
        CourseList: {}
    },
    material: [],
    list: [],
    current: {
        data: {
            company: {}
        }
    },
    presets: {},
    lane: {
        id: '',
        steps: []
    },
    error: null,
}, action) => {

    let campaign = {
        ...state,
        error: null,
        type: action.type
    };

    let newCampaignData;

    switch (action.type) {

        case types.INIT:
            campaign.list = action.payload.list;
            campaign.user = action.payload.user;
            campaign.assets = action.payload.assets;
            campaign.material = action.payload.material;
            campaign.presets = action.payload.presets;
            campaign.current = current;
            campaign.current.data.company = action.payload.company;
            break;

        case types.GET_SINGLE:
            newCampaignData = {
                ...campaign.current.data,
                ...action.payload.data,
                company: campaign.current.data.company
            }
            campaign.current = {
                ...action.payload,
                data: newCampaignData
            }
            break;

        case types.GET_TEMPLATE:
            newCampaignData = {
                ...campaign.current.data,
                ...action.payload.data,
                company: campaign.current.data.company
            }
            campaign.current = {
                ...action.payload,
                data: newCampaignData
            }
            break;

        case types.GET_LIST:
            campaign.list = action.payload.campaigns;
            campaign.user = action.payload.user;
            break;

        case types.CREATE:
            campaign.current = action.payload;
            break;

        case types.UPDATE:
            campaign.current = action.payload;
            break;

        case types.UPDATE_PRODUCTS:
            campaign.current.data.products = action.payload;
            break;

        case types.REMOVE:
            // Do nothing. The action will rerun getList().
            break;

        case types.CLEAR:
            campaign.current = current;
            break;

        case types.LANE:
            campaign.lane = action.payload;
            break;
        
        case types.LOADING:
            campaign.loading = action.payload;
            break;
        
        case types.LOADING_PARTIAL:
            campaign.loadingPartial = action.payload;
            break;

        case types.ERROR:
            campaign.error = action.error;
            break;

        default:
            campaign = state;
    }
    return campaign;
};
