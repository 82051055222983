import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../helpers';
import TrainingsRow from './Trainings.row.component.jsx';
import Paper from '../../../../components/Paper.component';
import CheckBox from '../../../../components/CheckBox.component';
import SearchBar from '../../../../components/SearchBar.component';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { alpha } from '@material-ui/core/styles';

const styles = theme => ({
    productsContainer: {
        paddingBottom: 100
    },
    paper: {
        marginBottom: theme.spacing(2),
        textAlign: 'left',
    },
    filterList: {
        maxHeight: 400,
        overflowY: 'auto',
        flexWrap: 'nowrap',
        paddingLeft: theme.spacing(1)
    },
    filters: {
        position: 'sticky',
        top: theme.spacing(12) 
    },
    filterLabel: {
        fontSize: theme.spacing(1.8)
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        // overflowX: 'hidden',
        // overflowY: 'scroll',
    },
    selection: {
        position: 'absolute',
        minWidth: theme.spacing(48),
        maxWidth: theme.spacing(48),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        bottom: theme.spacing(10.5),
        right: theme.spacing(3),
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        borderRadius: 0,
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    selectionHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: '12px 16px',
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectionTitle: {
        paddingLeft: theme.spacing(1), 
        fontSize: 15
    },
    selectionArticlesLabel: {
        width: theme.spacing(3.5) ,
        height: theme.spacing(3.5) ,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center',
        fontSize: theme.spacing(1.6),
        lineHeight: 2.1,
        borderRadius: '50%',
    },
    selectionItems: {
        padding: '24px 24px 8px 12px',
        alignItems: 'center',
        overflowY: 'auto'
    },
    selectionItem: {
        marginBottom: theme.spacing(2)
    },
    selectionItemText: {
        fontWeight: 'normal',
    },
    selectionIcon: {
        backgroundColor: alpha(theme.palette.common.black, .2),
        color: theme.palette.common.black,
        borderRadius: '50%',
        width: theme.spacing(3.5) ,
        height: theme.spacing(3.5) ,
    },
    category: {
        marginLeft: theme.spacing(2)
    }
})

class TrainingsList extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        const courseLanguage =  Core.campaignLanguage(campaign.current.data.language, 'course');
        const availableLangs = [];
        // campaign.assets.CourseList.forEach(course => {
        //     if (!availableLangs.includes(course.Language)) availableLangs.push(course.Language);
        // })
        // console.log("Available languages", availableLangs);
        return {
            ...props,
            trainings: campaign.assets.CourseList.filter((training) =>
                training.Language === courseLanguage || training.Language === ''),
            categories: campaign.assets.courseCategories.filter((cat) => 
                cat.languages.indexOf(courseLanguage) >= 0),
            campaignLanguage: campaign.current.data.language,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            displayedTrainings: props.trainings,
            trainingsObject: Core.arrayToObject(props.trainings, 'CourseId'),
            selectedTypes: [],
            selectedCats: [],
            searchText: '',
            selectionOpen: false
        }

        this.types = props.trainings.reduce((all, item) => {
            if (!all.includes(item.Type)) {
                all.push(item.Type);
            }
            return all;
        }, []).sort();

        props.onRemoveOldTrainings();
    }

    searchHandler = () => {
        const { trainings } = this.props;
        const { selectedCats, selectedTypes, searchText } = this.state;

        let results = [];

        // If types are selected, filter on types.
        if (selectedTypes.length > 0) {
            trainings.forEach((item) => {
                if (selectedTypes.includes(String(item.Type))) {
                    results.push(item);
                }
            });
        }

        // If categories are selected, filter on categories. Only if course is not selected. Because if course is selected, we need to show all trainings.
        if (selectedCats.length > 0 && !selectedTypes.includes('course')) {
            trainings.forEach((item) => {
                if (selectedCats.includes(String(item.CourseCategoryId))) {
                    results.push(item);
                }
            });
        }

        // If no filters are selected, show all trainings.
        if (selectedTypes.length === 0 && selectedCats.length === 0) {
            results = trainings;
        }

        if (searchText.length > 0) {
            results = results.filter((item) => {
                let searchValue = item.CourseTitle.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                return searchValue.indexOf(searchText) !== -1;
            });
        }

        // Remove duplicates
        results = results.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.Id === item.Id
            ))
        );

        // Sort result on type.
        const sortedList = results.sort((a, b) => {
            if (a.Type < b.Type) {
                return -1;
            }
            if (a.Type > b.Type) {
                return 1;
            }
            return 0;
        });

        this.setState({
            displayedTrainings: sortedList
        })
    }

    textHandler = (e) => {
        this.setState({
            searchText: e.target.value.toLowerCase(),
        }, () => this.searchHandler())
    }

    categoryHandler = (e) => {
        const { selectedCats } = this.state;
        const category = e.target.value;
        const currentCategory = selectedCats.indexOf(category);
        if (currentCategory > -1) {
            selectedCats.splice(currentCategory, 1);
        } else {
            selectedCats.push(category);
        }

        this.setState({
            selectedCats,
        }, () => this.searchHandler())
    }

    typeHandler = (e) => {
        const { selectedTypes } = this.state;  
        const type = e.target.value;
        const currentType = selectedTypes.indexOf(type);

        if (currentType > -1) {
            selectedTypes.splice(currentType, 1);
        } else {
            selectedTypes.push(type);
        }

        // If the type is course, and it is not already selected, we need to select all categories.
        if (type === 'course' && currentType === -1) {
            const { categories } = this.props;
            const selectedCats = categories.map((cat) => String(cat.id));
            return this.setState({
                selectedTypes,
                selectedCats,
            }, () => this.searchHandler())
        }
  
        // If the type is course, and it is already selected, we need to deselect all categories
        if (type === 'course' && currentType > -1) {
            return this.setState({
                selectedTypes,
                selectedCats: [],
            }, () => this.searchHandler())
        }

        this.setState({
            selectedTypes,
        }, () => this.searchHandler())
    }

    renderResults() {
        const { t, view, selectedTrainings, classes, onHandleChange, checkArray } = this.props;
        const { displayedTrainings } = this.state;

        if (view === "presets" && Object.keys(selectedTrainings).length > 0) {
            return (
                <Paper
                    background='light'
                    bordered
                    noShadow
                    className={classes.paper}
                >
                    <List className={classes.list}>
                        {Object.keys(selectedTrainings).map((training, key) => (
                            <TrainingsRow
                                key={training}
                                view={view}
                                checked={false}
                                training={selectedTrainings[training]}
                                onHandleChange={e => onHandleChange(e, training.Id)}
                                noBorder={key + 1 === Object.keys(displayedTrainings).length}
                            />
                        ))}
                    </List>
                </Paper>
            )
        } else if (view === "presets" && selectedTrainings.length === 0) {
            return <Typography>{t('trainings.edit.nothingFound')}</Typography>;
        } else if (view === "edit" && displayedTrainings.length > 0) {
            const sortedDisplayedTrainings = displayedTrainings.sort((a, b) => {
                if (a.Type < b.Type) {
                    return -1;
                }
                if (a.Type > b.Type) {
                    return 1;
                }
                return 0;
            });

            return (
                <Paper
                    background='light'
                    bordered
                    noShadow
                    className={classes.paper}
                >
                    <List className={classes.list}>
                        {sortedDisplayedTrainings.map((training, key) => (
                            <TrainingsRow
                                key={training.CourseId}
                                view={view}
                                checked={checkArray.indexOf(String(training.CourseId)) >= 0}
                                training={training}
                                onHandleChange={e => onHandleChange(e, training.CourseId)}
                                noBorder={key + 1 === displayedTrainings.length}
                            />
                        ))}
                    </List>
                </Paper>
            )
        } else {
            return <Typography>{t('trainings.edit.nothingFound')}</Typography>;
        }
    }
 
    render() {
        const {
            classes,
            t,
            checkArray,
            onHandleChange,
            categories,
            campaignLanguage,
            view,
        } = this.props;
        const {
            trainingsObject,
            selectedTypes,
            selectedCats
        } = this.state;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        return (
            <Grid container spacing={2} className={classes.productsContainer}>
                {view !== "presets" && (
                    <Grid item xs={4}>
                        <Paper
                            padding
                            className={classNames(classes.paper, classes.filters)}
                        >
                            <Typography variant="subtitle2" paragraph gutterBottom>
                                {t('trainings.edit.filter')}
                            </Typography>
                            <FormGroup className={classes.filterList}>
                                {this.types.map((type, key) => {
                                    const list = [(
                                        <FormControlLabel
                                            key={type}
                                            control={
                                                <CheckBox
                                                    checked={selectedTypes.includes(type)}
                                                    onChange={this.typeHandler}
                                                    value={type}
                                                    indeterminate={(() => {
                                                        if (type === 'course') {
                                                            return selectedCats.length > 0 && selectedCats.length < categories.length;
                                                        }

                                                        return false;
                                                    })()}
                                                    color="primary"
                                                />
                                            }
                                            label={t(`trainings.edit.${type.toLowerCase()}`)}
                                        />
                                    )];

                                    if (type === 'course') {
                                        categories.map((cat) => {
                                            const checked = selectedCats.includes(String(cat.id));
        
                                            const label = (() => {
                                                const language = Core.campaignLanguage(campaignLanguage, 'cat')
        
                                                if (cat.label[language]) {
                                                    return cat.label[language];
                                                }

                                                return cat.label['en-gb'];
                                            })();

                                            list.push((
                                                <div className={classes.category} key={cat.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <CheckBox
                                                                value={String(cat.id)}
                                                                checked={checked}
                                                                onChange={this.categoryHandler}
                                                            />
                                                        }
                                                        label={label}
                                                    />
                                                </div>
                                            )
                                        )});
                                    }

                                    return list;
                                })}
                            </FormGroup>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={view === 'edit' ? 8 : 12}>
                    {view !== "presets" && (
                        <SearchBar
                            onSearchHandler={this.textHandler}
                            placeholder={t('trainings.edit.searchBar')}
                        />
                    )}
                    
                    {this.renderResults()}
                </Grid>
                
                {view === 'edit' && checkArray.length > 0 &&
                    <Paper
                        className={classes.selection}
                        style={{ maxHeight: this.state.selectionOpen ? '70vh' : 52 }}
                    >
                        <Grid container className={classes.selectionHeader} onClick={() => this.setState({ selectionOpen: !this.state.selectionOpen })}>
                            <Grid item xs={1}>
                                <IconButton style={{ padding: 0 }}>
                                    {this.state.selectionOpen ? (
                                        <ExpandMoreIcon
                                            classes={{root: classes.selectionIcon}}
                                        />
                                    ) : (
                                        <ExpandLessIcon
                                            classes={{root: classes.selectionIcon}}
                                        />
                                    )}
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="h2" color="inherit" className={classes.selectionTitle}>
                                    {t('trainings.edit.selected')}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body1" color="inherit" className={classes.selectionArticlesLabel}>
                                    {checkArray.length}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.selectionItems} style={{ paddingBottom: checkArray.length > 12 ? '80px' : '8px', maxHeight: this.state.selectionOpen ? '70vh' : 52 }}>
                            {checkArray.map(id => (
                                <React.Fragment key={id}>
                                    <Grid item xs={11} >
                                        <Typography variant="h6" className={classes.selectionItemText} noWrap>{trainingsObject[id] && trainingsObject[id].CourseTitle}</Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{ textAlign: 'left' }}>
                                        <IconButton onClick={() => onHandleChange(false, id)} style={{ padding: 8 }}>
                                            <CloseIcon className={classes.closeIcon} fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Paper>
                }
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(TrainingsList.mapStateToProps, {})(TrainingsList)
));