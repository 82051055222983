import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
// import commonEN from './locales/en/common';
// import commonNL from './locales/nl/common';
// import onboardingEN from './locales/en/onboarding';
// import onboardingNL from './locales/nl/onboarding';

i18n
  .use(Backend)
  .init({
    // we init with resources
    // resources: {
    //   en: {
    //     common: commonEN,
    //     onboarding: onboardingEN
    //   },
    //   nl: {
    //     common: commonNL,
    //     onboarding: onboardingNL
    //   }
    // },
    Backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: ['en', 'de', 'fr', 'nl', 'es', 'it', 'be', 'wa', 'pt', 'at', 'ch'],
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      useSuspense: true
    }
  });

export default i18n;