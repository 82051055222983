import React from 'react';
import { connect } from 'react-redux';
import { Core } from '../../../../helpers';
import CustomizePreviewIframe from './CustomizePreviewIframe.component';
import Export from '../../../../helpers/Export';
import LWConnect from '../../../../../_LWCore/helpers/LWConnect/lw-connect';

class CustomizePreviewPdf extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            material: Core.arrayToObject(campaign.material, 'key'),
            presets: campaign.presets,
            trainings: Core.arrayToObject(campaign.assets.CourseList, 'CourseId'),
            savedTrainings: campaign.current.data.trainings ? campaign.current.data.trainings : {},
            campaignLanguage: campaign.current.data.language
        };
    }

    constructor(props) {
        super(props)

        const thisMaterial = props.material[props.prodKey];

        this.state = {
            previewUrl: '',
            loading: true,
            sizes: {
                output: thisMaterial.output,
                contentWidth: this.setInitialSize(thisMaterial.output.width, thisMaterial.output.type),
                contentHeight:  this.setInitialSize(thisMaterial.output.height, thisMaterial.output.type),
                scale: 1,
                pages: 1
            },
            compareProduct: JSON.stringify(props.product),
            compareCompany: JSON.stringify(props.company),
        }
    }

    UNSAFE_componentWillMount() {
        const {
            product,
            company,
            showVariant
        } = this.props;

        if (product && Object.keys(product).length >= 0) {
            this.getResultUrl(product, company, showVariant);
        }
    }

    componentDidMount() {
        const {
            product,
            material,
            prodKey,
        } = this.props;

        this.resizePreview(material[prodKey], product);
        this.mounted = true;
    }

    componentWillUnmount(){
      this.mounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {
            material,
            prodKey,
            product,
            showVariant
        } = this.props;
        const {
            compareProduct,
            compareCompany,
        } = this.state;

        if (product) {
            const nowProduct = JSON.stringify(nextProps.product);
            const nowCompany = JSON.stringify(nextProps.company);
    
            if (compareProduct !== nowProduct || nowCompany !== compareCompany || showVariant !== nextProps.showVariant) {
                this.setState({
                    compareProduct: nowCompany,
                    compareCompany: nowCompany,
                    loading: true
                }, () =>  this.getResultUrl(nextProps.product, nextProps.company, nextProps.showVariant))
    
                if (product.orientation && nextProps.product.orientation !== product.orientation) {
                    this.resizePreview(material[prodKey], nextProps.product);
                }
            }
        }
    }

    setInitialSize = (size, type) => {
        const mmToPixel = Core.mmToPixel();

        switch (type) {
            case 'pdf':
                return size * mmToPixel;
            default:
                return size ? size : 0;
        }
    }

    resizePreview = (material, product) => {
        const {
            sizes,
        } = this.state;

        const wrapper = document.getElementById(`wrapper_${material.key}`);

        if (product && wrapper) {
            const wrapperWidth = wrapper.clientWidth;
    
            let contentWidth = sizes.contentWidth;
            let contentHeight = sizes.contentHeight;
            let pages = sizes.pages;
    
            switch (material.output.type) {
                case 'pdf':
    
                    const range = material.output.pageRange.split('-');
                    if (range.length > 1) {
                        pages = range[range.length - 1] - range[0] + 1
                    }
    
                    if (product.orientation) {
                        const orientation = product.orientation;
                        const height = contentHeight;
                        const width = contentWidth
                        
                        if (orientation === 'port') {
                            if (width > height) {
                                contentHeight = width;
                                contentWidth = height;
                            }
                        }
                        else {
                            if (height > width) {
                                contentHeight = width;
                                contentWidth = height;
                            }
                        }
                    }
    
                    break;
                default:
                    // console.log('No resizing done.')
            }
            const scale =  wrapperWidth / contentWidth;
            const newSizes = {
                contentWidth: contentWidth,
                contentHeight: contentHeight,
                scale: scale,
                pages: pages,
            }
            this.setState({
                sizes: newSizes,
            })
        }
    }

    getVariant = (product, variant) => {
        const {
            material,
            prodKey,
        } = this.props;
        const output = material[prodKey].output;
        const showProduct = JSON.parse(JSON.stringify(product))

        if (output.iterate && Array.isArray(showProduct[output.iterate])) {
            showProduct[output.iterate].splice(0, variant);
        }
        return showProduct
    }

    getResultUrl = async (product, company, showVariant) => {
        const {
            trainings,
            savedTrainings,
            material,
            presets,
            prodKey,
            campaignLanguage,
        } = this.props;

        const thisMaterial = material[prodKey]

        let iterateVariant = 1;
        if (thisMaterial.output && thisMaterial.output.iterateVariant) {
            const max = thisMaterial.output.iterateVariant.num;
            iterateVariant = showVariant - (Math.floor(showVariant / max) * max) + 1;
        }

        const productVariant = this.getVariant(product, showVariant);

        const previewData = Export.toHtmlTemplate(
            productVariant,
            trainings,
            savedTrainings,
            thisMaterial,
            company,
            presets,
            campaignLanguage,
            iterateVariant,
        );
        const {error, data} = await LWConnect.post('uploadJSON', { data: previewData });

        if (!!data && !!data.url) {
            const previewUrl = `${process.env.TEMPLATE_HOST}${prodKey}/index.html?resourceLoc=${data.url}`
            if (this.mounted) {
                this.setState({
                    previewUrl,
                    loading: false
                })
            }
        }
        else if (!!error) {
            console.error(error)
        }
        else (
            console.error("Error getting preview url")
        )
    }

    render() {
        const {
            prodKey,
        } = this.props;
        const {
            previewUrl,
            loading,
            sizes,
        } = this.state;
        
        return(
            <CustomizePreviewIframe
                prodKey={prodKey}
                previewUrl={previewUrl}
                sizes={sizes}
                scrolling="auto"
                loading={loading}
            />
        )
    }
}

export default connect(CustomizePreviewPdf.mapStateToProps, {})(CustomizePreviewPdf);