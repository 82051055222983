import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import LogoIcon from '../../LogoIcon.component';

const styles = theme => ({
    link: {
        color: theme.palette.common.white,
        textDecoration: 'underline',
        fontWeight: 600
    },
    logo: {
        marginRight: theme.spacing(2)
    }
});
class Exit extends React.Component {
    render() {
        const {
            classes,
            backText,
            loading = false
        } = this.props;

        return (
            <Link className={classes.link} variant="body2" href="https://my.goodhabitz.com/">
                <LogoIcon className={classes.logo} />
                {!loading && backText}
            </Link>
        );
    }
}

export default withStyles(styles)(Exit);