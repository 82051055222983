import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../helpers';
import deepmerge from 'deepmerge';
import LayoutBody from '../../../components/LayoutBody.component';
import Products from './components/Products.list.component.jsx';
import FixScrollbar from '../../../components/FixScrollbar.component';
import { withStyles } from '@material-ui/core/styles';
import StepHeader from '../../../components/StepHeader';
import CampaignFooter from '../../../components/CampaignFooter.component';
import CampaignHeader from '../../../components/CampaignHeader.component';
import CampaignActions from '../../../reducers/Campaign/campaign.actions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from '@material-ui/core';

const styles = theme => ({
    productsContainer: {
        marginTop: -24,
    },
    button: {
        marginLeft: theme.spacing(1), 
        margnRight: theme.spacing(1), 
    },
    link: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(4.5) ,
        cursor: 'pointer'
    }
});

class ProductsList extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            material: campaign.material,
            current: campaign.current,
            lane: campaign.lane.id,
            steps: campaign.lane.steps,
            canEdit: campaign.current.productsReadOnly !== '1',
            isAdmin: campaign.user.roles && campaign.user.roles.includes('admin')
        };
    }

    constructor(props) {
        super(props);

        let view = 'edit';
        const { current } = props;
        if (current.data.lane.id === 'campaign') {
            view = 'presets';
        }

        this.state = {
            view,
            itemToEdit: {
                products: props.current.data.products ? props.current.data.products : {},
                productsInactive: props.current.data.productsInactive ? props.current.data.productsInactive : {},
            },
        }
    }

    switchView = () => {
        this.setState({ view: 'edit' })
    }

    handleChange = (e, selected) => {
        const { itemToEdit } = this.state;
        const products = itemToEdit.products;
        const productsInactive = itemToEdit.productsInactive;
        const prodKey = selected.key;
        const fields = selected.customizeFields;
        let newProducts = {};
        let newProductsInactive = {};

        if (e && e.target.checked) {
            if (productsInactive[prodKey]) {
                newProducts = deepmerge(products, { [prodKey]: productsInactive[prodKey] });
                newProductsInactive = productsInactive;
                delete newProductsInactive[prodKey];
            }
            else {
                let newProduct = { [prodKey]: {} };
                fields.forEach(function (customizeField) {
                    let newField = {
                        [prodKey]: Core.createObjectFromString(customizeField.field, undefined),
                    };
                    newProduct = deepmerge(newProduct, newField);
                })
                newProducts = deepmerge(products, newProduct);
            };
        }
        else {
            newProducts = products;
            newProductsInactive = deepmerge(productsInactive, { [prodKey]: newProducts[prodKey] });
            delete newProducts[prodKey];
        }

        this.props.updateProducts(newProducts).then(
            this.setState({
                itemToEdit: {
                    ...itemToEdit,
                    productsInactive: newProductsInactive,
                    products: newProducts
                },
            })
        )
    }

    handlePresets = () => {
        // ToDo: Change when presets are actually loaded from API. For now select first 2 materials.
        const { material } = this.props;
        const { itemToEdit } = this.state;
        if (Object.keys(itemToEdit.products).length < 1) {
            let presets = {};
            let i = 0;
            while (i < 2) {
                let prodKey = material[i].key;
                let fields = material[i].customizeFields;
                let newProduct = {}
                fields.forEach(function (customizeField) {
                    let newField = {
                        [prodKey]: Core.createObjectFromString(customizeField.field, undefined),
                    };
                    newProduct = deepmerge(newProduct, newField);
                })
                presets[material[i].key] = newProduct;
                i++
            }
            this.setState({
                itemToEdit: {
                    ...itemToEdit,
                    products: presets,
                }
            })
        }
    }

    render() {
        const {
            classes,
            t,
            steps,
            activeStep,
            canEdit,
            getCurrentStep,
            isAdmin,
            onSelectStep,
            onClickStep,
        } = this.props;
        const {
            view,
            itemToEdit,
        } = this.state;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        const checkArray = Object.keys(itemToEdit.products);
        const stepIsCompleted = checkArray.length > 0;

        return (
            <React.Fragment>
                <FixScrollbar />
                <StepHeader
                    itemToEdit={itemToEdit}
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={onClickStep}
                    stepIsCompleted={stepIsCompleted}
                    lane={this.props.lane}
                    helpText={t('common.header.button')}
                />
                <CampaignHeader
                    title={t('products.edit.title')}
                    intro={t('products.edit.intro')}
                />
                <LayoutBody
                    component="section"
                    width="medium"
                    textAlignCenter
                    paddingBottom
                    className={classes.productsContainer}
                >
                    <Products
                        view={view}
                        isAdmin={isAdmin}
                        checkArray={checkArray}
                        selectedProducts={itemToEdit.products}
                        onHandleChange={this.handleChange}
                        onRemoveOldTrainings={this.removeOldTrainings}
                    />
                </LayoutBody>
                <CampaignFooter
                    onSelectPreviousStep={() => onSelectStep(false, -1, itemToEdit, stepIsCompleted)}
                    onSelectNextStep={() => onSelectStep(false, 1, itemToEdit, stepIsCompleted)}
                    previousStepIcon={<ArrowBackIcon fontSize="small" />}
                    nextStepIcon={<ArrowForwardIcon fontSize="small" />}
                    nextStepDisabled={!stepIsCompleted}
                    getCurrentStep={getCurrentStep}
                >
                    {view === "presets" && <Link onClick={this.switchView} className={classes.link} underline="always" variant="body2">{t('common.buttons.modify')}</Link>}
                </CampaignFooter>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(ProductsList.mapStateToProps, {
        updateProducts: CampaignActions.updateProducts,
    })(ProductsList)
));