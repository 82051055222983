import axios from 'axios';
import LWConnect from './lw-connect';

const LWPublish = axios.create({
    baseURL: process.env.PUBLISH_HOST,
    timeout: process.env.APP_API_TIMOUT
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
LWPublish.interceptors.request.use(
    (config) => {
        let data = {};
        let token = '';

        try {
            if (process.env.APP_AUTH_PROVIDER && process.env.APP_AUTH_PROVIDER === 'localStorage') {
                token = localStorage.getItem('publishToken');
            }
            if (token && config && config.data && typeof config.data === 'string') {
                data = JSON.parse(config.data);
                config.data = JSON.stringify({ token: token, ...data });
            }

            if (token && config && config.data && typeof config.data === 'object') {
                config.data = { token: token, ...config.data };
            }

            if (config.method === 'get' && !config.params) {
                config.params = { token: token };
            }

            if (config.method === 'get' && typeof config.params === 'object') {
                config.params = { token: token, ...config.params };
            }
            return config;
        } catch (e) {
            console.error("Publish error", e);
        }
    },

    (error) => {
        console.error("Publish error", error);
        return Promise.reject(error);
    }
);

/**
 * Intercept responses
 * 1.   Catch connection/auth errors
 * 2.   Catch API errors, and tranform to default 'Promise.reject'.
 */
LWPublish.interceptors.response.use(
    // LW custom error middleware, response has code 200 but success=0 in data
    async (response) => {
        if (response.data && (response.data.error == 'noToken' || response.data.error == 'InvalidCredentials')) {

            // Handle non existing or invalid publish token.
            let originalRequest = response.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                const tokenResponse = await LWConnect.get('getPublishToken');
                if (tokenResponse.success === 1) {
                    const publishToken = tokenResponse.data.key;
                    localStorage.setItem('publishToken', publishToken);

                    // Replace publish token in original request.
                    let originalData = JSON.parse(originalRequest.data);
                    originalData = {
                        ...originalData,
                        token: publishToken
                    }
                    originalRequest = {
                        ...originalRequest,
                        data: JSON.stringify(originalData)
                    }
                    
                    // Retry request with updated publish token.
                    return LWPublish(originalRequest);
                }
                else {
                    return Promise.reject(response.data ? response.data : response);
                }
            }
        }
        else if (!response.data || !response.data.success || response.data.error || response.data.errors ) {
            return Promise.reject(response.data ? response.data : response);
        }
        return response.data ? response.data : response;
    },

    // Default error handler, because response is not 200. Handled when no connection or page not found (for example).
    (error) => {
        console.log("ERROR", error)
        return Promise.reject(error);
    });

export default LWPublish;
