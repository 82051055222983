import React from 'react';
import IconTablecard from '../media/tablecard.svg';
import IconActionplan from '../media/actionplan.svg';
import IconEmail from '../media/email.svg';
import IconMockuptrainings from '../media/mockuptrainings.svg';
import IconOverviewposter from '../media/overviewposter.svg';
import IconPlacemat from '../media/placemat.svg';
import IconPostcard from '../media/postcard.svg';
import IconTrainingslist from '../media/training_list.svg';
import IconTrainingslistDesc from '../media/training_list_desc.svg';
import IconVideo from '../media/video.svg';
import IconPosters from '../media/posters.svg';
import IconMockupSituated from '../media/mockupsituated.svg';

function ProductIcon(props) {
    const { icon, className } = props;

    const icons = {
        tablecard: IconTablecard,
        actionplan: IconActionplan,
        email: IconEmail,
        mockuptrainings: IconMockuptrainings,
        overviewposter: IconOverviewposter,
        placemat: IconPlacemat,
        postcard: IconPostcard,
        training_list: IconTrainingslist,
        training_list_desc: IconTrainingslistDesc,
        video: IconVideo,
        posters: IconPosters,
        mockupsituated: IconMockupSituated
    }

    return (
        <img
            src={icons[icon]}
            className={className}
        />
    )
}

export default ProductIcon;