import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion'
import ExpansionPanelSummary from './ExpansionPanelSummary.component';
import ExpansionPanelDetails from './ExpansionPanelDetails.component';

const styles = theme => ({
    root: {
        borderRadius: theme.spacing(1), 
        marginBottom: theme.spacing(2),
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        '&:last-child': {
            marginBottom: theme.spacing(2),
        }
    }
})

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

class ExpansionPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            children,
            classes,
            title,
            onChange,
            expanded,
            error,
            id,
            ...other
        } = this.props;
        // console.log('EH props', this.props);

        return (
            <Accordion
                expanded={expanded}
                onChange={onChange}
                id={id}
                classes={{
                    root: classes.root,
                    expanded: classes.expanded
                }}
            >
                <ExpansionPanelSummary
                    expanded={expanded}
                    onChange={onChange}
                    error={error}
                    {...other}
                >
                    {title}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    expanded={expanded}
                    {...other}
                >
                    {children}
                </ExpansionPanelDetails>
            </Accordion>
        );
    }
}

export default withStyles(styles)(ExpansionPanel);