import React from 'react';
import LWConnect from '../../../../_LWCore/helpers/LWConnect/lw-connect';
import CampaignAction from '../../../reducers/Campaign/campaign.actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import store from '../../../store';
import { withTranslation } from 'react-i18next';
import { Core, Tasks, Publish } from '../../../helpers';
import LayoutBody from '../../../components/LayoutBody.component';
import Paper from '../../../components/Paper.component';
import ResultsRow from './components/Results.row.component.jsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import StepHeader from '../../../components/StepHeader/components/StepHeader.component';
import CampaignHeader from '../../../components/CampaignHeader.component';
import CampaignFooter from '../../../components/CampaignFooter.component';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LinearProgress from '@material-ui/core/LinearProgress';
import ConfettiCannon from '../../../components/ConfettiCannon.component';
import { Link } from '@material-ui/core';

const styles = theme => ({
    paper: {
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        overflow: 'hidden',
    },
    resultsContainer: {
        marginTop: -24,
        paddingBottom: 100
    },
    resultHeader: {
        padding: theme.spacing(2)
    },
    table: {
        margin: 0,
        width: '100%',
    },
    header: {
        backgroundColor: theme.palette.grey[200],
    },
    last: {
        textAlign: 'right',
    },
    edit: {
        marginLeft: 'auto',
        marginRight: 0,
        boxShadow: theme.shadows[0],
        backgroundColor: theme.palette.common.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
    },
    button: {
        marginLeft: theme.spacing(1), 
        margnRight: theme.spacing(1), 
    },
    rightArrow: {
        marginLeft: theme.spacing(1.5),
        transform: 'translateY(1px)'
    },
    link: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(4.5) ,
        cursor: 'pointer'
    }
});

class Results extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;

        return {
            ...props,
            campaign,
            steps: campaign.lane.steps,
            material: Core.arrayToObject(campaign.material, 'key'),
            products: campaign.current.data.products,
            language: campaign.current.data.language,
            company: campaign.current.data.company,
            presets: campaign.presets,
            trainings: Core.arrayToObject(campaign.assets.CourseList, 'CourseId'), // Actual trainings as received from API.
            savedTrainings: campaign.current.data.trainings, // Trainings as stored in the campaign.
            current: campaign.current,
            lane: campaign.lane,
            customerCountry: campaign.user.CustomerCountry.toLowerCase(),
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isWaiting: true,
            progress: 1,
            publishResult: {},
            publishResultAll: false,
            resultsList: [],
            pricesLoading: true,
            taskList: Tasks.build(props.products, props.material, props.trainings, props.savedTrainings, props.company, props.presets, props.language),
            resultsCount: Tasks.resultsCount(props.products, props.material),
            itemToEdit: {
                title: props.campaign.current.title ? props.campaign.current.title : '',
                language: props.campaign.current.data.language ? props.campaign.current.data.language : defaultLang
            }
        }
    }

    UNSAFE_componentWillMount() {
        const {
            current,
            company,
            products,
            lane
        } = this.props;
        const checkOld = current.data.published ? current.data.published.check : '';
        const checkNew = JSON.stringify({
            company: company,
            trainings: current.data.trainings,
            products: products
        })
        const hasResults = current.data.published && current.data.published.publishResult && Object.keys(current.data.published.publishResult).length > 0;

        const jobToken = current.data.jobToken || null;

        if (hasResults && (checkOld === checkNew)) {
            const resultsList = this.setList(current.data.published.publishResult);
            this.setState({
                isWaiting: false,
                publishResult: current.data.published.publishResult,
                publishResultAll: current.data.published.publishResultAll,
                resultsList
            });
        } else if (hasResults && (checkOld !== checkNew)) {
            this.publish();
        } else if (!hasResults && (checkOld === checkNew)) {
            if (jobToken) {
                this.publish(jobToken);
            } else {
                this.publish();
            }
        } else if (!hasResults && (checkOld !== checkNew)) {
            this.publish();
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.isWaiting == false && this.state.isWaiting == true) {
            this.props.update(nextState.newCurrent)
        }
    }

    setList = (results) => {
        const resultsList = [];
        Object.keys(results).forEach(cat => {
            results[cat].forEach(prod => {
                resultsList.push({
                    ...prod,
                    cat: cat
                })
            })
        })
        return resultsList;
    }

    goHome = () => {
        store.dispatch(push('/start'));
    }

    publish = async (jobToken = null) => {
        const {
            products,
            company,
            current
        } = this.props;
        const {
            taskList
        } = this.state;

        const publishProcess = new Publish(taskList, jobToken);

        publishProcess.on('start', (publishData) => {
            console.log(`${publishData.status} [${publishData.jobToken}]`);

            const check = {
                company: company,
                trainings: current.data.trainings,
                products: products
            }
            const published = {
                check: JSON.stringify(check),
                publishResult: {}
            }
            const newCurrent = {
                ...current,
                data: {
                    ...current.data,
                    published,
                    jobToken: publishData.jobToken
                },
            }

            this.props.update(newCurrent);
        });

        publishProcess.on('progress', (publishData) => {
            const tasksDone = publishData.data.tasksDone;
            const tasksTotal = publishData.data.tasksTotal;
            const progress = Math.round(tasksDone / tasksTotal * 100);

            const result = {};
            let cat = '';

            if (publishData.data.products) {
                publishData.data.products.forEach((pub) => {
                    cat = pub.product.cat
                    if (cat) {
                        if (!result[cat]) {
                            result[cat] = [];
                        }
                        result[cat].push({
                            name: pub.name,
                            fileName: pub.product.fileName,
                            url: pub.product.url,
                            thumb: pub.product.thumb,
                            pages: pub.product.pages ? pub.product.pages : 1
                        })
                    }
                })
            }

            this.setState({
                progress: progress,
                resultsList: this.setList(result)
            })
            console.log(`${progress}% ${publishData.data.status} [${publishData.jobToken}]`);
        });

        publishProcess.on('finish', (publishData) => {
            // console.log("Publishdata", publishData);
            const result = {};
            let resultAll = '';
            let cat = '';

            if (publishData.data.products) {
                publishData.data.products.forEach((pub) => {
                    cat = pub.product.cat
                    if (!cat) {
                        resultAll = pub.product;
                    }
                    else {
                        if (!result[cat]) {
                            result[cat] = [];
                        }
                        result[cat].push({
                            name: pub.name,
                            fileName: pub.product.fileName,
                            url: pub.product.url,
                            thumb: pub.product.thumb,
                            pages: pub.product.pages ? pub.product.pages : 1
                        })
                    }
                })
            }

            const check = {
                company: company,
                trainings: current.data.trainings,
                products: products
            }
            const published = {
                check: JSON.stringify(check),
                publishResult: result,
                publishResultAll: resultAll
            }
            const newCurrent = {
                ...current,
                data: {
                    ...current.data,
                    published
                }
            }

            delete newCurrent.data.jobToken;

            const resultsList = this.setList(newCurrent.data.published.publishResult);

            this.setState({
                isWaiting: false,
                publishResult: result,
                publishResultAll: resultAll,
                newCurrent: newCurrent,
                resultsList
            });
        });

        publishProcess.on('error', (publishData) => {
            console.error('Process error', publishData);
        });

        try {
            if (jobToken) {
                await publishProcess.poll();
            } else {
                await publishProcess.start();
            }
        } catch (e) {
            console.error(e);
        }
    }

    createLink = (fileName, url, type) => {
        if (type === 'download') {
            return url;
        }

        return `${process.env.TEMPLATE_HOST}download_utility?filename=${fileName}&url=${encodeURIComponent(url)}`
    }

    trackDownload = (fileName, url, productType) => {
        const { current } = this.props;
        const payload = {
            token: localStorage.getItem('token'),
            campaignId: current.id,
            fileName: fileName,
            url: url,
            productType: productType,
            timestamp: Date.now()
        }
        if (current.data && current.data.templateCampaignId) {
            payload.templateCampaignId = current.data.templateCampaignId;
        }
        LWConnect.post('logging/download', payload);
        return true;
    }

    selectNextStep = (fileName, url) => {
        this.trackDownload(fileName, url, 'zipped_campaign');
        window.location.href = this.createLink(fileName, url);
    }

    render() {
        const {
            classes,
            t,
            onSelectStep,
            getCurrentStep,
            current,
            material,
            steps,
            activeStep,
            onClickStep
        } = this.props;
        const {
            isWaiting,
            progress,
            publishResultAll,
            resultsList,
            itemToEdit,
            resultsCount
        } = this.state;
        const stepIsCompleted = itemToEdit.language.length > 0;

        const results = [...Array(resultsCount).keys()];
        resultsList.reverse().forEach((item, key) => {
            results[key] = item;
        });

        return (
            <React.Fragment>
                <StepHeader
                    itemToEdit={itemToEdit}
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={onClickStep}
                    stepIsCompleted={stepIsCompleted}
                    lane={this.props.lane.id}
                    helpText={t('common.header.button')}
                />
                <CampaignHeader
                    title={t('results.title')}
                    intro={t('results.intro')}
                />
                {/*<Wait
                    isWaiting={isWaiting}
                    progress={progress}
                />*/}
                {isWaiting && (
                    <ConfettiCannon
                        startVelocity={80}
                    />
                )}
                <LayoutBody
                    component="section"
                    width="small"
                    textAlignCenter
                    paddingBottom
                    className={classes.resultsContainer}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper
                                bordered
                                className={classes.paper}
                            >
                                <Grid container className={classes.resultHeader}>
                                    <Grid item xs={11}>
                                        <Typography variant="body2" className={classes.activity}>
                                            {isWaiting ? (
                                                <React.Fragment>
                                                    {resultsList && resultsList.length ? t('results.progress.working', { product: resultsList[resultsList.length - 1].name }) : t('results.progress.notDone', { productCount: resultsCount })}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {t('results.progress.done')}
                                                </React.Fragment>
                                            )}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Typography variant="body2" className={classes.activity} style={{ textAlign: 'right' }}>
                                            {isWaiting && progress + "%"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {isWaiting ? (
                                    <LinearProgress color="primary" variant="determinate" value={progress} />
                                ) : (
                                    <LinearProgress color="primary" variant="determinate" value={100} />
                                )}

                                {results.map((item, index) => (
                                    <ResultsRow
                                        key={index}
                                        item={item}
                                        material={material[item.cat]}
                                        onCreateLink={this.createLink}
                                        onTrackDownload={this.trackDownload}
                                    />
                                ))}
                            </Paper>
                        </Grid>
                    </Grid>
                </LayoutBody>
                <CampaignFooter
                    onSelectPreviousStep={() => onSelectStep(false, -1, itemToEdit, stepIsCompleted)}
                    onSelectNextStep={() => this.selectNextStep(`${Core.slugify(current.title)}.zip`, publishResultAll)}
                    previousStepIcon={<ArrowBackIcon fontSize="small" />}
                    nextStepIcon={<GetAppIcon fontSize="small" />}
                    nextStepDisabled={!publishResultAll}
                    getCurrentStep={getCurrentStep}
                >
                    <Link onClick={this.goHome} className={classes.link} underline="always" variant="body2">{t('results.overview')}</Link>
                </CampaignFooter>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(Results.mapStateToProps, {
        update: CampaignAction.update,
        // updateCart: ShopAction.updateCart,
        // getPrices: ShopAction.getPrices
    })(Results)
));
