import axios from 'axios';
import store from '../../../src/store';

const LWConnect = axios.create({
    baseURL: process.env.APP_API_HOST,
    timeout: process.env.APP_API_TIMOUT
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
LWConnect.interceptors.request.use(
    (config) => {
        let data = {};
        let auth = {};

        try {
            if (process.env.APP_AUTH_PROVIDER && process.env.APP_AUTH_PROVIDER === 'localStorage') {
                auth = { token: localStorage.getItem('token') };
            } else {
                auth = store.getState().auth ? store.getState().auth : {};
            }
            const {id, token} = auth;
            if (token && config && config.data && typeof config.data === 'string') {
                data = JSON.parse(config.data);
                config.data = JSON.stringify({ userId: id, token: token, ...data });
            }

            if (token && config && config.data && typeof config.data === 'object') {
                config.data = { userId: id, token: token, ...config.data };
            }

            if (config.method === 'get' && !config.params) {
                config.params = { userId: id, token: token };
            }

            if (config.method === 'get' && typeof config.params === 'object') {
                config.params = { userId: id, token: token, ...config.params };
            }

            return config;
        } catch (e) {
            console.error(e);
        }
    },

    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Intercept responses
 * 1.   Catch connection/auth errors
 * 2.   Catch API errors, and tranform to default 'Promise.reject'.
 */
LWConnect.interceptors.response.use(
    // LW custom error middleware, response has code 200 but success=0 in data
    (response) => {
        if (!response.data || !response.data.success || response.data.error || response.data.errors ) {
            if (response.data && response.data.error === 'userIncorrect') {
                localStorage.clear();
                location.href = '/';
                return;
            }
            return Promise.reject(response.data ? response.data : response);
        }
        return response.data ? response.data : response;
    },

    // Default error handler, because response is not 200. Handled when no connection or page not found (for example).
    (error) => {
        return Promise.reject(error);
    });

export default LWConnect;
