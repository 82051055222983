import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    borderRadius: 100,
    textTransform: 'none',
  }
});

function Button(props) {
  const {
    classes,
    className,
    style,
    primary,
    secondary,
    ...other
  } = props;

  const variant = primary ? 'contained' : secondary ? 'contained' : 'text';
  const color = primary ? 'primary' : secondary ? 'secondary' : 'default';
  const disableRipple = primary ? false : secondary ? false : true;
  
  return (
    <MuiButton
        variant={variant}
        color={color}
        style={style}
        className={classNames(classes.root, className)}
        // classes={{
        //   text: classes.default,
        //   sizeSmall: classes.small,
        // }}
        disableRipple={disableRipple}
        {...other}
    />
  );
}

export default withStyles(styles)(Button);