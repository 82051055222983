import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { formatLocaleCurrency } from 'country-currency-map';

const styles = theme => ({
    chip: {
        borderColor: theme.palette.grey[200],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1), 
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1)
    },
    pick: {
        color: theme.palette.text.primary
    },
    price: {
        marginRight: theme.spacing(4)
    }
})


class ResultsScale extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showQuantities: this.getQuantities()
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.quantity !== this.props.quantity) {
            this.setState({showQuantities: this.getQuantities()})
        }
    }

    getQuantities = () => {
        const {
            quantity,
            prices
        } = this.props;

        // When API returns no prices retrun empty array.
        if (!prices || !prices.quantityValues) return [];

        const currentIndex = quantity === 0 ? 0 : prices.quantityValues.indexOf(quantity);
        const maxIndex = prices.quantityValues.length - 1;
        let quantityArray = [];
        if (currentIndex > maxIndex - 3) {
            quantityArray = prices.quantityValues.slice(maxIndex - 3, maxIndex + 1);
        }
        else if (currentIndex > 0) {
            quantityArray = prices.quantityValues.slice(currentIndex - 1, currentIndex + 3);
        }
        else {
            quantityArray = prices.quantityValues.slice(currentIndex, currentIndex + 4);
        }
        return quantityArray;
    }

    renderValues = (index) => {
        const {
            showQuantities
        } = this.state;
        const {
            classes,
            quantity,
            prices
        } = this.props;
        let values = [showQuantities[index - 1], showQuantities[index]];
        return (
            <Grid container key={index}>
                {values.map( (q, i) =>
                    <React.Fragment key={i}>
                        <Grid item xs={3}>
                            <Typography
                                key={q}
                                variant="caption"
                                className={classNames({[classes.pick]: q === quantity})}
                            >
                            <span
                                className={classes.chip}
                            >
                                {q}
                            </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                key={q}
                                align='right'
                                variant="caption"
                                className={classNames(classes.price, {[classes.pick]: q === quantity})}
                            >
                                {formatLocaleCurrency(prices.quantities[q].Standard_price, prices.quantities[q].currency)}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    }

    render() {
        const {
            showQuantities
        } = this.state;
        // console.log("ResultsScale props", this.props);
        // console.log("ResultsScale state", this.state);

        return (
            <Grid
                container
            >
             {showQuantities.length > 0 ?
                showQuantities.map( (q, index) => {
                    return index % 2 ?
                        this.renderValues(index)
                    :
                     '';
                })
            :
                <Grid item xs={6}>
                    <Typography
                        variant="caption"
                        color="error"
                    >
                        Prices not found
                    </Typography>
                </Grid>
            }   
                {}
            </Grid>
        )
    }

}

export default withStyles(styles)(ResultsScale)