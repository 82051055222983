import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import ResultsScale from './Results.scale.component.jsx';
import ResultsEmpty from './Results.empty.component.jsx';
import ResultsActions from './Results.actions.component.jsx';
import ResultsRowName from './Results.row.name.component.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    table: {
        margin: 0,
        width: '100%'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'right'
    },
    thumbnail: {
        width: 54,
        height: 48,
        objectFit: 'cover',
        borderRadius: 8,
        backgroundColor: '#F5F5F5'
    },
    order: {
        marginRight: theme.spacing(1)
    },
    download: {
        marginLeft: theme.spacing(1), 
        padding: theme.spacing(.5)
    },
    placeholderTitle: {
        backgroundColor: '#9B9B9B',
        borderRadius: 5,
        width: 70,
        height: 10,
        marginLeft: theme.spacing(2)
    },
    progress: {
        color: 'rgba(70, 64, 61, 0.25)'
    }
})

class ResultsRow extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showHover: false,
            showScale: false
        }
    }

    render () {
        const {
            showHover,
            showScale
        } = this.state;
        const {
            t,
            classes,
            item,
            quantity,
            prices,
            material,
            onCreateLink,
            onHandleQuantity,
            onTrackDownload
        } = this.props;

        return (
            <Grid
                container
                spacing={4}
                className={classes.table}
                onMouseEnter={() => {this.setState({showScale: true})}}
                onMouseLeave={() => {this.setState({showScale: false})}}
            >
                {typeof item === "number" ? (
                    <React.Fragment>
                        <Grid item xs={1}>
                            <div className={classes.thumbnail}></div>
                        </Grid>
                        <Grid item xs={5} style={{ alignSelf: 'center' }}>
                            <div className={classes.placeholderTitle}></div>
                        </Grid>
                        <Grid
                            item xs={6}
                            className={classes.actions}
                        >
                            <CircularProgress size={24} classes={{ svg: classes.progress }} />
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item xs={1}>
                            <img src={item.thumb} className={classes.thumbnail} />
                        </Grid>
                        <Grid item xs={5}>
                            {material.output.print && showScale ?
                                <ResultsScale
                                    quantity={quantity}
                                    prices={prices}
                                />
                            :
                                <ResultsRowName
                                    name={item.fileName ? item.fileName : item.name}
                                    cat={item.cat}
                                />
                            }
                        </Grid>
                        <Grid
                            item xs={6}
                            className={classes.actions}
                        >
                            {material.output.print &&
                                <React.Fragment>
                                    {prices && prices.quantities && (
                                        <React.Fragment>
                                        {quantity === 0 ?
                                            <ResultsEmpty
                                                onHandleQuantity={onHandleQuantity}
                                                price={prices.quantities[prices.quantityValues[0]]}
                                                showHover={showHover}
                                                item={item}
                                            />
                                        :
                                            <ResultsActions
                                                onHandleQuantity={onHandleQuantity}
                                                price={prices.quantities[quantity]}
                                                quantity={quantity}
                                                item={item}
                                                showPrice={true}
                                            />
                                        }
                                        </React.Fragment>
                                    )
                                    }
                                </React.Fragment>
                            }
                            <Tooltip
                                title={t('common.buttons.download')}
                            >
                                <IconButton
                                    disabled={!material.output.download}
                                    target="_blank"
                                    className={classes.download}
                                    onClick={() => onTrackDownload(item.fileName, item.url, item.cat)}
                                    href={onCreateLink(item.fileName, item.url, material.output.type)}
                                >
                                    <GetAppIcon
                                    fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(ResultsRow));