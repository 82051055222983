import React from 'react';
import { withTranslation } from 'react-i18next';
import Paper from '../../../components/Paper.component';
import Button from '../../../components/Button.component';
import Link from '../../../components/Link.component';
import OnboardingIcon from './Onboarding.icon.component.jsx';
import OnboardingText from './Onboarding.text.component.jsx';
import OnboardingVideo from './Onboarding.video.component.jsx';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
    paper: {
        borderRadius: theme.spacing(5),
        width: '100%',
        minHeight: 300,
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.custom.green,
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    content: {
        minHeight: theme.spacing(15),
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`
    },
    stepper: {
        background: 'none',
    },
    dot: {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    dotActive: {
        backgroundColor: theme.palette.common.white,
    }
});

class OnboardingStepper extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            classes,
            t,
            activeStep,
            onboardingSteps,
            onStep,
            onSkip,
            onBack,
            onClose,
            overContent,
        } = this.props;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        const maxSteps = onboardingSteps.length;

        return (
            <React.Fragment>
                <Paper
                    padding
                    className={classes.paper}
                >
                    {onboardingSteps[activeStep].content ?
                        <React.Fragment>
                            <Avatar
                                className={classes.avatar}
                            >
                                <OnboardingIcon
                                    icon={`icon0${activeStep + 1}`}
                                />
                            </Avatar>
                            <OnboardingText
                                step={onboardingSteps[activeStep]}
                            />
                        </React.Fragment>
                    :
                        <OnboardingVideo
                            step={onboardingSteps[activeStep]}
                        />
                    }
                    <Button
                        primary
                        onClick={onStep}
                        className={classes.button}
                        size="large"
                    >
                        {activeStep === 0 ?
                            t('common.buttons.start')
                            :
                            t('common.buttons.next')
                        }
                    </Button>
                    <div>
                        {activeStep > 0 &&
                            <Link
                                // block
                                onClick={onBack}
                            >
                                {t('common.buttons.back')}
                            </Link>
                        }
                        {activeStep > 0 && (<React.Fragment>&nbsp;&nbsp;&nbsp;</React.Fragment>)}
                        {overContent ?
                            <Link
                                // block
                                onClick={() => onClose(false)}
                            >
                                {t('common.buttons.close')}
                            </Link>
                            :
                            <Link
                                // block
                                onClick={onSkip}
                            >
                                {t('common.buttons.skip')}
                            </Link>
                        }
                    </div>
                </Paper>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    classes={{
                        root: classes.stepper,
                        dot: classes.dot,
                        dotActive: classes.dotActive,
                    }}
                />
            </React.Fragment>
        )
    }

}

export default withTranslation()(withStyles(styles)(OnboardingStepper));
