import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

function LogoIcon(props) {
    const {
        colors = [
            props.theme.palette.custom.green,
            props.theme.palette.common.white,
        ],
        theme,
        className
    } = props;

    return (
        <ButtonBase
            centerRipple
            onClick={props.onClick}
            className={className}
        >
            <svg
                width="23"
                height="34"
                viewBox="0 0 23 34"
            >
                <g fill={colors[0]}>
                    <path d="M9.8 20.5c-5.4 0-9.7-4.4-9.7-9.7S4.4 1 9.8 1s9.7 4.4 9.7 9.7-4.3 9.8-9.7 9.8zm0-15c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3 5.3-2.4 5.3-5.3c0-3-2.4-5.3-5.3-5.3z"></path>
                    <path d="M19.2 7C17.4 7 16 5.6 16 3.8S17.4.6 19.2.6s3.2 1.4 3.2 3.2S21 7 19.2 7zm0-4.9c-1 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7c1 0 1.7-.8 1.7-1.7s-.8-1.7-1.7-1.7zM9.8 33.2c5.4 0 9.7-4.4 9.7-9.7H15c0 2.9-2.4 5.3-5.3 5.3s-5.3-2.4-5.3-5.3H.1c0 5.3 4.3 9.7 9.7 9.7z"></path>
                </g>
            </svg>
        </ButtonBase>
    )
}

LogoIcon.propTypes = {
    colors: PropTypes.array,
};

export default withTheme(LogoIcon);
