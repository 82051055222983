import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../helpers';
import ProductsRow from './Products.row.component.jsx';
import Paper from '../../../../components/Paper.component';
import CheckBox from '../../../../components/CheckBox.component';
import SearchBar from '../../../../components/SearchBar.component';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { alpha } from '@material-ui/core/styles';

const styles = theme => ({
    productsContainer: {
        paddingBottom: 100
    },
    paper: {
        marginBottom: theme.spacing(2),
        textAlign: 'left'
    },
    filters: {
        position: 'sticky',
        top: theme.spacing(12) 
    },
    filterLabel: {
        fontSize: theme.spacing(1.8)
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        // overflowX: 'hidden',
        // overflowY: 'scroll',
    },
    selection: {
        position: 'absolute',
        minWidth: theme.spacing(48),
        maxWidth: theme.spacing(48),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        bottom: theme.spacing(10.5),
        right: theme.spacing(3),
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        borderRadius: 0,
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    selectionHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: '12px 16px',
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectionTitle: {
        paddingLeft: theme.spacing(1), 
        fontSize: 15
    },
    selectionArticlesLabel: {
        width: theme.spacing(3.5) ,
        height: theme.spacing(3.5) ,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center',
        fontSize: theme.spacing(1.6),
        lineHeight: 2.1,
        borderRadius: '50%',
    },
    selectionItems: {
        padding: '24px 24px 8px 12px',
        alignItems: 'center',
        overflowY: 'auto'
    },
    selectionItem: {
        marginBottom: theme.spacing(2)
    },
    selectionItemText: {
        fontWeight: 'normal',
    },
    selectionIcon: {
        backgroundColor: alpha(theme.palette.common.black, .2),
        color: theme.palette.common.black,
        borderRadius: '50%',
        width: theme.spacing(3.5) ,
        height: theme.spacing(3.5) ,
    }
})

class ProductsEdit extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        const { t } = props;
        return {
            ...props,
            categories: [{ keys: ['image', 'png'], label: t('products.filters.image') }, { keys: ['eml', 'html'], label: t('products.filters.mail') }, { keys: ['pdf'], label: t('products.filters.poster') }, { keys: ['download', 'mp4'], label: t('products.filters.video') }],
            products: campaign.material,
            campaignLanguage: campaign.current.data.language,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            displayedProducts: props.products,
            productsObject: Core.arrayToObject(props.products, 'key'),
            selectedCats: [],
            searchText: '',
            selectionOpen: false
        }
    }

    searchHandler = () => {
        const { products } = this.props;
        const { selectedCats, searchText } = this.state;
        let resultCats = products;
        if (selectedCats.length > 0) {
            resultCats = products.filter((item) => {
                return selectedCats.indexOf(item.output.type) !== -1;
            });
        }
        let result = resultCats;
        if (searchText.length > 0) {
            result = resultCats.filter((item) => {
                let searchValue = this.props.t(`material.${item.key}.title`);
                return searchValue.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            });
        }
        this.setState({
            displayedProducts: result
        });
    }

    textHandler = (e) => {
        this.setState({
            searchText: e.target.value.toLowerCase(),
        }, () => this.searchHandler())
    }

    categoryHandler = (e) => {
        const { selectedCats } = this.state;
        const cat = e.target.value;
        const keys = cat.split('-');

        keys.forEach(cat => {
            if (selectedCats.indexOf(cat) > -1) {
                selectedCats.splice(selectedCats.indexOf(cat), 1);
            } else {
                selectedCats.push(cat);
            }
        });

        this.setState({
            selectedCats: selectedCats,
        }, () => this.searchHandler())
    }
    
    deleteCheckItem = (key) => {
        const { onHandleChange } = this.props;
        const productToDelete = this.props.products.find(product => product.key === key);
        if (productToDelete) {
            onHandleChange(false, productToDelete);
        }
    }

    renderResults() {
        const { t, view, selectedProducts, products, classes, onHandleChange, checkArray } = this.props;
        const { displayedProducts } = this.state;

        if (view === "presets" && Object.keys(selectedProducts).length > 0) {
            return (
                <Paper
                    background='light'
                    bordered
                    noShadow
                    className={classes.paper}
                >
                    <List className={classes.list}>
                        {Object.keys(selectedProducts).map((product, key) => (
                            <ProductsRow
                                key={product}
                                view={view}
                                checked={checkArray.indexOf(String(product)) >= 0}
                                product={products.find(item => item.key === product)}
                                onHandleChange={onHandleChange}
                                noBorder={key + 1 === displayedProducts.length}
                            />
                        ))}
                    </List>
                </Paper>
            )
        } else if (view === "presets" && selectedProducts.length === 0) {
            return <Typography>{t('trainings.edit.nothingFound')}</Typography>;
        } else if (view === "edit" && displayedProducts.length > 0) {
            return (
                <Paper
                    background='light'
                    bordered
                    noShadow
                    className={classes.paper}
                >
                    <List className={classes.list}>
                        {displayedProducts.map((product, key) => (
                            <ProductsRow
                                key={product.key}
                                view={view}
                                checked={checkArray.indexOf(String(product.key)) >= 0}
                                product={product}
                                onHandleChange={onHandleChange}
                                noBorder={key + 1 === displayedProducts.length}
                            />
                        ))}
                    </List>
                </Paper>
            )
        } else {
            return <Typography>{t('trainings.edit.nothingFound')}</Typography>;
        }
    }
 
    render() {
        const {
            classes,
            t,
            checkArray,
            onHandleChange,
            categories,
            view
        } = this.props;
        const {
            displayedProducts,
            productsObject,
            selectedCats,
        } = this.state;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        return (
            <Grid container spacing={2} className={classes.productsContainer}>
                {view !== "presets" && (
                    <Grid item xs={4}>
                        <Paper
                            padding
                            className={classNames(classes.paper, classes.filters)}
                        >
                            <Typography variant="subtitle2" paragraph gutterBottom>
                                {t('trainings.edit.filter')}
                            </Typography>
                            <FormGroup>
                                {categories.map(cat => {
                                    const checked = selectedCats.some(selectedCat => cat.keys.includes(selectedCat));
                                    return (
                                        <React.Fragment key={cat.keys.join('-')}>
                                            <FormControlLabel
                                                classes={{ label: classes.filterLabel }}
                                                control={
                                                    <CheckBox
                                                        value={String(cat.keys.join('-'))}
                                                        checked={checked}
                                                        onChange={this.categoryHandler}
                                                    />
                                                }
                                                label={cat.label}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </FormGroup>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={view === 'edit' ? 8 : 12}>
                    {view !== "presets" && (
                        <SearchBar
                            onSearchHandler={this.textHandler}
                            placeholder={t('products.edit.searchBar')}
                        />
                    )}
                    {this.renderResults()}
                </Grid>
                {view === 'edit' && checkArray.length > 0 &&
                    <Paper
                        // noBorder
                        className={classes.selection}
                        style={{ maxHeight: this.state.selectionOpen ? '70vh' : 52 }}
                    >
                        <Grid container className={classes.selectionHeader} onClick={() => this.setState({ selectionOpen: !this.state.selectionOpen })}>
                            <Grid item xs={1}>
                                <IconButton style={{ padding: 0 }}>
                                    {this.state.selectionOpen ? (
                                        <ExpandMoreIcon
                                            classes={{root: classes.selectionIcon}}
                                        />
                                    ) : (
                                        <ExpandLessIcon
                                            classes={{root: classes.selectionIcon}}
                                        />
                                    )}
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="h2" color="inherit" className={classes.selectionTitle}>
                                    {t('products.edit.selected')}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="body1" color="inherit" className={classes.selectionArticlesLabel}>
                                    {checkArray.length}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.selectionItems} style={{ paddingBottom: checkArray.length > 12 ? '80px' : '8px', maxHeight: this.state.selectionOpen ? '70vh' : 52 }}>
                            {checkArray.map(key => (
                                <React.Fragment key={key}>
                                    <Grid item xs={11} >
                                        <Typography variant="h6" className={classes.selectionItemText} noWrap>{productsObject[key] && t(`material.${productsObject[key].key}.title`)}</Typography>
                                    </Grid>
                                    <Grid item xs={1} style={{ textAlign: 'left' }}>
                                        <IconButton onClick={() => this.deleteCheckItem(key)} style={{ padding: 8 }}>
                                            <CloseIcon className={classes.closeIcon} fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>

                    </Paper>
                }
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(ProductsEdit.mapStateToProps, {})(ProductsEdit)
));