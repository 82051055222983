import { Core } from '../';
import store from '../../store';

class Import {
    /**
     *
     * @param {array}
     * @param {object}
     * @return {object}
     */
    static getPresets(presetsSource, material) {
        const productPresets = {};
        presetsSource.forEach( (pres, index) => {
            if (pres.AssetList && pres.AssetList.length && pres.AssetList[0].Id > 0) {
                const campaignLang = pres.LanguageCode;
                const rawPresets = pres.AssetList;
                productPresets[campaignLang] = {};

                material.forEach( (mat, index) => {
                    if (mat.key === 'postcard') {
                        const rawOptions = rawPresets.find(p => {
                            return p.CategoryId === 102;
                        })
                        const options = [];
                        Array.isArray(rawOptions.Value) && rawOptions.Value.forEach( (option, index ) => {
                            options.push({
                                key: `${index}`,
                                display: `${option.TitlePart3} ${option.TitlePart4}`,
                                payLoad: option,
                            })
                        })
                        productPresets[campaignLang].postcard = options;
                    }
                    if (mat.key === 'tablecard') {
                        const rawOptions = rawPresets.find(p => {
                            return p.CategoryId === 103;
                        })
                        const options = [];
                        Array.isArray(rawOptions.Value) && rawOptions.Value.forEach( (option, index ) => {
                            options.push({
                                key: `${index}`,
                                display: `${option.TitlePart1} ${option.TitlePart2}`,
                                payLoad: option,
                            })
                        })
                        productPresets[campaignLang].tablecard = options;
                    }
                    if (mat.key === 'placemat') {
                        const title = rawPresets.find(p => {
                            return p.CategoryId === 104;
                        })?.Value;
                        const subTitle = rawPresets.find(p => {
                            return p.CategoryId === 105;
                        })?.Value;
                        productPresets[campaignLang].placemat = {
                            title: title,
                            subtitle: subTitle,
                        };
                    }
                    if (mat.key === 'overviewposter') {
                        const title = rawPresets.find(p => {
                            return p.CategoryId === 107;
                        })?.Value;
                        productPresets[campaignLang].overviewposter = {
                            title: title,
                        };
                    }
                });

                // Create all trainings url
                productPresets[campaignLang].trainingsurl = rawPresets.find(p => {
                    return p.CategoryId === 108;
                }).Value,

                // Create payoff
                // Normalize texts to make sure special characters render as should in video's
                productPresets[campaignLang].payoff = {
                    part_1: rawPresets.find(p => {
                        return p.CategoryId === 109;
                    }).Value.normalize('NFC'),
                    part_2:  rawPresets.find(p => {
                        return p.CategoryId === 110;
                    }).Value.normalize('NFC')
                }

                // Create reference
                productPresets[campaignLang].reference = rawPresets.find(p => {
                    return p.CategoryId === 101;
                })?.Value;
            }
        })
        
        return productPresets
    }
    /**
     *
     * @param {array}
     * @return {array}
     */
    static getCourseCats(courses) {
        const cats = [];
        courses.forEach((c) => {
            const existingIndex = cats.findIndex((cat) => cat.id === c.CourseCategoryId);
            if (existingIndex < 0) {
                cats.push({
                    id: c.CourseCategoryId,
                    label: JSON.parse(c.CourseCategoryName || c.CategoryName),
                    languages: [c.Language],
                })
            }
            else {
                const languages = cats[existingIndex].languages;
                if (languages.indexOf(c.Language) < 0) {
                    languages.push(c.Language);
                    cats[existingIndex] = {
                        ...cats[existingIndex],
                        languages: languages
                    }
                }
            }
        })
        return cats;
    }
    /**
     *
     * @param {array} company
     * @return {object}
     */
    static getCompany(company) {
        const newCompany = {
            lms: company.AssetList.find( (asset) => {
                return asset.CategoryId === 204
            }).Value,
            logo: company.AssetList.find( (asset) => {
                return asset.CategoryId === 201
            }).Value,
        }
        return newCompany;
    }
    
    /**
     * Update trainings saved in a campaign with current traiingsdata.
     * @param {object} trainings as saved in the campaign
     * @returns 
     */

    static actualizeTrainings(trainings) {

        const { campaign } = store.getState();
        const courses = campaign.assets.CourseList;
        const courseObj = Core.arrayToObject(courses, 'CourseId');

        let newTrainings = { ...trainings };
        Object.keys(newTrainings).forEach((key) => {
            if (courseObj[key]) {
                newTrainings = {
                    ...newTrainings,
                    [key]: courseObj[key]
                }
                
            }
        })

        return newTrainings;
    }
}

export default Import;
