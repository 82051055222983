import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiTextfield from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

const styles = theme => ({
    wrapper: {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }
    },
    focused: {
        backgroundColor: theme.palette.grey[200],
    },
    input: {
        fontSize: '0.9375rem',
        padding: 15,
        lineHeight: '26px',
        height: 26,
    },
    multilineRoot: {
        paddingTop: 15,
        paddingBottom: 15,
    },
    inputMultiline: {
        padding: 0,
        lineHeight: '19px',
        marginTop: 2,
        marginBottom: 5,
    },
    helperText: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize: theme.spacing(1.5)
    }
});

function Textfield(props) {
    const {
        classes,
        className,
        value,
        readOnly,
        onChange,
        endAdornment,
        ...other
    } = props;

    return (
        <MuiTextfield
            value={value ? value : ''}
            onChange={onChange}
            variant="filled"
            fullWidth
            className={classNames(classes.root, className)}
            FormHelperTextProps={{
                className: classes.helperText
            }}
            InputProps={{
                disableUnderline: true,
                readOnly: readOnly,
                classes: {
                    root: classes.wrapper,
                    input: classes.input,
                    focused: classes.focused,
                    multiline: classes.multilineRoot,
                    inputMultiline: classes.inputMultiline
                },
                endAdornment: (
                    <InputAdornment
                        position="end"
                        component="span"
                        disablePointerEvents
                    >
                        <span style={{ color: '#868686' }}>{endAdornment}</span>
                    </InputAdornment>
                )
            }}
            {...other}
        />
    )
}

export default withStyles(styles)(Textfield)