import React from 'react';
import CampaignAction from '../../../reducers/Campaign/campaign.actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LayoutBody from '../../../components/LayoutBody.component';
import FixScrollbar from '../../../components/FixScrollbar.component';
import lanes from '../../../data/lanes.json';
import { withStyles } from '@material-ui/core/styles';
import StepHeader from '../../../components/StepHeader/components/StepHeader.component';
import CampaignHeader from '../../../components/CampaignHeader.component';
import CampaignFooter from '../../../components/CampaignFooter.component';
import ThemesSelect from './components/Theme.select.component.jsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '../../../components/Dialog.component';
import store from '../../../store';
import { push } from 'connected-react-router';

const styles = theme => ({
    themesContainer: {
        marginTop: -24,
        paddingBottom: 100
    },
    button: {
        marginLeft: theme.spacing(1), 
        margnRight: theme.spacing(1), 
    }
});

class Theme extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            templates: campaign.assets.templates,
            lane: campaign.lane.id,
            steps: campaign.lane.steps,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            closeCampaign: false,
            itemToEdit: {},
            lanes: lanes.lanes
        }
    }

    getTheme = async (theme) => {
        // const newLane = lanes[1];
        await this.props.getTemplate(theme);
        if (theme !== null) {
            this.props.onSelectStep(false, 1, {}, true);
        }
    }

    goHome = () => {
        this.props.clear();
        store.dispatch(push('/start'));
    }

    render() {
        const {
            classes,
            t,
            steps,
            activeStep,
            getCurrentStep,
            onSelectStep,
            onClickStep,
            templates,
        } = this.props;
        const {
            itemToEdit
        } = this.state;
        const stepIsCompleted = false;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);
    
        return (
            <React.Fragment>
                <FixScrollbar />
                <StepHeader
                    itemToEdit={itemToEdit}
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={onClickStep}
                    stepIsCompleted={stepIsCompleted}
                    lane={this.props.lane}
                    helpText={t('common.header.button')}
                />
                <CampaignHeader
                    title={t('theme.title')}
                    intro={t('theme.intro')}
                />
                <LayoutBody
                    component="section"
                    width="mediumSmall"
                    textAlignCenter
                    paddingBottom
                    className={classes.themesContainer}
                >
                    <ThemesSelect
                        templates={templates}
                        getTheme={this.getTheme}
                    />
                </LayoutBody>
                {/*<LayoutBody
                    component="section"
                    width="medium"
                    textAlignCenter
                    paddingTop
                    paddingBottom
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectTheme
                                onSwitchLane={onSwitchLane}
                                onSelectTheme={this.selectTheme}
                                selectedTheme={selectedTheme}
                                templates={templates}
                            />
                        </Grid>
                    </Grid>
                </LayoutBody>
                */}
                <CampaignFooter
                    onSelectPreviousStep={() => this.setState({ closeCampaign: true })}
                    onSelectNextStep={() => onSelectStep(false, 1, itemToEdit, stepIsCompleted)}
                    previousStepIcon={<ArrowBackIcon fontSize="small" />}
                    nextStepIcon={<ArrowForwardIcon fontSize="small" />}
                    nextStepDisabled={!stepIsCompleted}
                    getCurrentStep={getCurrentStep}
                    hideNextButton
                />
                <Dialog
                    open={this.state.closeCampaign}
                    title={t('start.archive.exit.title')}
                    text={t('start.archive.exit.description')}
                    onCancel={() => this.setState({ closeCampaign: false })}
                    cancelText={t('common.buttons.cancel')}
                    onConfirm={() => this.goHome()}
                    confirmText={t('common.buttons.overview')}
                    type="close"
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(withStyles(styles)(
    connect(Theme.mapStateToProps, {
        getTemplate: CampaignAction.getTemplate,
        clear: CampaignAction.clear
    })(Theme)
));
