import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ResultsIcon from '@material-ui/icons/SaveAlt';
import CustomizeIcon from '@material-ui/icons/Visibility';
import TrainingsIcon from '@material-ui/icons/FormatListBulleted';
import LanguageIcon from '@material-ui/icons/Language';
import DoneIcon from '@material-ui/icons/Done';
import ThemeIcon from '@material-ui/icons/BookmarkBorder';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({
    root: {
        width: theme.spacing(3.5) ,
        height: theme.spacing(3.5) ,
        backgroundColor: '#E1E1E1',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(-0.6),
        zIndex: 1,
        opacity: 1,
        transition: theme.transitions.create('background-color')
    },
    active: {
        marginTop: 0,
        marginBottom: theme.spacing(-0.6),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main
    },
    done: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }
})

function StepIcon(props) {
    const {
        icon,
        active,
        done,
        classes,
        className,
    } = props;

    let component = null;
    const size = 16;

    switch (icon) {
        case 'camp':
            component = <DoneIcon style={{ width: size, height: size, }} />;
            break;
        case 'cust':
            component = <CustomizeIcon style={{ width: size, height: size, }} />;
            break;
        case 'resu':
            component = <ResultsIcon style={{ width: size, height: size, }} />;
            break;
        case 'trai':
            component = <TrainingsIcon style={{ width: size, height: size, }} />;
            break;
        case 'prod':
            component = <SvgIcon
                            viewBox="-34 0 512 512"
                            style={{ width: size, height: size, }}
                        >
                            <path d="m221.703125 0-221.703125 128v256l221.703125 128 221.703125-128v-256zm176.515625 136.652344-176.515625 101.914062-176.515625-101.914062 176.515625-101.910156zm-368.132812 26.027344 176.574218 101.941406v203.953125l-176.574218-101.945313zm206.660156 305.894531v-203.953125l176.574218-101.941406v203.949218zm0 0" />
                        </SvgIcon>;
            break;
        case 'titl':
            component = <LanguageIcon style={{ width: size, height: size, }} />;
            break;
        case 'them':
            component = <ThemeIcon style={{ width: size, height: size, }} />
            break;
        default:
            component = 'x';
    }

    return (
        <Avatar
            className={classNames(
                classes.root,
                {
                    [classes.done]: done && !active,
                    [classes.active]: active,
                },
                className,
            )}
            classes={{
                root: classes.icon
            }}
        >
            {component}
        </Avatar>
    )
}

export default withStyles(styles)(StepIcon);