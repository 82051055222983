import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(1), 
        marginBottom: -1,
        minHeight: theme.spacing(8),
        transition: theme.transitions.create('background-color, border-radius'),
        '&$expanded': {
            minHeight:  theme.spacing(8),
        },
        '&:hover': {
            backgroundColor:  theme.palette.secondary.light
        },
    },
    title: {
        color: theme.palette.common.black
    },
    error: {
        backgroundColor: theme.palette.error.main,
        borderRadius: theme.spacing(1), 
        marginBottom: -1,
        minHeight: theme.spacing(8),
        transition: theme.transitions.create('background-color, border-radius'),
        '&$expanded': {
            minHeight:  theme.spacing(8),
        },
        '&:hover': {
            backgroundColor:  theme.palette.error.dark
        },
    },
    content: {
        color: theme.palette.common.white,
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    expandIcon: {
        color: theme.palette.common.white,
    },
    expandIconRoot: {
        backgroundColor: alpha(theme.palette.common.black, .2),
        color: theme.palette.common.black,
        borderRadius: '50%',
    }
})

class ExpansionPanelSummary extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
      const {
          children,
          classes,
          error,
          expanded,
          onChange,
          ...other
      } = this.props;
    //   console.log('EH props', this.props);

      return (
        <AccordionSummary
            // expanded={expanded}
            onChange={onChange}
            expandIcon={
                <ExpandMoreIcon
                    classes={{root: classes.expandIconRoot}}
                />
            }
            classes={{
                root: classNames(error ? classes.error : classes.root),
                focusVisible: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                expandIcon: classes.expandIcon,
            }}
            {...other}
        >
            <Typography
                color="inherit"
                variant="h3"
                className={classes.title}
            >
                {children}
            </Typography>
        </AccordionSummary>
      );
    }
  }
  
  export default withStyles(styles)(ExpansionPanelSummary);
