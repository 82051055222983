import React from 'react';
import Icon01 from '../media/onboard_01.svg';
import Icon02 from '../media/onboard_02.svg';
import Icon03 from '../media/onboard_03.svg';
import Icon04 from '../media/onboard_04.svg';

function OnboardingIcon(props) {
    const { icon, className } = props;

    const icons = {
        icon01: Icon01,
        icon02: Icon02,
        icon03: Icon03,
        icon04: Icon04,
    }

    return (
        <img
            src={icons[icon]}
            className={className}
        />
    )
}

export default OnboardingIcon;
