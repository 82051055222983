import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from './ToolBar.component';
import Exit from './Exit.component';
import Help from '../../Help.component';

export const styles = theme => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    },
    toolbar: {
        justifyContent: 'space-between'
    },
    helpButton: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white
    }
});

function AppBar(props) {
    const {
        classes,
        showHelp,
        backText,
        helpText,
        loading = false
    } = props;

    return (
        <MuiAppBar
            elevation={0}
            position="static"
            color="primary"
            classes={{root: classes.root}}
        >
            <Toolbar
                className={classes.toolbar}
            >
                <Exit backText={backText} loading={loading} />
                {!loading && showHelp &&
                    <Help classes={{ root: classes.helpButton }} helpText={helpText} />
                }
            </Toolbar>
        </MuiAppBar>
    )
};

export default withStyles(styles)(AppBar);