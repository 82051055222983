import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../../../components/Paper.component';
import List from '@material-ui/core/List';
import { Link, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import ThumbNail from '../../../../components/ThumbNail.component';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    paper: {},
    icon: {
        transition: theme.transitions.create('background-color'),
    },
    iconSelected: {
        backgroundColor: theme.palette.common.white,
    },
    title: {
        fontFamily: '"Circular", Arial, Helvetica, sans-serif',
        fontWeight: 'bold',
        color: theme.palette.common.black,
    },
    titleSelected: {
        color: theme.palette.common.white,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
    },



    row: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
        minHeight: theme.spacing(10),
        transition: theme.transitions.create('background-color'),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.grey[200],
        }
    },
    noBorder: {
        borderBottom: 'none',
    },
    cat: {
        marginRight: theme.spacing(1), 
    },
    checked: {
        backgroundColor: theme.palette.grey[100],
    },
    avatar: {
        marginLeft: theme.spacing(2),
        width: 48,
        height: 48
    },
    icon: {
        position: 'absolute',
        top: 0,
        left: 0,
        maxWidth: '90%',
        marginLeft: '5%',
        marginTop: '5%'
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    preview: {
        textAlign: 'right',
        // transform: 'translateY(3px)'
    },
    arrow: {
        color: '#46403D',
        fontSize: theme.spacing(1.5),
        textAlign: 'right',
        cursor: 'pointer',
    }
});

function SelectTheme(props) {
    const {
        classes,
        t,
        templates,
        getTheme
    } = props;

    const themes = [
        {
            id: '100',
            title: 'Introductiecampagne',
        },
        {
            id: '101',
            title: 'Eindejaarscampagne',
        },
        {
            id: '102',
            title: 'Vitaliteit',
        },
    ]

    return (
        <Paper className={classes.root}>
            <Paper
                background='light'
                bordered
                noShadow
                className={classes.paper}
            >
                <List className={classes.list}>
                    {templates.map((template, key) => (
                        <ListItem className={classes.row} onClick={() => getTheme(template.id)} key={template.id}>
                            <ListItemAvatar className={classes.avatar}>
                                <ThumbNail
                                    bordered
                                    image={template.thumbnail}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                classes={{ primary: classes.title }}
                                primary={template.title}
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                secondary={template.description || template.type}
                                secondaryTypographyProps={{
                                    variant: 'body2'
                                }}
                            />
                            <ListItemText
                                classes={{ primary: classes.preview }}
                                primary={<ArrowForwardIcon />}
                                primaryTypographyProps={{
                                    variant: 'h6'
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Paper>
    );
}

export default withStyles(styles)(SelectTheme);