import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import CampaignAction from '../../reducers/Campaign/campaign.actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import store from '../../store';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Body from '../../components/Body.component';
import Loader from '../../components/Loader.component';
import Theme from './Theme/Theme.component.jsx';
import Langtitle from './Langtitle/Langtitle.component.jsx';
import Trainings from './Trainings/Trainings.component.jsx';
import Products from './Products/Products.component.jsx';
import Customize from './Customize/Customize.component.jsx';
import Results from './Results/Results.component.jsx';
import lanes from '../../data/lanes.json';
import { Core } from '../../helpers';

class StepsController extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            loading: campaign.loading,
            current: campaign.current,
            material: campaign.material,
            lane: campaign.lane,
            isAdmin: campaign.user && campaign.user.roles && campaign.user.roles.includes('admin')
        };
    }

    constructor(props) {
        super(props);

        const { loading } = props;
        if (loading === undefined) {
            store.dispatch(push('/'));
        }

        let activeStep = 1;

        if (props.lane && props.lane.id === 'campaign' && props.lane.steps && props.lane.steps[1].complete) {
            activeStep = 2;
        }

        // Set the activeStep when arriving with a jump paramater.
        const jumpStep = Core.getParam('jump');
        if (!!jumpStep) {
            window.history.pushState(null, "", location.href.split("?")[0]);
            activeStep = parseInt(jumpStep);
        };

        this.state = {
            lanes: lanes.lanes,
            activeStep,
            lastStep: 1,
            checkOutOpen: false
        };
    }

    getNextStep = (activeStep, dir, stepsLength) => {

        if (dir === undefined) {
            // No direction defined, go to onboarding.
            return -1;
        }
        else if (activeStep + dir < 0) {
            // There's no step before null.
            return 0;
        }
        else if (activeStep + dir > stepsLength - 1) {
            //Do not go past the last step.
            return stepsLength - 1
        }
        else {
            // Take a step.
            return activeStep + dir
        }
    }

    getNextPath = (nextStep, steps) => {
        const { t } = this.props;
        if (nextStep === -1) {
            // Go to the onbarding screen.
            return '/';
        }   
        else if (nextStep === 0) {
            // Go to the start screen.
            return t(`steps.${steps[nextStep].id}.path`);
        }
        else {
            // Go to the specified step.
            return `/form${t(`steps.${steps[nextStep].id}.path`)}`
        }
    }

    selectStep = (e, dir, changes, complete) => {
        const { activeStep } = this.state;
        const { current, lane, isAdmin } = this.props;
        const steps = lane.steps;
        const nextStep = this.getNextStep(activeStep, dir, steps.length);
        const nextPath = this.getNextPath(nextStep, steps);
        const newChanges = JSON.parse(JSON.stringify(changes))

        let newTitle = current.title
        if (newChanges && 'title' in newChanges) {
            if (newChanges.title.length === 0) {
                newTitle = 'Campaign / ' + moment().format('YYYY-MM-DD / HH:mm');
            }
            else {
                newTitle = newChanges.title
            }
            delete newChanges.title;
        }

        const newLane = {
            ...lane,
            steps: Object.assign(
                [...lane.steps],
                {
                    [activeStep]:
                        Object.assign({}, lane.steps[activeStep], { complete: complete })
                }
            )
        }

        const newCurrent = {
            ...current,
            title: newTitle,
            type: newLane.id,
            thumbnail: "",
            data: {
                ...current.data,
                ...newChanges
            },
        }

        const language = current.data && current.data.language;
        if (language) {
            newCurrent.language = language;
        }

        this.props.setLane(newLane).then(
            this.props.update(newCurrent).then(
                this.setState(
                    {
                        lastStep: activeStep,
                        activeStep: nextStep,
                    },
                    store.dispatch(push(nextPath))
                )
            )
        )
    }

    getCurrentStep = () => {
        const { activeStep } = this.state;
        const { lane, current, material, isAdmin } = this.props;
        const steps = lane.steps;

        if (steps[activeStep].id !== "prod") {
            return steps[activeStep].id;
        } else {
            // See how if trainings are needed based on selected products.
            const products = current.data.products;
            const materialDef = Core.arrayToObject(material, 'key');
            const productsReadOnly = current.productsReadOnly === '1' ? true : false;
            let required = 0
            if (products) {
                Object.keys(products).forEach(product => {
                    if (materialDef[product] && materialDef[product].customizeFields && materialDef[product].customizeFields.length > 0) {
                        materialDef[product].customizeFields.forEach(field =>{
                            if (field.type.split('-')[0] === 'trainings') {
                                required = 1;
                            }
                            else if (field.type.split('-')[0] === 'trainingsvariant') {
                                required = 1;
                            }
                        })
                    }
                })
            }
            
            // Go to next step if lane is products and no trainings are required.
            // Or go to next step it's a fixed campaign and you're not an admin.
            if ((required === 0 && current.type === 'products') || (required === 0 && current.type === 'campaign') ) {
                // Next step is personalise.
                return steps[activeStep + 1].id;
            }

            return steps[activeStep].id;
        }
    }

    clickStep = (e, target, changes, complete) => {
        const { activeStep } = this.state;
        const dir = target - activeStep;
        this.selectStep(e, dir, changes, complete)
    }

    handleCheckOut = () => {
        const {
            checkOutOpen
        } = this.state;
        this.setState({ checkOutOpen: !checkOutOpen })
    }

    render() {
        const {
            activeStep,
            lastStep,
            selectedTheme,
            checkOutOpen
        } = this.state;
        const {
            t,
            loading,
            history
        } = this.props;
        // console.log('Steps state', this.state);
        // console.log('Steps props', this.props);

        return (
            <React.Fragment>
                <Body>
                    <Loader isLoading={loading}>
                        <Router history={history}>
                            <Switch>
                                <Route exact path={`/form${t(`steps.them.path`)}`}>
                                    <Theme
                                        activeStep={activeStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        onSelectTheme={this.selectTheme}
                                        selectedTheme={selectedTheme}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                                <Route exact path={`/form${t(`steps.titl.path`)}`}>
                                    <Langtitle
                                        activeStep={activeStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                                <Route exact path={`/form${t(`steps.trai.path`)}`}>
                                    <Trainings
                                        activeStep={activeStep}
                                        lastStep={lastStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                                <Route exact path={`/form${t(`steps.prod.path`)}`}>
                                    <Products
                                        activeStep={activeStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                                <Route exact path={`/form${t(`steps.cust.path`)}`}>
                                    <Customize
                                        activeStep={activeStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                                <Route exact path={`/form${t(`steps.resu.path`)}`}>
                                    <Results
                                        activeStep={activeStep}
                                        onSelectStep={this.selectStep}
                                        onClickStep={this.clickStep}
                                        checkOutOpen={checkOutOpen}
                                        onHandleCheckOut={this.handleCheckOut}
                                        getCurrentStep={this.getCurrentStep}
                                    />
                                </Route>
                            </Switch>
                        </Router>
                    </Loader>
                </Body>
            </React.Fragment>
        )
    }
}

export default withTranslation()(connect(StepsController.mapStateToProps, {
    update: CampaignAction.update,
    setLane: CampaignAction.setLane
})(StepsController));
