import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        height: `calc(100vh - 80px)`,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white
    }
})

class Body extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            children,
            classes,
            className,
            ...other
        } = this.props;

        return (
            <div className={classNames(className, classes.root)}>
                {children}
            </div>
        )
    }
}

export default withStyles(styles)(Body);