import React from 'react';
import PropTypes from 'prop-types';

// Component to make scrollbar visible for a given element (id) at all times.

class FixScrollBar extends React.Component {

    constructor(props) {
        super(props)
    }
    
    componentDidMount() {
        if (document.getElementById(this.props.id)) {
            document.getElementById(this.props.id).style.overflowY = 'scroll';
        }
    }
    
    componentWillUnmount() {
        if (document.getElementById(this.props.id)) {
            document.getElementById(this.props.id).style.overflowY = '';
        }
    }

    render() {
      return null
    }

}

FixScrollBar.propTypes = {
    id: PropTypes.string,
};

export default FixScrollBar;

