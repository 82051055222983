import React from 'react';
import confetti from 'canvas-confetti';
import { withTheme } from '@material-ui/core/styles';

class ConfettiCannon extends React.Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        this._colors = [
            this.props.theme.palette.custom.pink,
            this.props.theme.palette.custom.yellow,
            this.props.theme.palette.common.white,
        ];
        this.startLoop();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.stop === true) {
            this.stopLoop();
        }

    }
    
    componentWillUnmount() {
        this.stopLoop();
    }

    startLoop = () => {
        if( !this._frameId ) {
            this._frameId = window.requestAnimationFrame( this.loop );
        }
    }

    stopLoop = () => {
        window.cancelAnimationFrame( this._frameId );
    }

    loop = (props) => {
        confetti({
            particleCount: this._colors.length,
            angle: 60,
            spread: props.spread,
            startVelocity: props.startVelocity,
            origin: {
                x: 0
            },
            colors: this._colors
        });
        confetti({
            particleCount: this._colors.length,
            angle: 120,
            spread: props.spread,
            startVelocity: props.startVelocity,
            origin: {
                x: 1
            },
            colors: this._colors
        });

        if (!props.stop) {
            this._frameId = requestAnimationFrame(this.loop);
        }
    }

    render () {
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);
    
        return (
            null
        )
    
    }

}

ConfettiCannon.defaultProps = {
    spread: 55,
    startVelocity: 45,
    stop: false,
};

export default withTheme(ConfettiCannon);
