import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers, applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

// Project based reducers
import campaignReducer from './reducers/Campaign/campaign.reducer';
// import shopReducer from './reducers/Shop/shop.reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createBrowserHistory();
const middleware = routerMiddleware(history);

export default createStore(
    combineReducers({
        campaign: campaignReducer,
        router: connectRouter(history),
        // shop: shopReducer
    }),
    composeEnhancers(applyMiddleware(middleware, thunk))
);

export {history};
