import React from 'react';
import CustomizeTextField from '../components/CustomizeTextField.component';
import CustomizeTrainingsList from '../components/CustomizeTrainingsList.component';
import CustomizeTrainings from '../components/CustomizeTrainings.component';
import CustomizeColor from '../components/CustomizeColor.component';
import CustomizeRadio from '../components/CustomizeRadio.component';
import CustomizeSelect from '../components/CustomizeSelect.component';
import CustomizeMultiSelect from '../components/CustomizeMultiSelect.component';
import CustomizeTrainingsVariant from '../components/CustomizeTrainingsVariant.component';
import CustomizeTrainingsCustomUrl from '../components/CustomizeTrainingsCustomUrl.component';
import CustomizeImageUpload from '../components/CustomizeImageUpload.component';

class Field extends React.Component {

    static _element(props) {
        // console.log(props.field.type)
        switch (props.field.type) {
            case 'text-multiline': return <CustomizeTextField
                multiline={true}
                {...props}
            />;
            case 'text': return <CustomizeTextField {...props} />;
            case 'text-hidden': return <CustomizeTextField
                hidden={true}
                {...props}
            />;
            case 'trainingslist-cats': return <CustomizeTrainingsList
                all={false}
                cats={true}
                {...props}
            />;
            case 'trainingslist-all': return <CustomizeTrainingsList
                all={true}
                cats={false}
                {...props}
            />;
            case 'trainingslist-all-cats': return <CustomizeTrainingsList
                all={true}
                cats={true}
                {...props}
            />;

            case 'trainingslist-all-cats-desc': return <CustomizeTrainingsList
                all={true}
                cats={true}
                {...props}
            />;
            case 'trainingslist': return <CustomizeTrainingsList
                all={false}
                cats={false}
                {...props}
            />;
            case 'trainings': return <CustomizeTrainings {...props} />;
            case 'trainings-hidden': return <CustomizeTrainings
                hidden={true}
                {...props}
            />;
            case 'trainings-custom-url': return <CustomizeTrainingsCustomUrl
                {...props}
            />;
            case 'color': return <CustomizeColor {...props} />;
            case 'radio': return <CustomizeRadio
                preset={false}
                {...props}
            />;
            case 'select': return <CustomizeSelect {...props} />
            case 'radio-postcard': return <CustomizeRadio
                preset='postcard'
                {...props}
            />;
            case 'radio-tablecard': return <CustomizeRadio
                preset='tablecard'
                {...props}
            />;
            case 'multiselect-postcard': return <CustomizeMultiSelect
                preset='postcard'
                {...props}
            />;
            case 'multiselect': return <CustomizeMultiSelect
                {...props}
            />;
            case 'trainingsvariant': return <CustomizeTrainingsVariant
                {...props}
            />;
            case 'image_upload': return <CustomizeImageUpload {...props} />
            case 'company':
            case 'get-value-on-export': return null;
            default: console.warn(`Field type '${props.field.type}' not found`)
                return null;
        }
    }

    render() {
        return Field._element(this.props);
    }

}

export default Field;