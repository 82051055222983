import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import StopIcon from '@material-ui/icons/Stop';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from 'classnames';

const styles = theme => ({
    icon: {
        borderRadius: theme.spacing(.5),
        borderStyle: 'solid',
        borderWidth: 2,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.grey[300],
        fill: theme.palette.common.white
    },
    checked: {
        fill: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.main
    },
    indeterminate: {
        backgroundColor: theme.palette.grey[200],
        fill: theme.palette.common.black
    }
});

function CheckBox(props) {
    const {
        classes,
        checked,
        value,
        onChange,
        indeterminate,
        ...other
    } = props;

    return (
        <MuiCheckbox
            checked={checked}
            value={value}
            indeterminate={indeterminate}

            icon={
                <StopIcon
                    classes={{
                        root: classes.icon
                    }} 
                />}
            checkedIcon={
                <CheckIcon
                    classes={{
                        root: classNames(classes.icon, classes.checked),
                    }} 
                />
            }
            indeterminateIcon={
                <RemoveIcon
                    classes={{
                        root: classNames(classes.icon, classes.indeterminate),
                    }} 
                />
            }
            onChange={onChange}
            {...other}
        />
    )

}

export default withStyles(styles)(CheckBox);
