import React from 'react';
import IconProducts from '../media/lane_product.svg';
import IconConcept from '../media/lane_concept.svg';

function StartIcon(props) {
    const { icon, className } = props;

    const icons = {
        products: IconProducts,
        campaign: IconConcept,
    }

    return (
        <img
            src={icons[icon]}
            className={className}
        />
    )
}

export default StartIcon;
