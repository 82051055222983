import React from "react";
import CampaignAction from "../../../reducers/Campaign/campaign.actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Core } from "../../../helpers";
import LayoutBody from "../../../components/LayoutBody.component";
import Paper from "../../../components/Paper.component";
import Select from "../../../components/Select.component";
import Textfield from "../../../components/Textfield.component";
import FixScrollbar from "../../../components/FixScrollbar.component";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import StepHeader from "../../../components/StepHeader";
import CampaignHeader from "../../../components/CampaignHeader.component";
import CampaignFooter from "../../../components/CampaignFooter.component";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Belgium from "../../../assets/images/countries/belgium.svg";
import English from "../../../assets/images/countries/english.svg";
import France from "../../../assets/images/countries/france.svg";
import Germany from "../../../assets/images/countries/germany.svg";
import Italy from "../../../assets/images/countries/italy.svg";
import Netherlands from "../../../assets/images/countries/netherlands.svg";
import Portugal from "../../../assets/images/countries/portugal.svg";
import Spain from "../../../assets/images/countries/spain.svg";
import Austria from "../../../assets/images/countries/austria.svg";
import Swiss from "../../../assets/images/countries/swiss.svg";
import Poland from "../../../assets/images/countries/poland.svg";
import Denmark from "../../../assets/images/countries/denmark.svg";
import Sweden from "../../../assets/images/countries/sweden.svg";
import Dialog from "../../../components/Dialog.component";
import store from "../../../store";
import { push } from "connected-react-router";

const styles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  up: {
    alignSelf: "flex-start",
    marginTop: theme.spacing(1.5),
  },
  button: {
    marginLeft: theme.spacing(1),
    margnRight: theme.spacing(1),
  },
  form: {
    marginTop: -24,
    paddingBottom: 100,
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  language: {
    width: 16,
    height: 16,
  },
});

class Langtitle extends React.Component {
  static mapStateToProps(state, props) {
    const { campaign } = state;
    return {
      ...props,
      campaign: campaign,
      steps: campaign.lane.steps,
      lane: campaign.lane.id,
      availableLanguages: campaign.current.availableLanguages,
      user: campaign.user,
    };
  }

  constructor(props) {
    super(props);

    let languageOptions = [
      "nl-nl",
      "nl-be",
      "en-gb",
      "fr-fr",
      "fr-be",
      "es-es",
      "de-de",
      "it-it",
      "pt-pt",
      "de-at",
      "de-ch",
    ];
    if (props.lane === "campaign" && props.availableLanguages) {
      languageOptions = props.availableLanguages
        .map((x) => x.language)
        .sort((a, b) => (a.language > b.language ? 1 : -1));
    }

    // let defaultLang = Core.interfaceLanguage(props.user.LanguageCode);
    let defaultLang = props.user.LanguageCode.toLowerCase();
    this.state = {
      closeCampaign: false,
      languageOptions: languageOptions,
      itemToEdit: {
        title: props.campaign.current.title ? props.campaign.current.title : "",
        language: props.campaign.current.data.language
          ? props.campaign.current.data.language
          : defaultLang,
      },
    };
  }

  handleChange = (e, field) => {
    const { itemToEdit } = this.state;
    const { campaign, lane, availableLanguages } = this.props;
    const value = e.target.value;

    // Load an other language version of a preset campagne
    if (lane === "campaign" && field === "language" && availableLanguages) {
      const newCampaign = availableLanguages.find((item) => {
        return item.language === value;
      });
      this.props.getTemplate(newCampaign.id, campaign.current.id);
    } else {
      this.setState({
        itemToEdit: {
          ...itemToEdit,
          [field]: value,
        },
      });
    }
  };

  goHome = () => {
    this.props.clear();
    store.dispatch(push("/start"));
  };

  getLanguageIcon = (lang) => {
    switch (lang) {
      case "nl-nl":
        return Netherlands;
      case "nl-be":
      case "fr-be":
        return Belgium;
      case "en-gb":
        return English;
      case "fr-fr":
        return France;
      case "es-es":
        return Spain;
      case "de-de":
        return Germany;
      case "it-it":
        return Italy;
      case "pt-pt":
        return Portugal;
      case "de-at":
        return Austria;
      case "de-ch":
        return Swiss;
      default:
        return English;
    }
  };

  render() {
    const {
      classes,
      t,
      steps,
      activeStep,
      getCurrentStep,
      onSelectStep,
      onClickStep,
    } = this.props;
    const { itemToEdit, languageOptions } = this.state;
    const maxTitle = 55;
    const stepIsCompleted = itemToEdit.language.length > 0;
    // console.log('EH langtitle state', this.state);
    // console.log('EH langtitle props', this.props);

    return (
      <React.Fragment>
        <FixScrollbar />
        <StepHeader
          itemToEdit={itemToEdit}
          steps={steps}
          activeStep={activeStep}
          onClickStep={onClickStep}
          stepIsCompleted={stepIsCompleted}
          lane={this.props.lane}
          helpText={t("common.header.button")}
        />
        <CampaignHeader
          title={t("langtitle.title")}
          intro={t("langtitle.intro")}
        />
        <LayoutBody
          component="section"
          width="small"
          textAlignCenter
          paddingBottom
          className={classes.form}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper paddingBig marginBottom className={classes.paper}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={11}>
                    <Typography variant="subtitle1">
                      {t("langtitle.form.campaigntitle.label")}
                    </Typography>
                    <Textfield
                      className={classes.field}
                      value={itemToEdit.title}
                      onChange={(e) => this.handleChange(e, "title")}
                      placeholder={t(
                        "langtitle.form.campaigntitle.placeholder"
                      )}
                      inputProps={{ maxLength: maxTitle }}
                      helperText={t("langtitle.form.campaigntitle.tooltip")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={11}>
                    <Typography variant="subtitle1">
                      {t("langtitle.form.lang.label")}
                    </Typography>
                    <Select
                      value={itemToEdit.language}
                      onChange={(e) => this.handleChange(e, "language")}
                      helperText={t("langtitle.form.lang.tooltip")}
                    >
                      {languageOptions.map((lang) => {
                        return (
                          <MenuItem value={lang} key={lang}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={this.getLanguageIcon(lang)}
                                style={{ marginRight: 20 }}
                                className={classes.language}
                              />
                              {t(`langtitle.form.lang.options.${lang}`)}
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </LayoutBody>
        <CampaignFooter
          onSelectPreviousStep={() => this.setState({ closeCampaign: true })}
          onSelectNextStep={() =>
            onSelectStep(false, 1, itemToEdit, stepIsCompleted)
          }
          previousStepIcon={<ArrowBackIcon fontSize="small" />}
          nextStepIcon={<ArrowForwardIcon fontSize="small" />}
          nextStepDisabled={!stepIsCompleted}
          getCurrentStep={getCurrentStep}
        />

        <Dialog
          open={this.state.closeCampaign}
          title={t("start.archive.exit.title")}
          text={t("start.archive.exit.description")}
          onCancel={() => this.setState({ closeCampaign: false })}
          cancelText={t("common.buttons.cancel")}
          onConfirm={() => this.goHome()}
          confirmText={t("common.buttons.overview")}
          type="close"
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(
    connect(Langtitle.mapStateToProps, {
      getTemplate: CampaignAction.getTemplate,
      clear: CampaignAction.clear,
    })(Langtitle)
  )
);
