import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';
import { Core } from '../helpers';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  backgroundLight: {
    backgroundColor: theme.palette.background.paper,
  },
  backgroundDark: {
    backgroundColor: theme.palette.grey[100],
  },
  noShadow: {
    boxShadow: theme.shadows[0],
  },
  paddingBig: {
    padding: theme.spacing(4),
  },
  paddingMedium: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(3),
  },
  fullHeight: {
    height: '100%',
  },
  bordered: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:  theme.palette.grey[200],
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  }
});

function Paper(props) {
  const {
    background,
    classes,
    className,
    paddingMedium,
    paddingBig,
    padding,
    noShadow,
    fullHeight,
    marginBottom,
    bordered,
    ...other
  } = props;

  return (
    <MuiPaper
      elevation={6}
      className={classNames(
        classes.root,
        classes[`background${Core.capitalize(background)}`],
        {
          [classes.paddingMedium]: paddingMedium,
          [classes.paddingBig]: paddingBig,
          [classes.padding]: padding,
          [classes.fullHeight]: fullHeight,
          [classes.bordered]: bordered,
          [classes.noShadow]: noShadow,
          [classes.marginBottom]: marginBottom,
        },
        className,
      )}
      {...other}
    />
  );
}

Paper.propTypes = {
  background: PropTypes.oneOf(['light', 'primary', 'dark', 'none']),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  padding: PropTypes.bool,
};

Paper.defaultProps = {
  background: 'light',
  padding: false
};

export default withStyles(styles)(Paper);