import types from './campaign.types';
import LWConnect from './../../../_LWCore/helpers/LWConnect/lw-connect';
import axios from 'axios';
import i18next from 'i18next';
import { Core, Import } from '../../helpers';
import lanes from '../../data/lanes.json';


/**
 *
 * @return {Function}
 */
function getList() {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.LOADING_PARTIAL,
                payload: true
            })

            const {error, data} = await LWConnect.post('getCampaigns', {});

            if (error) {
                return handleError(error);
            }

            dispatch({
                type: types.GET_LIST,
                payload: data,
            })

            dispatch({
                type: types.LOADING_PARTIAL,
                payload: false
            })

        } catch (e) {
            return handleError(e)
        }
    };
}

/**
 *
 * @return {Function}
 */
function getSingle(id) {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.LOADING,
                payload: true
            })

            const {error, data} = await LWConnect.post('getCampaignItem', {id});

            if (error) {
                return handleError(error);
            }

            // Actualize trainings info of stored trainings
            const trainings = data.data.trainings;
            if (trainings && Object.keys(trainings).length > 0) {
                data.data.trainings = Import.actualizeTrainings(trainings);
            }

            dispatch({
                type: types.GET_SINGLE,
                payload: data,
            })

            dispatch({
                type: types.LOADING,
                payload: false
            })

        } catch (e) {
            return handleError(e)
        }
    };
}


/**
 *
 * @return {Function}
 */
function getTemplate(id, oldId) {
    return async (dispatch) => {
        try {
            
            dispatch({
                type: types.LOADING,
                payload: true
            })

            const {error, data} = await LWConnect.post('getCampaignTemplateItem', {id});
            
            if (error) {
                return handleError(error);
            }

            data.data.lane = lanes.lanes[1];
            data.data.productsInactive = [];



            if (data.id) {
                // Remove id of template to create new campaign and store it as the templateId for analytics purposes.
                data.data = {
                    ...data.data,
                    templateCampaignId: data.id
                };
                delete data.id;
            }
            if (oldId) {
                // Replace campaign if switching language.
                data.id = oldId.toString();
            }
            if (data.date) {
                delete data.date;
            }
            // console.log("getTemplate data", data, oldId)
            const newCampaign = await LWConnect.post('saveCampaign', {...data});

            if (newCampaign.error) {
                return handleError(newCampaign.error);
            }

            // Actualize trainings info of stored trainings
            const trainings = data.data.trainings;
            if (trainings && Object.keys(trainings).length > 0) {
                data.data.trainings = Import.actualizeTrainings(trainings);
            } 
            
            dispatch({
                type: types.GET_TEMPLATE,
                payload: {...data, ...newCampaign.data}
                
            })

            dispatch({
                type: types.LOADING,
                payload: false
            })

        } catch (e) {
            return handleError(e)
        }
    };
}
/**
 *
 * @param error
 * @return {Function}
 */
function clear() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR,
            payload: false
        })
    };
}

/**
 *
 * @param error
 * @return {Function}
 */
function handleError(error) {
    console.error(error);
    return (dispatch) => {
        dispatch({
            type: types.ERROR,
            error
        })
    };
}

/**
 *
 * @param attributes
 * @return {function(*): *}
 */
function init(attributes) {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.LOADING,
                payload: true
            })

            // Set token in localstorage for API access.
            const newToken = Core.getParam('token');
            const oldToken = localStorage.getItem('token')
            if (newToken && newToken !== oldToken) {
                localStorage.setItem('token', newToken)
            }
            history.pushState(null, "", location.href.split("?")[0]);

            LWConnect.post('logging/session', {
                token: localStorage.getItem('token'),
                timestamp: Date.now()
            });

            // Get assets
            const assets = await LWConnect.post('getAssets', {});

            // Get materials
            const material = await axios.get(`/models/definitions.json?d=${Date.now()}`);

            // Get list
            const list = await LWConnect.post('getCampaigns', {});

            // Set interface language based on user.
            i18next.changeLanguage(Core.interfaceLanguage(list.data.user.LanguageCode));

            // Get product presets
            const presets = Import.getPresets(assets.data.SalesUnit, material.data);

            // Get company info
            const company = Import.getCompany(assets.data.Company);

            // Get course categories
            const courseCats = Import.getCourseCats(assets.data.ContentList);

            // Get campaign templates
            const templates = await LWConnect.post('getCampaignTemplates', {});

            // Combine assets and course categories
            const assetsCombined = {
                ...assets.data,
                CourseList: assets.data.ContentList,
                courseCategories: courseCats,
                templates: templates.data.campaigns,
            }

            // Remove ContentList from assetsCombined because it is CourseList in the application and ContentList from the API.
            delete assetsCombined.ContentList;

            // Find errors
            const error = assets.error || material.error || list.error;

            if (error) {
                return handleError(error);
            }

            // Get the publishtoken assigned to this user.
            const publishTokenResponse = await LWConnect.get('getPublishToken');
            if (publishTokenResponse.success === 1) {
                localStorage.setItem('publishToken', publishTokenResponse.data.key);
            }
            else {
                console.warn("No publish token provided");
            }

            dispatch({
                type: types.INIT,
                payload: {
                    list: list.data.campaigns,
                    user: list.data.user,
                    assets: assetsCombined,
                    material: material.data,
                    presets: presets,
                    company: company,
                }
            });

            dispatch({
                type: types.LOADING,
                payload: false
            })

        } catch (e) {
            console.error(e);
        }
    };
}

/**
 *
 * @param attributes
 * @return {Function}
 */
function create(attributes) {
    // We are creating a new campaign. Better make sure the id is empty.
    if (attributes.id) {
        delete attributes.id;
    }
    return async (dispatch) => {
        try {
            dispatch({
                type: types.LOADING,
                payload: true
            })
            
            const {error, data} = await LWConnect.post('saveCampaign', {...attributes});

            if (error) {
                return handleError(error);
            }

            dispatch({
                type: types.CREATE,
                payload: {...attributes, ...data},
            })

            dispatch({
                type: types.LOADING,
                payload: false
            })

        } catch (e) {
            return handleError(e)
        }
    };
}

/**
 *
 * @param attributes
 * @return {Function}
 */
function update(attributes) {
    // If the attributes has no id, create a new campaign. Don't update.
    if (!attributes.id) {
        return create(attributes);
    }

    return async (dispatch) => {
        try {
            dispatch({
                type: types.LOADING,
                payload: true
            })

            dispatch({
                type: types.UPDATE,
                payload: attributes
            })

            dispatch({
                type: types.LOADING,
                payload: false
            })
            
            const {error} = await LWConnect.post('saveCampaign', {...attributes});

            if (error) {
                return handleError(error);
            }

        } catch (e) {
            return handleError(e)
        }
    };
}

/**
 *
 * @param attributes
 * @return {Function}
 */
function updateProducts(attributes) {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.LOADING,
                payload: true
            })

            dispatch({
                type: types.UPDATE_PRODUCTS,
                payload: attributes
            })

            dispatch({
                type: types.LOADING,
                payload: false
            })
        } catch (e) {
            return handleError(e)
        }
    };
}

/**
 *
 * @param id
 * @return {Function}
 */
function remove(id) {
    return async (dispatch) => {
        try {
            const {error} = await LWConnect.post('removeCampaign', {id});

            if (error) {
                return handleError(error);
            }

            dispatch({
                type: types.REMOVE,
                payload: {id}
            })

        } catch (e) {
            return handleError(e)
        }
    };
}

/**
 *
 * @param {object}
 * @return {Function}
 */
function setLane(lane) {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.LANE,
                payload: lane
            })

        } catch (e) {
            return handleError(e)
        }
    };
}

export default {
    init,
    getList,
    getSingle,
    getTemplate,
    create,
    update,
    updateProducts,
    remove,
    clear,
    setLane
}
