import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import CheckBig from '../../../../components/CheckBig.component';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withTranslation } from 'react-i18next';
import ThumbNail from '../../../../components/ThumbNail.component';
import ProductIcon from './Product.icon.component.jsx';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
    root: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
        minHeight: theme.spacing(10),
        maxWidth: '100%'
    },
    noBorder: {
        borderBottom: 'none',
    },
    cat: {
        marginRight: theme.spacing(1), 
    },
    checked: {
        backgroundColor: theme.palette.grey[100],
    },
    avatar: {
        marginLeft: theme.spacing(2)
    },
    icon: {
        position: 'absolute',
        top: 0,
        left: 0,
        maxWidth: '90%',
        marginLeft: '5%',
        marginTop: '5%'
    },
    preview: {
        paddingLeft: theme.spacing(3),
        flexShrink: 0,
    },
    previewText: {
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    previewLink: {
        color: '#46403D',
        fontSize: theme.spacing(1.5),
        textAlign: 'right',
        cursor: 'pointer'
    },
    previewModal: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center;'
    },
    previewContainer: {
        maxHeight: '90%',
        position: 'relative'
    },
    previewImage: {
        maxHeight: '100%'
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2)
    }
});

class ProductRow extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            campaignLanguage: campaign.current.data.language,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            showPreview: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.checked !== this.props.checked || nextState.showPreview !== this.state.showPreview || nextProps.view !== this.props.view;
    }

    render() {
        const {
            classes,
            product,
            checked,
            onHandleChange,
            view,
            noBorder,
            t
        } = this.props;
        const {
            showPreview
        } = this.state;

        let output = null;
        if (view === 'edit') {
            output =
                <ListItemAvatar>
                    <CheckBig
                        checked={checked}
                        value={String(product.key)}
                        onChange={(e) => onHandleChange(e, product)}
                    />
                </ListItemAvatar>
        }

        return (
            <React.Fragment>
            <ListItem className={
                classNames(
                    classes.root,
                    {
                        [classes.noBorder]: noBorder,
                        [classes.checked]: checked && view === 'edit',
                    }
                )}
            >
                {output}
                <ListItemAvatar className={classes.avatar}>
                    <ThumbNail
                        bordered
                    >
                        <ProductIcon
                            icon={product.icon}
                            className={classes.icon}
                        />
                    </ThumbNail>
                </ListItemAvatar>
                <ListItemText
                    primary={t(`material.${product.key}.title`)}
                    primaryTypographyProps={{
                        variant: 'h4',
                        gutterBottom: true,
                    }}
                    secondary={t(`material.${product.key}.desc.short`)}
                    secondaryTypographyProps={{
                        variant: 'caption'
                    }}
                />
                {product.preview &&
                    <ListItemText
                        classes={{
                            root: classes.preview,
                            primary: classes.previewText
                        }}
                        primary={
                            <Link
                                classes={{ root: classes.previewLink }}
                                underline="always"
                                onClick={() => this.setState({ showPreview: true})}
                            >
                                {t(`common.products.preview`)}
                            </Link>
                        }
                        primaryTypographyProps={{
                            variant: 'h6',
                            gutterBottom: true,
                        }}
                    />
                }
            </ListItem>
            {showPreview && product.preview &&
                <Modal open onClose={() => this.setState({ showPreview: false})}>
                    <div className={classes.previewModal} onClick={() => this.setState({ showPreview: false})}>
                        <div className={classes.previewContainer}>
                        <Fab aria-label="Close" className={classes.closeIcon} size="small" onClick={() => this.setState({ showPreview: false})}>
                            <CloseIcon />
                        </Fab>
                        <img
                            className={classes.previewImage}
                            src={`${process.env.TEMPLATE_HOST}product_previews/${product.preview}`}
                        />
                        </div>
                    </div>
                </Modal>
            }
            </React.Fragment>
        );
    }
}

export default withTranslation()(withStyles(styles)(
    connect(ProductRow.mapStateToProps, {})(ProductRow)
));