import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Core } from '../helpers';

const styles = theme => ({
  root: {
    outline: 0,
    '&:focus': {
      outline: 0
    }
  },
  paddingBottomSmall: {
    paddingBottom: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },
  paddingTopSmall: {
    paddingTop: theme.spacing(2),
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
  paddingBottomBig: {
    paddingBottom: theme.spacing(8),
  },
  paddingTopBig: {
    paddingTop: theme.spacing(8),
  },
  widthExtrasmall: {
      width: 423,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  widthSmall: {
      width: 792,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: 600
      }
  },
  widthMediumSmall: {
      width: 896,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  widthMedium: {
      width: 1198,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  widthLarge: {
      width: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  widthXlarge: {
      width: 1800,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  dark: {
    backgroundColor: '#F8F7F9'
  },
  purple: {
    backgroundColor: theme.palette.primary.main
  },
});

function LayoutBody(props) {
  const {
    children,
    classes,
    className,
    component: Component,
    fullHeight,
    fullWidth,
    dark,
    purple,
    paddingTopSmall,
    paddingTop,
    paddingBottomSmall,
    paddingBottom,
    paddingTopBig,
    paddingBottomBig,
    textAlignCenter,
    style,
    width,
    tabindex,
    ...other
  } = props;

  const component = (
    <Component
      className={classNames(
        classes.root,
        {
          [classes[`width${Core.capitalize(width)}`]]: !fullWidth,
          [classes.fullHeight]: fullHeight,
          [classes.paddingBottom]: paddingBottom,
          [classes.paddingTopSmall]: paddingTopSmall,
          [classes.paddingTop]: paddingTop,
          [classes.paddingBottomSmall]: paddingBottomSmall,
          [classes.paddingBottomBig]: paddingBottomBig,
          [classes.paddingTopBig]: paddingTopBig,
          [classes.textAlignCenter]: textAlignCenter,
        },
        className,
      )}
      style={style}
      {...other}
    >

        {children}

    </Component>
  )

  if (dark) {
    return (
      <div className={classNames(
          classes.dark, {
            [classes.fullHeight]: fullHeight,
          }
        )}
      >
        {component}
      </div>
    );
  }

  if (purple) {
    return (
      <div className={classNames(
          classes.purple, {
            [classes.fullHeight]: fullHeight,
          }
        )}
      >
        {component}
      </div>
    );
  }

  return component;
}

export default withStyles(styles)(LayoutBody);
