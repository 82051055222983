import React from 'react';
import { connect } from 'react-redux';
import { Core, Export } from '../../../../helpers';
import CustomizePreviewIframe from './CustomizePreviewIframe.component';

class CustomizePreviewEmail extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            material: Core.arrayToObject(campaign.material, 'key'),
            presets: campaign.presets,
            trainings: Core.arrayToObject(campaign.assets.CourseList, 'CourseId'),
            savedTrainings: campaign.current.data.trainings ? campaign.current.data.trainings : {},
            campaignLanguage: campaign.current.data.language
        };
    }

    constructor(props) {
        super(props)

        const thisMaterial = props.material[props.prodKey];

        this.state = {
            previewUrl: this.getPreviewUrl(),
            sizes: {
                output: thisMaterial.output,
                contentWidth: thisMaterial.output.width,
                contentHeight: thisMaterial.output.height,
                scale: 1,
                pages: 1
            }
        }
    }

    componentDidMount() {
        const {
            material,
            prodKey,
            product,
            company,
        } = this.props;

        this.resizePreview(material[prodKey]);
        this.postToPreview(this.getResult(product, company));
    }

    getPreviewUrl = () => {
        const {
            material,
            prodKey
        } = this.props;
        // console.log("Preview", material[prodKey])
        const previewUrl = `${process.env.TEMPLATE_HOST}${material[prodKey].output.template}/`;
        return previewUrl;
    }

    postToPreview = (result) => {
        const form = document.getElementById("previewForm");
        const data = document.getElementById("previewData");

        if (form) {
            data.value = JSON.stringify(result);
            form.submit();
        }

    }

    resizePreview = (material) => {
        const {
            sizes,
        } = this.state;

        const wrapper = document.getElementById(`wrapper_${material.key}`);

        if (wrapper) {
            const wrapperWidth = wrapper.clientWidth;
    
            let contentWidth = sizes.contentWidth;
            let contentHeight = sizes.contentHeight;
            let pages = sizes.pages;

            const scale =  wrapperWidth / contentWidth;
            const newSizes = {
                contentWidth: contentWidth,
                contentHeight: contentHeight,
                scale: scale,
                pages: pages,
            }
            this.setState({
                sizes: newSizes,
            })
        }
    }

    getResult = (product, company) => {
        const {
            trainings,
            savedTrainings,
            material,
            presets,
            prodKey,
            campaignLanguage,
        } = this.props;

        const thisMaterial = material[prodKey]

        return Export.toHtmlTemplate(
            product,
            trainings,
            savedTrainings,
            thisMaterial,
            company,
            presets,
            campaignLanguage,
            1
        )
    }

    render() {
        const {
            prodKey,
            product,
            company
        } = this.props;
        const {
            previewUrl,
            sizes,
        } = this.state;

        const result = this.getResult(product, company);
        this.postToPreview(result);
        
        return(
            <React.Fragment>
                <form id="previewForm" method="post" target={`previewIframe_${prodKey}`} action={previewUrl}>
                    <input type="hidden" id="previewData" name="data" value={JSON.stringify(result)} />
                </form>
                <CustomizePreviewIframe
                    prodKey={prodKey}
                    previewUrl={previewUrl}
                    sizes={sizes}
                    scrolling="auto"
                />
            </React.Fragment>
        )
    }
}

export default connect(CustomizePreviewEmail.mapStateToProps, {})(CustomizePreviewEmail);