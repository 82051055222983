import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import { withStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
        '&:focus': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    input: {
        fontSize: '0.9375rem',
        padding: 15,
        paddingRight: 40,
        lineHeight: '26px',
        minHeight: 26,
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        transform: 'translateY(-2px)',
    },
    helpertext: {
        marginLeft: theme.spacing(2)
    }
});

function Select(props) {
    const {
        children,
        classes,
        value,
        onChange,
        helperText,
        ...other
    } = props;
    // console.log("Select", value)

    return (
        <React.Fragment>
            <MuiSelect
                variant='filled'
                className={classes.root}
                value={value}
                onChange={onChange}
                classes={{
                    root: classes.root,
                    icon: classes.icon,
                    filled: classes.input,
                    select: classes.select,
                }}
                input={
                    <FilledInput
                        disableUnderline
                        fullWidth
                    />
                }
                {...other}
            >
                {children}
            </MuiSelect>
            {helperText && <FormHelperText className={classes.helpertext}>{helperText}</FormHelperText>}
        </React.Fragment>
    )
}

export default withStyles(styles)(Select);