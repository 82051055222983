import React from 'react';
import StepIcon from './StepIcon.component';
import StepLabel from './StepLabel.component';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';

const styles = theme => ({
    root: {
        background: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        maxHeight: 80
    },
    enabled: {
        cursor: 'pointer'
    },
    disabled: {
        cursor: 'default'
    },
    connectorRoot: {
        left: 'calc(-50% + 8px)',
        right: 'calc(50% + 8px)',
        zIndex: 0,
        borderColor: '#E1E1E1'
    },
    connectorLine: {
        marginTop: 0,
        borderWidth: 2,
        transition: theme.transitions.create('border-color'),
        borderColor: theme.palette.primary.main
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: '#E1E1E1',
        },
    },
})

function Stepper(props) {
    const {
        classes,
        t,
        steps,
        activeStep,
        onClickStep,
        itemToEdit,
        complete,
    } = props;

    const maxActive = complete ? activeStep + 1 : activeStep;

    const connector = (
      <StepConnector
        classes={{
            root: classes.connectorRoot,
            line: classes.connectorLine,
            disabled: classes.connectorDisabled,
        }}
      />
    );

    return (
        <MuiStepper
            className={classes.root}
            alternativeLabel
            activeStep={activeStep - 1}
            connector={connector}
        >
            {steps.map((step, key) => {
                if (key === 0) return null
                const disabled = key > maxActive && !step.complete;
                return (
                <Step
                    key={key}
                    onClick={(e) => { disabled ? false : onClickStep(e, key, itemToEdit, complete) }}
                >
                        <StepLabel
                            disabled={disabled}
                            classes={{
                                root: disabled ? classes.disabled : classes.enabled,
                            }}
                            icon={
                                    <StepIcon
                                        icon={step.id}
                                        active={key === activeStep}
                                        done={key < activeStep}
                                    />
                            }
                        >
                            {t(`steps.${step.id}.title`)}
                        </StepLabel>
                </Step>
            )})}
        </MuiStepper>
    )
}

export default withTranslation()(withStyles(styles)(Stepper));