import React from 'react';
import OnboardingStepper from '../controllers/Onboarding/components/Onboarding.stepper.component.jsx';
import Modal from '@material-ui/core/Modal';
import LayoutBody from './LayoutBody.component';
import stepsData from '../controllers/Onboarding/data/onboardingsteps.json';
import Button from './Button.component.js';

class Help extends React.Component {

    constructor(props) {
        super(props);

        let showHelp = true;
        if (localStorage.getItem('onBoardingSeen') || sessionStorage.getItem('onBoardingSeen')) showHelp = false;
        
        this.state = {
            showHelp,
            activeStep: 0,
            onboardingSteps: stepsData,
        }
    }

    showHelp = (show) => {
        if (!show) sessionStorage.setItem('onBoardingSeen', true);
        this.setState({
            activeStep: 0,
            showHelp: show
        })
    }

    /**
     * Mark the onboarding as seen.
     */
    setSeen = () => {
        localStorage.setItem('onBoardingSeen', true);
    }

    onStep = () => {
        const {
            activeStep,
            onboardingSteps,
        } = this.state;

        if (activeStep === onboardingSteps.length - 1) {
            this.setSeen();
            this.showHelp(false);
        }
        else {
            this.setState({ activeStep: activeStep + 1 });
        }
    }

    onBack = () => {
        const {
            activeStep
        } = this.state;

        if (activeStep > 0) {
            this.setState({ activeStep: activeStep - 1 })
        }
    }

    render() {
        const {
            t,
            classes,
            helpText
        } = this.props;
        const {
            showHelp,
            activeStep,
            onboardingSteps
        } = this.state;

        return (
            <React.Fragment>
                <Button classes={classes} onClick={() => this.showHelp(true)} variant="outlined" size="small">{helpText}</Button>
                <Modal
                    open={showHelp}
                    onClose={() => this.showHelp(false)}
                >
                    <LayoutBody
                        component="div"
                        width="extrasmall"
                        textAlignCenter
                        fullHeight
                    >
                        <OnboardingStepper
                            activeStep={activeStep}
                            onboardingSteps={onboardingSteps}
                            onStep={this.onStep}
                            onClose={this.showHelp}
                            onBack={this.onBack}
                            overContent
                        />
                    </LayoutBody>
                </Modal>
            </React.Fragment>
        )
    }
}

export default Help;