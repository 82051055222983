import React from 'react';
import { withTranslation } from 'react-i18next';
import Select from '../../../../../../components/Select.component';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { alpha } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    up: {
        alignSelf: 'flex-start',
        marginBottom: -theme.spacing(1.5)
    },
    right: {
        textAlign: 'right',
    },
    select: {
        transition: theme.transitions.create('background-color'),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, .1),
        },
    },
    icon: {
        backgroundColor: alpha(theme.palette.common.white, .5),
        color: alpha(theme.palette.common.black, .6),
    },
    menu: {
        borderRadius: theme.spacing(1), 
        overflow: 'hidden',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: alpha(theme.palette.common.black, .3),
    },
    list: {
        padding: 0,
    },
});

class CustomizeSelect extends React.Component {

    constructor(props) {
        super(props);

    }

    UNSAFE_componentWillMount() {
        const {
            value,
            prodKey,
            field,
            onHandleChangeField,
        } = this.props;
        const path = prodKey + '.' + field.field;
        onHandleChangeField(path, value ? value : field.options[0]);
    }

    render() {
        const {
            classes,
            t,
            value,
            field,
            prodKey,
            onHandleChangeField,
        } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} className={classes.root}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.up}>
                            <Typography variant="body2">
                                {t(`material.${prodKey}.fields.${field.field}.label`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                id={prodKey + '.' + field.field}
                                value={value ? value : field.options[0]}
                                onChange={(e) => onHandleChangeField(prodKey + '.' + field.field, e.target.value)}
                                helperText={t(`material.${prodKey}.fields.${field.field}.tooltip`)}
                                classes={{
                                    root: classes.select,
                                    icon: classes.icon,
                                }}
                                MenuProps={{
                                    classes: { paper: classes.menu },
                                    MenuListProps: {
                                        className: classes.list
                                    }
                                }}
                            >
                                {field.options.map((option, key) => {
                                    return <MenuItem
                                        key={key}
                                        value={option}
                                        className={classes.option}
                                    >
                                        {t(`material.${prodKey}.fields.${field.field}.options.${option}`)}
                                    </MenuItem>
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(CustomizeSelect));