import React, { Suspense } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import StartRoutes from './controllers/Start/Start.routes.jsx';
import StepsRoutes from './controllers/Steps/Steps.routes.jsx';
import LoaderFullPage from './components/TopBar';
import { history } from './store';
import './fonts/fonts.css';

export default function AppRoutes(props) {
    return (
        <Router history={history}>
            <Suspense fallback={<LoaderFullPage />}>
                <Switch>
                    {/* <Route exact path="/" component={Onboarding} /> */}
                    <Route path="/start" role="guest" component={StartRoutes} />
                    <Route path="/form" role="guest" component={StepsRoutes} />
                    <Redirect from="*" to={`/start${props.location.search}`} />
                </Switch>
            </Suspense>
        </Router>
    );
}
