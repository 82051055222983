import React from 'react';
import { connect } from 'react-redux';
import Core from '../../../../../../helpers/Core';
import CustomizeTrainingsListCol from './CustomizeTrainingsListCol.component';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
    },
    gridItem: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1), 
    },
})

class CustomizeTrainingsList extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        const courseLanguage = Core.campaignLanguage(campaign.current.data.language, 'course');
        return {
            ...props,
            trainings: campaign.current.data.trainings,
            allTrainings: campaign.assets.CourseList.filter((training) =>
                training.Language === courseLanguage || training.Language === ''),
            categories: campaign.assets.courseCategories.filter((cat) => 
                cat.languages.indexOf(courseLanguage) >= 0),
            campaignLanguage: campaign.current.data.language
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            trainingsLeft: {},
            trainingsRight: {},
            trainings: {},
            trainingsSource: props.all ? Core.arrayToObject(props.allTrainings, 'CourseId') : props.trainings,
            catOpen: [],
        }
    }

    UNSAFE_componentWillMount() {
        const {
            prodKey,
            field,
            onHandleChangeField,
            categories,
            campaignLanguage,
        } = this.props;
        const {
            trainingsSource,
        } = this.state;
        const path = prodKey + '.' + field.field;
        const selected = this.getSelected();

        // Order trainings by category.
        const trainingsArray = Object.keys(trainingsSource).map((t) => trainingsSource[t]);

        const numLeftCats = Math.ceil(Object.keys(categories).length / 2);
        const trainingsCategorized = {};
        const trainingsCategorizedLeft = {};
        const trainingsCategorizedRight = {};
        categories.forEach( (cat, index) => {
            const thisCat = {
                id: cat.id,
                catTitle: cat.label[Core.campaignLanguage(campaignLanguage, 'cat')],
                trainings: trainingsArray.filter(item => {
                    return item.CourseCategoryId === cat.id;
                })
            }
            if (index < numLeftCats) {
                trainingsCategorizedLeft[cat.id] = thisCat
            }
            else {
                trainingsCategorizedRight[cat.id] = thisCat
            }
            trainingsCategorized[cat.id] = thisCat
        })

        this.setState({
            trainings: trainingsCategorized,
            trainingsLeft: trainingsCategorizedLeft,
            trainingsRight: trainingsCategorizedRight,
            selected: selected,
        });
        onHandleChangeField(path, selected);
    }

    handleChange = (e) => {
        const {
            selected
        } = this.state;
        const {
            prodKey,
            field,
            onHandleChangeField
        } = this.props;
        const id = e.target.id;
        const checked = e.target.checked;
        const path = prodKey + '.' + field.field;
        let newSelected = [];
        if (checked) {
            // Add training
            selected.push(id);
            newSelected = selected;
        }
        else {
            // Remove training
            newSelected = selected.filter(item => item !== id);
        }
        
        this.setState({selected: newSelected});
        onHandleChangeField(path, newSelected);
    }

    handleCatChange = (e) => {
        const {
            selected,
            trainings,
        } = this.state;
        const {
            prodKey,
            field,
            onHandleChangeField
        } = this.props;
        const cat = e.target.id.substring(4);
        const checked = e.target.checked;
        const path = prodKey + '.' + field.field;
        const traingsSet = trainings[cat].trainings;
        let newSelected = selected;
        if (checked) {
            // Add all trainings in cat
            traingsSet.forEach(item => {
                if (!newSelected.includes(String(item.CourseId))) {
                    newSelected.push(String(item.CourseId));
                }
            });
        }
        else {
            // Remove all trainings in cat
            traingsSet.forEach(item => {
                newSelected.splice(newSelected.indexOf(String(item.CourseId)), 1);
            });
        }
        
        this.setState({selected: newSelected});
        onHandleChangeField(path, newSelected);
        // console.log('EH handleCatChange', cat, newSelected);
    }

    handleCatOpen = (cat) => {
        const {
            catOpen
        } = this.state;
        if (catOpen.indexOf(cat) < 0) {
            // Add this cat to the array of open categories.
            catOpen.push(cat);
        }
        else {
            // Remove this cat to the array of open categories.
            catOpen.splice(catOpen.indexOf(cat), 1);
        }
        this.setState({
            catOpen: catOpen,
        })
    }

    checkCat = (cat) => {
        const {
            selected,
            trainings,
        } = this.state;
        const trainingsSet = trainings[cat].trainings;
        let complete = -1
        if (trainingsSet.every(t => selected.includes(String(t.CourseId)))) {
            complete = 1
        }
        else if (trainingsSet.some(t => selected.includes(String(t.CourseId)))) {
            complete = 0
        }
        return complete;
    }

    getSelected = () => {
        const {
            value,
            campaignLanguage
        } = this.props;
        const {
            trainingsSource,
        } = this.state;
        if (Array.isArray(value)) {
            // Selected allready set or changed campaign language? Check trainingslist.
             const newValue = value.filter(item => {
                if (trainingsSource[item]) {
                    trainingsSource[item].Language === Core.campaignLanguage(campaignLanguage, 'course') || trainingsSource[item].Language === '';
                }
                return false;
             })
            if (newValue.length > 0) {
                return newValue;
            }
            else {
                return  Object.keys(trainingsSource);
            }
        }
        else {
            // Fresh start: set every training as active.
            return  Object.keys(trainingsSource);
        }
    }

    render() {
        const {
            classes,
            error,
            errorText,
        } = this.props;
        const {
            selected,
            trainingsLeft,
            trainingsRight,
            catOpen,
        } = this.state;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        return (
            <Grid
                container
            >
            
                <Collapse in={error}>
                    <Grid
                        item
                        xs={12}
                        className={classes.gridItem}
                    >
                        <Typography
                            variant='body1'
                            color='error'
                        >
                            {errorText}
                        </Typography>
                    </Grid>
                </Collapse>
                <Grid
                    item xs={12}
                    className={classes.root}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            {Object.keys(trainingsLeft).map( (cat, index) =>
                                <CustomizeTrainingsListCol
                                    key={index}
                                    cat={cat}
                                    index={index}
                                    trainings={trainingsLeft}
                                    catOpen={catOpen}
                                    selected={selected}
                                    checkCat={this.checkCat(cat)}
                                    handleChange={this.handleChange}
                                    handleCatChange={this.handleCatChange}
                                    handleCatOpen={this.handleCatOpen}
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {Object.keys(trainingsRight).map( (cat, index) =>
                                <CustomizeTrainingsListCol
                                    key={index}
                                    cat={cat}
                                    index={index}
                                    trainings={trainingsRight}
                                    catOpen={catOpen}
                                    selected={selected}
                                    checkCat={this.checkCat(cat)}
                                    handleChange={this.handleChange}
                                    handleCatChange={this.handleCatChange}
                                    handleCatOpen={this.handleCatOpen}
                                />
                            )}
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
        )
    }

}

export default withStyles(styles)(
    connect(CustomizeTrainingsList.mapStateToProps, {})(CustomizeTrainingsList)
);
