import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from './Paper.component';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
    root: {
        display: 'flex',
        marginBottom: theme.spacing(3),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        "& input::placeholder": {
            color: '#8C8C8C',
            fontSize: theme.spacing(2)
        }
    }
});

function SearchBar(props) {
    const {
        classes,
        onSearchHandler,
        placeholder = "",
        ...other
    } = props;

    return (
        <Paper
            className={classes.root}
        >
            <IconButton aria-label="Search">
                <SearchIcon />
            </IconButton>
            <InputBase
                className={classes.input}
                onChange={(e) => onSearchHandler(e)}
                placeholder={placeholder}
            />
        </Paper>
    )
}

export default withStyles(styles)(SearchBar);
