import React from 'react';
import { connect } from 'react-redux';
import { Core } from '../../../../helpers';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CustomizePreviewVideo from './CustomizePreviewVideo.component';
import CustomizePreviewPdf from './CustomizePreviewPdf.component';
import CustomizePreviewEmail from './CustomizePreviewEmail.component';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import Pretty from '../../../../components/pretty.component';

const styles = theme => ({
    label: {
        margin: theme.spacing(2)
    },
    stepper: {
        maxWidth: '100%',
        flexGrow: 1,
    },
})

class CustomizePreview extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            material: Core.arrayToObject(campaign.material, 'key'),
            campaignLanguage: campaign.current.data.language
        };
    }

    constructor(props) {
        super(props)

        const thisMaterial = props.material[props.prodKey];
        const previewUrl = this.getVideoPreviewUrl(props.prodKey);

        this.state = {
            output: thisMaterial.output,
            showVariant: 0,
            previewUrl: previewUrl,
            scrollPosition: 0
        }

        this.container = React.createRef();
        this.preview = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }

    handleScroll = () => {
        const containerElement = this.container && this.container.current
        const previewElement = this.preview && this.preview.current
        if (containerElement && previewElement) {
            if ((containerElement.getBoundingClientRect().top) <= 0) {
                let position = containerElement.getBoundingClientRect().top;

                if (position < 0) {
                    position = Math.abs(position);
                }

                const containerHeight = containerElement.getBoundingClientRect().height;
                const templateHeight = previewElement.getBoundingClientRect().height;
                const difference = (containerHeight - templateHeight) - 200;

                if (position >= difference) {
                    return;
                }

                this.setState({ scrollPosition: position + 100 });
            } else {
                this.setState({ scrollPosition: 0 });
            }
        }
    }

    setVariantTot = (product) => {
        const {
            material,
            prodKey,
        } = this.props;
        const {
            showVariant
        } = this.state;

        if (prodKey && product) {
            const output = material[prodKey].output;
            let tot = 1;

            if (output.iterate && Array.isArray(product[output.iterate])) {
                const iterations = product[output.iterate].filter((item) => {
                    return item !== '-1'
                });

                tot = iterations.length;
            }

            if (showVariant > tot - 1) {
                this.setState({
                    showVariant: tot - 1,
                });
            }

            return tot;
        } else {
            return 0;
        }
    }

    handleVariant = (e, dir) => {
        const {
            showVariant,
            totVariants,
        } = this.state;

        const newActiveVariant = showVariant + dir < 0 ? 0 : showVariant + dir > totVariants ? totVariants : showVariant + dir;

        this.setState({
            showVariant: newActiveVariant,
        })
    }

    getVideoPreviewUrl = (prodKey) => {
        const { campaignLanguage, material } = this.props;
        let previewUrl = '';
        if (material[prodKey] && material[prodKey].output.type === 'mp4') {
            const langPath = campaignLanguage.split('-')[0];
            previewUrl = `${process.env.TEMPLATE_HOST}mp4/${langPath}/${prodKey}.mp4`;
        }
        else if (material[prodKey] && material[prodKey].output.type === 'download'
            && material[prodKey].output
            && material[prodKey].output.product
            && material[prodKey].output.product.extention === 'mp4'
        ) {
            const product = material[prodKey].output.product;
            previewUrl = `${product.url}${product.fileName}_${campaignLanguage}.${product.extention}`
        }
        return previewUrl;
    }

    render() {
        const {
            classes,
            t,
            product,
            prodKey,
            company,
            campaignLanguage
        } = this.props;
        const {
            output,
            showVariant,
            previewUrl
        } = this.state;

        const totVariants = this.setVariantTot(product);

        return (
            <div ref={this.container} style={{ minHeight: '100%' }}>
                <Grid
                    container
                    spacing={2}
                >
                    {output.type === 'mp4' || output.type === 'pdf' || output.type === 'image' || output.type === 'eml' || output.type === 'download' ? (
                        <React.Fragment>
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="body2"
                                    className={classes.label}
                                >
                                    {t('customize.preview')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <div ref={this.preview} style={{ transform: `translateY(${this.state.scrollPosition}px)` }}>
                                    {totVariants > 1 &&
                                        <MobileStepper
                                            steps={totVariants}
                                            activeStep={showVariant}
                                            position="static"
                                            // variant={totVariants > 8 ? "progress" : "dots"}
                                            variant="dots"
                                            className={classes.stepper}
                                            nextButton={
                                                <IconButton size="small" onClick={(e) => this.handleVariant(e, 1)} disabled={showVariant === totVariants - 1}>
                                                    <KeyboardArrowRight fontSize="inherit" />
                                                </IconButton>
                                            }
                                            backButton={
                                                <IconButton size="small" onClick={(e) => this.handleVariant(e, -1)} disabled={showVariant === 0}>
                                                    <KeyboardArrowLeft />
                                                </IconButton>
                                            }
                                        />
                                    }
                                    {(output.type === 'mp4' || output.type === 'download') && previewUrl.length > 0 ? (
                                        <CustomizePreviewVideo
                                            previewUrl={previewUrl}
                                            campaignLanguage={campaignLanguage}
                                        />
                                    ) : output.type === 'pdf' || output.type == 'image' ? (
                                        <CustomizePreviewPdf
                                            prodKey={prodKey}
                                            product={product}
                                            company={company}
                                            showVariant={showVariant}
                                        />
                                    ) : output.type === 'eml' ? (
                                        <CustomizePreviewEmail
                                            prodKey={prodKey}
                                            product={product}
                                            company={company}
                                        />
                                    ) : null}
                                </div>
                            </Grid>
                        </React.Fragment>
                    )
                        : (
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    className={classes.label}
                                >
                                    {t('customize.nopreview')}
                                </Typography>
                            </Grid>
                        )}
                </Grid>
            </div>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizePreview.mapStateToProps, {})(CustomizePreview)
));
