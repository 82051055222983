import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../../../helpers';
import Grid from '@material-ui/core/Grid';
import Select from '../../../../../../components/Select.component';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { alpha } from '@material-ui/core/styles';

const styles = theme => ({
    training: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.grey[200],
        '&:nth-child(3n + 3)': {
            borderRight: 'none',
        }
    },
    lastRow: {
        borderBottom: 'none',
    },
    image: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 210,
        marginBottom: theme.spacing(2),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center, center',
    },
    error: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1), 
        paddingBottom: theme.spacing(1), 
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    hidden: {
        display: 'none',
    },
    firstselect: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        color: alpha(theme.palette.common.black, .6),
    }
})

class CustomizeTrainingsVariant extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            trainings: campaign.current.data.trainings,
            campaignLanguage: Core.campaignLanguage(campaign.current.data.language, 'course'),
        };
    }

    constructor(props) {
        super(props);

        const trainingsArray = Object.keys(props.trainings).filter(key => {
            return props.trainings[key].Language === props.campaignLanguage || props.trainings[key].Language === '';
        });
        const fieldLength = Core.getTrainingsFieldLength(trainingsArray.length, props.field.max, props.field.min);

        this.state = {
            fieldLength: fieldLength,
            selected: Array.isArray(props.value) ? props.value : Array(fieldLength).fill({ training: "-1", variant: "1" }),
            lastRowFilled: Math.floor(fieldLength / 3) * 3,
            trainingsArray: trainingsArray,
        }
    }

    UNSAFE_componentWillMount() {
        const {
            trainings,
            campaignLanguage,
            prodKey,
            field,
            onHandleChangeField,
        } = this.props;
        const {
            selected,
            fieldLength,
            trainingsArray,
        } = this.state;

        // Prefill training slots
        const path = prodKey + '.' + field.field;
        let nextPick = 0;
        let nextVariant = 0;
        const firstVariant = field.options[0].key

        // Selected Training not found or in wrong language, replace with next available training.
        selected.forEach((v, i) => {
            if (!trainings[v.training] || (trainings[v.training].Language !== campaignLanguage && trainings[v.training].Language !== '')) {
                if (i < fieldLength) {
                    selected[i] = { training: trainingsArray[nextPick], variant: field.options[nextVariant].key };
                    nextPick++
                    nextPick = nextPick > (trainingsArray.length - 1) ? 0 : nextPick;
                    nextVariant++
                    nextVariant = nextVariant > (field.options.length - 1) ? 0 : nextVariant;
                }
                else {
                    selected[i] = { training: "-1", variant: firstVariant }
                }
            }
        })

        // A training has been added. Extend selected.
        if (selected.length < fieldLength) {
            const checkArray = [...trainingsArray];
            selected.forEach(sel => {
                checkArray.splice(checkArray.indexOf(sel.training), 1)
            })
            while (selected.length < fieldLength) {
                selected.push({
                    training: checkArray[0],
                    variant: firstVariant
                })
                checkArray.splice(0, 1);
            }
        }

        // A training has been removed. Reduce selected.
        if (selected.length > fieldLength) {
            selected.length = fieldLength;
        }


        // Put empty selections at the back.
        selected.sort((a, b) => {
            if (a.training === '-1') {
                return 1;
            }
            else if (b.training === '-1') {
                return -1;
            }
            else return 0;
        })

        this.setState({
            selected: selected,
        });
        onHandleChangeField(path, selected);

        // Preload training images.
        trainingsArray.forEach((key, i) => {
            const img = new Image();
            img.src = (() => {
                if (trainings[key].AssetList.length) {
                    return trainings[key].AssetList.find((asset) => {
                        return asset.CategoryId === 301
                    })?.Value;
                }

                return trainings[key].TileTransparent;
            })();
        })
    }

    handleSelect = (e) => {
        const {
            selected
        } = this.state;
        const {
            prodKey,
            field,
            onHandleChangeField
        } = this.props;
        const pos = parseInt(e.target.name.split('-')[1]);
        const key = e.target.name.split('-')[0]
        const value = e.target.value;
        const path = prodKey + '.' + field.field;
        const newValue = {
            ...selected[pos],
            [key]: value
        }

        selected[pos] = newValue;
        this.setState({ selected: selected });
        onHandleChangeField(path, selected);
    }

    getLowResImage = (training) => {
        const imgHiRes = training.AssetList.find((asset) => {
            return asset.CategoryId === 301
        })?.Value;
        const imgLowRes = training.CourseTileTransparent.slice(-3) === 'png' ? training.CourseTileTransparent : imgHiRes;
        return imgLowRes;
    }

    getId = (value) => {
        return parseInt(value.split('|')[0]);
    }

    getImage = (value) => {
        const { trainings } = this.props;
        const id = this.getId(value);
        if (id > 0 && trainings[id]) {
            return `${this.getLowResImage(trainings[id])}`
        }
        return 'none';
    }

    getBackgroundColor = (value) => {
        const { trainings } = this.props;
        const id = this.getId(value);
        if (id > 0 && trainings[id]) {
            return trainings[id].CourseCategoryColor
        }
        return 'initial';
    }

    render() {
        const {
            classes,
            t,
            prodKey,
            field,
            trainings,
            error,
            errorText,
            hidden,
        } = this.props;
        const {
            selected,
            fieldLength,
            trainingsArray,
            lastRowFilled,
        } = this.state;
        // console.log('EH state', this.state);
        // console.log('EH props', this.props);

        return (
            <React.Fragment>
                <Grid
                    container
                    className={classNames({ [classes.hidden]: hidden })}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Collapse in={error}>
                            <Typography
                                color="error"
                                variant="caption"
                                className={classes.error}
                            >
                                {errorText}
                            </Typography>
                        </Collapse>
                    </Grid>
                </Grid>
                <Grid container>
                    {[...Array(fieldLength)].map((e, i) =>
                        <Grid
                            item
                            xs={12}
                            key={i}
                            className={classNames(
                                classes.training,
                                {
                                    [classes.lastRow]: i + 1 > lastRowFilled,
                                }
                            )}
                        >
                            <Grid container>
                                <Grid item xs={12} className={classes.firstselect}>
                                    <Select
                                        name={`training-${i})`}
                                        value={selected[i].training}
                                        displayEmpty
                                        onChange={this.handleSelect}
                                        classes={{
                                            icon: classes.icon
                                        }}
                                        renderValue={key => {
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: (selected[i].training > 0 && trainings[selected[i].training]) ? trainings[selected[i].training].CourseCategoryColor : 'initial',
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius: 4,
                                                            marginRight: 26
                                                        }}
                                                    >
                                                        <img
                                                            src={selected[i].training > 0 && trainings[selected[i].training] ? this.getLowResImage(trainings[selected[i].training]) : 'none'}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%'
                                                            }}
                                                        />
                                                    </div>
                                                    {trainings[key].CourseTitle}
                                                </div>
                                            )
                                        }}
                                    >
                                        {trainingsArray.map((key, index) =>
                                            <MenuItem key={key} value={key}>{trainings[key].CourseTitle}</MenuItem>
                                        )};
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        name={`variant-${i})`}
                                        value={selected[i].variant}
                                        displayEmpty
                                        onChange={this.handleSelect}
                                        classes={{
                                            icon: classes.icon
                                        }}
                                    >
                                        {field.options.map((option, index) =>
                                            <MenuItem key={option.key} value={option.key}>{t(`material.${prodKey}.fields.${field.field}.options.${option.key}`)}</MenuItem>
                                        )};
                                    </Select>
                                </Grid>
                            </Grid>

                        </Grid>)}
                </Grid>
            </React.Fragment>
        )
    }

}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeTrainingsVariant.mapStateToProps, {})(CustomizeTrainingsVariant)
));
