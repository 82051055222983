import { Core } from "../../helpers";
import he from "he";
import i18next from "i18next";

class Export {
    /**
     *
     * @param {any} desc
     * @param {string} lang
     * @returns {string}
     *
     */
    static getTrainingsDescription(desc, lang) {
        // Remove linebreaks if present, because theJSON test will fail on them.
        const cleanDesc = desc.replace(/\n/g, " ");

        if (Core.isJsonString(cleanDesc)) {
            return he.decode(JSON.parse(cleanDesc)[Core.campaignLanguage(lang, "cat")]);
        }
        return he.decode(desc);
    }

    /**
     *
     * @param string
     *
     */
    static toHtmlTemplate(product, trainings, savedTrainings, material, company, presetsSource, lang, iterateVariant) {
        const presets = presetsSource[Core.campaignLanguage(lang, "presets")];
        const protocolPattern = /^((http|https):\/\/)/;
        const result = [];
        // handle company info.
        result.push({
            key: "company_logo",
            value: company.logo
        });
        result.push({
            key: "company_lms",
            value: company.lms
        });
        result.push({
            key: "company_payoff_1",
            value: presets && presets.payoff ? presets.payoff.part_1 : "payoff-1"
        });
        result.push({
            key: "company_payyoff_2",
            value: presets && presets.payoff ? presets.payoff.part_2 : "payyof-2"
        });
        result.push({
            key: "company_trainingsurl",
            value: presets ? presets.trainingsurl : "trainingsurl"
        });
        result.push({
            key: "company_reference",
            value: presets ? presets.reference : "reference"
        });
        result.push({
            key: "company_overviewpostertitle",
            value: presets ? presets.overviewpostertitle : "title"
        });
        result.push({
            key: "campaign_lang",
            value: Core.campaignLanguage(lang, "display")
        });
        result.push({
            key: "campaign_lang-cat",
            value: Core.campaignLanguage(lang, "cat")
        });
        if (material.output && material.output.iterateVariant) {
            // Check wether to pass a number or an option
            let thisValue = iterateVariant;
            if (material.output.iterateVariant.options && Array.isArray(material.output.iterateVariant.options)) {
                thisValue = material.output.iterateVariant.options[iterateVariant - 1];
            }
            result.push({
                key: material.output.iterateVariant.key,
                value: thisValue
            });
        }
        // Handle custom fields
        material.customizeFields.forEach((field) => {
            // console.log('EH exportProduct field', field, product);
            switch (field.type) {
                case "trainings":
                case "trainings-hidden":
                case "trainings-custom-url":
                    if (product[field.field]) {
                        let elements = [];
                        let training = "";

                        product[field.field].forEach((value, index) => {
                            training = value.split("|")[0];

                            if (savedTrainings[training]) {
                                let trainingsUrl = "";
                                if (value.split("|")[1] == undefined) {
                                    trainingsUrl = savedTrainings[training].Url;
                                } else {
                                    trainingsUrl = value.split("|")[1];

                                    if (!protocolPattern.test(trainingsUrl)) {
                                        trainingsUrl = "https://" + trainingsUrl;
                                    }
                                }

                                let imgHiRes = (() => {
                                    if (savedTrainings[training].AssetList.length) {
                                        return savedTrainings[training].AssetList.find((asset) => {
                                            return asset.CategoryId === 301;
                                        })?.Value;
                                    }

                                    return savedTrainings[training].CourseTileTransparent;
                                })();
                                let imgLowRes =
                                    savedTrainings[training].CourseTileTransparent.slice(-3) === "png"
                                        ? savedTrainings[training].CourseTileTransparent
                                        : imgHiRes;

                                elements.push({
                                    key: "training",
                                    elements: [
                                        {
                                            key: "id",
                                            value: savedTrainings[training].CourseId
                                        },
                                        {
                                            key: "title",
                                            value: savedTrainings[training].CourseTitle
                                        },
                                        {
                                            key: "description",
                                            value: Export.getTrainingsDescription(
                                                savedTrainings[training].CourseDescription,
                                                lang
                                            )
                                        },
                                        {
                                            key: "image",
                                            value: imgHiRes
                                        },
                                        {
                                            key: "image_lowres",
                                            value: imgLowRes
                                        },
                                        {
                                            key: "color",
                                            value: savedTrainings[training].CourseCategoryColor
                                        },
                                        {
                                            key: "url",
                                            value: trainingsUrl
                                        }
                                    ]
                                });
                            }
                        });
                        result.push({
                            key: field.field,
                            elements: elements
                        });
                    }
                    break;
                case "trainingslist-cats":
                case "trainingslist-all-cats":
                case "trainingslist-all-cats-desc":
                    // Build a categorized trainingslist.
                    if (product[field.field]) {
                        const removeDuplicates = (myArr, prop) => {
                            return myArr.filter((obj, pos, arr) => {
                                return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
                            });
                        };
                        const trainingsComplete = product[field.field]
                            .filter((x) => !!trainings[x])
                            .map((x) => trainings[x]);
                        const categories = removeDuplicates(trainingsComplete, "CourseCategoryId");
                        let categoriesElements = [];
                        categories.forEach((category, index) => {
                            const categoryElements = [];
                            const catNames = JSON.parse(category.CourseCategoryName);
                            // Category title
                            categoryElements.push({
                                key: "cat_title",
                                value: catNames[Core.campaignLanguage(lang, "cat")]
                            });
                            // Category icon
                            categoryElements.push({
                                key: "cat_icon",
                                value: category.AssetList.find((asset) => {
                                    return asset.CategoryId === 301;
                                }).Value
                            });
                            //category trainingslist
                            const catTrainings = trainingsComplete.filter((training) => {
                                return training.CourseCategoryId === category.CourseCategoryId;
                            });
                            const trainElements = [];

                            if (field.type === "trainingslist-all-cats-desc") {
                                catTrainings.forEach((training, index) => {
                                    trainElements.push({
                                        key: "title",
                                        elements: [
                                            {
                                                key: "id",
                                                value: training.CourseId
                                            },
                                            {
                                                key: "title",
                                                value: training.CourseTitle
                                            },
                                            {
                                                key: "description",
                                                value: Export.getTrainingsDescription(training.CourseDescription, lang)
                                            }
                                        ]
                                    });
                                });
                            } else {
                                catTrainings.forEach((training, index) => {
                                    trainElements.push({
                                        key: "title",
                                        value: training.CourseTitle
                                    });
                                });
                            }
                            categoryElements.push({
                                key: "cat_trainings",
                                elements: trainElements
                            });
                            categoriesElements.push({
                                key: "category",
                                elements: categoryElements
                            });
                        });
                        result.push({
                            key: "categories",
                            elements: categoriesElements
                        });
                    }
                    break;
                case "trainingslist":
                case "trainingslist-all":
                    // Build a sorted trainingslist.
                    if (product[field.field]) {
                        let elements = [];
                        const sortedTrainings = product[field.field].sort((a, b) => {
                            const thisA = parseInt(a);
                            const thisB = parseInt(b);
                            if (thisA < thisB) {
                                return 1;
                            } else if (thisB < thisA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });

                        sortedTrainings.forEach((training) => {
                            if (trainings[training]) {
                                let imgHiRes = (() => {
                                    if (trainings[training].AssetList.length) {
                                        return trainings[training].AssetList.find((asset) => {
                                            return asset.CategoryId === 301;
                                        })?.Value;
                                    }

                                    return trainings[training].CourseTileTransparent;
                                })();
                                let imgLowRes =
                                    trainings[training].CourseTileTransparent.slice(-3) === "png"
                                        ? trainings[training].CourseTileTransparent
                                        : imgHiRes;

                                elements.push({
                                    key: "training",
                                    elements: [
                                        // {
                                        //     key: 'id',
                                        //     value:  trainings[training].CourseId,
                                        // },
                                        {
                                            key: "title",
                                            value: trainings[training].CourseTitle
                                        },
                                        {
                                            key: "image",
                                            value: imgHiRes
                                        },
                                        {
                                            key: "image_lowres",
                                            value: imgLowRes
                                        },
                                        {
                                            key: "color",
                                            value: trainings[training].CourseCategoryColor
                                        }
                                    ]
                                });
                            }
                        });
                        result.push({
                            key: "trainings",
                            elements: elements
                        });
                    }
                    break;
                case "radio-postcard":
                case "radio-tablecard":
                    if (product[field.field]) {
                        const selected = product[field.field];
                        const presetName = field.type.split("-")[1];
                        const presetValue = presets[presetName].find((p) => {
                            return p.key === selected;
                        });
                        if (presetValue.payLoad) {
                            Object.keys(presetValue.payLoad).forEach((key) => {
                                result.push({
                                    key: key,
                                    value: presetValue.payLoad[key]
                                });
                            });
                        }
                    }
                    break;
                case "multiselect-postcard":
                    if (product[field.field]) {
                        let elements = [];
                        let presetValue = "";
                        const selected = Array.isArray(product[field.field])
                            ? product[field.field]
                            : Array(1).fill(product[field.field]);
                        const presetName = field.type.split("-")[1];
                        selected.forEach((item, index) => {
                            let itemKeys = [];
                            presetValue = presets[presetName].find((p) => {
                                return p.key === item;
                            });
                            if (presetValue.payLoad) {
                                Object.keys(presetValue.payLoad).forEach((key) => {
                                    itemKeys.push({
                                        key: key,
                                        value: presetValue.payLoad[key]
                                    });
                                });
                            }
                            elements.push({
                                key: "text",
                                elements: itemKeys
                            });
                        });
                        result.push({
                            key: "texts",
                            elements: elements
                        });
                    }
                    break;
                case "multiselect":
                    if (product[field.field]) {
                        let elements = [];
                        let presetValue = "";
                        const selected = Array.isArray(product[field.field])
                            ? product[field.field]
                            : Array(1).fill(product[field.field]);
                        selected.forEach((item, index) => {
                            elements.push(item);
                        });
                        result.push({
                            key: "items",
                            elements: elements
                        });
                    }
                    break;
                case "trainingsvariant":
                    if (product[field.field]) {
                        let elements = [];
                        let variants = [];
                        product[field.field].forEach((value, index) => {
                            const training = value.training;
                            if (savedTrainings[training]) {
                                let imgHiRes = (() => {
                                    if (savedTrainings[training].AssetList.length) {
                                        return savedTrainings[training].AssetList.find((asset) => {
                                            return asset.CategoryId === 301;
                                        })?.Value;
                                    }

                                    return savedTrainings[training].CourseTileTransparent;
                                })();
                                let imgLowRes =
                                    savedTrainings[training].CourseTileTransparent.slice(-3) === "png"
                                        ? savedTrainings[training].CourseTileTransparent
                                        : imgHiRes;

                                elements.push({
                                    key: "training",
                                    elements: [
                                        {
                                            key: "id",
                                            value: savedTrainings[training].CourseId
                                        },
                                        {
                                            key: "title",
                                            value: savedTrainings[training].CourseTitle
                                        },
                                        {
                                            key: "description",
                                            value: Export.getTrainingsDescription(
                                                savedTrainings[training].CourseDescription,
                                                lang
                                            )
                                        },
                                        {
                                            key: "image",
                                            value: imgHiRes
                                        },
                                        {
                                            key: "image_lowres",
                                            value: imgLowRes
                                        },
                                        {
                                            key: "color",
                                            value: savedTrainings[training].CourseCategoryColor
                                        },
                                        {
                                            key: "url",
                                            value: savedTrainings[training].Url
                                        }
                                    ]
                                });

                                variants.push(value.variant);
                            }
                        });
                        result.push({
                            key: "trainings",
                            elements: elements
                        });
                        result.push({
                            key: "variant",
                            value: variants[0]
                        });
                    }
                    break;
                case "image_upload":
                    result.push({
                        key: field.field,
                        value: product[field.field]
                    });
                    break;
                default:
                    // Check if traningsurl from presets should be replaced.
                    if (field.field == "custom-trainingsurl" && product[field.field].length > 0) {
                        let customTrainingsurl = product[field.field];
                        if (!protocolPattern.test(customTrainingsurl)) {
                            customTrainingsurl = "https://" + customTrainingsurl;
                        }
                        var foundIndex = result.findIndex((x) => x.key == "company_trainingsurl");
                        if (foundIndex > -1) {
                            result[foundIndex] = {
                                key: "company_trainingsurl",
                                value: customTrainingsurl
                            };
                        } else {
                            result.push({
                                key: "company_trainingsurl",
                                value: customTrainingsurl
                            });
                        }
                    } else {
                        result.push({
                            key: field.field,
                            value: product[field.field]
                        });
                    }
            }
        });
        // console.log('EH exportProduct result', result);

        return result;
    }

    static toMp4(product, trainings, savedTrainings, material, company, presetsSource, lang) {
        const result = {
            tdtemplatedata: []
        };

        const createParameter = (comp, id, layerName, type, value) => {
            const param = {
                comp: comp,
                layerName: layerName,
                type: type,
                value: value
            };

            if (id) {
                param.id = id;
            }

            return param;
        };

        material.customizeFields.forEach((field) => {
            // console.log('EH exportProduct field', field, product);
            switch (field.type) {
                case "trainings":
                    if (product[field.field] !== undefined) {
                        product[field.field].forEach((training, index) => {
                            if (savedTrainings[training]) {
                                let layerName = "";
                                let compName = "";
                                let courseTitle = "";
                                let courseDescription = "";
                                let color = "000000";
                                const defaultImgSrc = `${process.env.TEMPLATE_HOST}mp4/logo_white.png`;
                                const imgSrc = (() => {
                                    const foundImgCat = savedTrainings[training].AssetList.find((asset) => {
                                        return asset.CategoryId === 301;
                                    });
                                    if (!foundImgCat) return defaultImgSrc;
                                    if (!foundImgCat.Value) return defaultImgSrc;
                                    if (foundImgCat.Value.slice(-3) !== "png") return defaultImgSrc;
                                    return foundImgCat.Value;
                                })();
                                if (material.templates && material.templates.trainings) {
                                    const trainingsTemplates = material.templates.trainings;
                                    if (trainingsTemplates.layerName) {
                                        layerName = trainingsTemplates.layerName.layer.replace("[index]", index + 1);
                                        compName = trainingsTemplates.layerName.comp.replace("[index]", index + 1);
                                        result.tdtemplatedata.push(
                                            createParameter(compName, null, layerName, "image", imgSrc)
                                        );
                                    }
                                    if (trainingsTemplates.courseTitle) {
                                        courseTitle = trainingsTemplates.courseTitle.layer.replace(
                                            "[index]",
                                            index + 1
                                        );
                                        result.tdtemplatedata.push(
                                            createParameter(
                                                trainingsTemplates.courseTitle.comp,
                                                null,
                                                courseTitle,
                                                "text",
                                                savedTrainings[training].CourseTitle
                                            )
                                        );
                                    }
                                    if (trainingsTemplates.courseDescription) {
                                        courseDescription = trainingsTemplates.courseDescription.layer.replace(
                                            "[index]",
                                            index + 1
                                        );
                                        result.tdtemplatedata.push(
                                            createParameter(
                                                trainingsTemplates.courseDescription.comp,
                                                null,
                                                courseDescription,
                                                "text",
                                                savedTrainings[training].Description
                                            )
                                        );
                                    }
                                    if (trainingsTemplates.color) {
                                        color = trainingsTemplates.color.layer.replace("[index]", index + 1);
                                        result.tdtemplatedata.push(
                                            createParameter(
                                                trainingsTemplates.color.comp,
                                                null,
                                                color,
                                                "text",
                                                savedTrainings[training].CourseCategoryColor.replace("#", "")
                                            )
                                        );
                                    }
                                }
                            }
                        });
                        result.tdtemplatedata.push(
                            createParameter("main", null, "text___contentAmount", "text", product[field.field].length)
                        );
                    }
                    break;
                case "text-hidden":
                case "text":
                    if (product[field.field] !== undefined) {
                        result.tdtemplatedata.push(
                            createParameter(field.comp, field.id, field.field, "text", product[field.field])
                        );
                    }
                    break;
                case "company":
                    if (company[field.key] !== undefined) {
                        result.tdtemplatedata.push(
                            createParameter(field.comp, field.id, field.field, "text", company[field.key])
                        );
                    } else {
                        result.tdtemplatedata.push(createParameter(field.comp, field.id, field.field, "text", ""));
                    }
                case "get-value-on-export":
                    let newValue = null;
                    if (typeof field.default?.trainingIndex === "number" && field.default?.valueToCheck) {
                        const trainingKey = product?.trainings?.[field.default.trainingIndex];
                        const key = trainings?.[trainingKey]?.[field.default.valueToCheck];
                        if (trainingKey && key) {
                            newValue = i18next.t(
                                `material.${material.key}.fields.${field.field}.default.defaultObject.${key}`,
                                { lng: Core.campaignLanguage(lang, "display") }
                            );
                        }
                    }

                    if (newValue !== null && newValue !== undefined) {
                        result.tdtemplatedata.push(
                            createParameter(field.comp, field.id, field.field, "text", newValue)
                        );
                    }
                    break;
                default:
                    console.warn("unknown fields", field);
            }
        });

        if (material.assets) {
            //There are preset assets to export.
            for (const [key, value] of Object.entries(material.assets)) {
                // console.log(key, value);
                if (key === "companyLogo") {
                    result.tdtemplatedata.push(createParameter(value.comp, null, value.layer, "image", company.logo));
                }
            }
        }

        console.log("EH exportProduct result", result);
        return result;
    }

    /**
     *
     * @param string
     *
     */
    static toDownload(material, lang) {
        // console.log(material);
        const result = {
            url: `${material.output.product.url}${material.output.product.fileName}_${lang}.${material.output.product.extention}`,
            fileName: `${material.output.product.fileName}_${lang}.${material.output.product.extention}`,
            thumbUrl: `${material.output.product.url}${material.output.product.fileName}_thumb_${lang}.png`,
            thumbFileName: `${material.output.product.fileName}_thumb_${lang}.png`
        };
        return result;
    }
}

export default Export;
