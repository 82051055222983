import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ListItemSecondaryAction, Typography, withStyles } from '@material-ui/core';
import { Core } from '../../../../helpers'; 
import CheckBig from '../../../../components/CheckBig.component';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import he from 'he';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withTranslation } from 'react-i18next';
import ThumbNail from '../../../../components/ThumbNail.component';
import TrainingIcon from './Training.icon.component.jsx';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
        minHeight: theme.spacing(10)
    },
    noBorder: {
        borderBottom: 'none',
    },
    cat: {
        marginRight: theme.spacing(1), 
    },
    checked: {
        backgroundColor: theme.palette.grey[100],
    },
    avatar: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    iconContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        maxWidth: '90%'
    },
    preview: {
        textAlign: 'right'
    },
    previewLink: {
        color: '#46403D',
        fontSize: theme.spacing(1.5),
        textAlign: 'right',
        cursor: 'pointer'
    }
});

class TrainingsRow extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            campaignLanguage: campaign.current.data.language,
        };
    }

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.checked !== this.props.checked;
    }

    render() {
        const {
            classes,
            training,
            checked,
            onHandleChange,
            view,
            noBorder,
            campaignLanguage,
            t
        } = this.props;

        // Descriptions come as string or multilingual JSON.
        let desc = training.CourseDescription.replace(/(?:\r\n|\r|\n)/g, '');
        if (Core.isJsonString(desc)) {
            desc = JSON.parse(desc)[Core.campaignLanguage(campaignLanguage, 'cat')];
            try {
                desc = he.decode(desc);
            } catch (e) {
                console.log(e);
            }
        }

        let output = null;
        if (view === 'edit') {
            output = (
                <ListItemAvatar>
                    <CheckBig
                        checked={checked}
                        value={String(training.key)}
                        onChange={(e) => onHandleChange(e, training.CourseId)}
                    />
                </ListItemAvatar>
            );
        }

        return (
            <ListItem className={
                classNames(
                    classes.root,
                    {
                        [classes.noBorder]: noBorder,
                        [classes.checked]: checked,
                    }
                )}
            >
                {output}
                <ListItemAvatar className={classes.avatar}>
                    <ThumbNail
                        noBackground
                        className={classes.iconContainer}
                    >
                        <TrainingIcon
                            icon={training.CourseTileTransparent}
                            className={classes.icon}
                        />
                    </ThumbNail>
                </ListItemAvatar>
                <ListItemText
                    primary={training.CourseTitle}
                    primaryTypographyProps={{
                        variant: 'h4',
                        gutterBottom: true,
                    }}
                    secondary={desc}
                    secondaryTypographyProps={{
                        variant: 'caption'
                    }}
                />
            </ListItem>
        )

    }
}

export default withTranslation()(withStyles(styles)(
    connect(TrainingsRow.mapStateToProps, {})(TrainingsRow)
));