import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Core } from '../../../../../../helpers';
import TextField from '../../../../../../components/Textfield.component';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { InputAdornment } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[200],
    },
    right: {
        textAlign: 'right',
    },
    up: {
        alignSelf: 'flex-start',
        marginBottom: -theme.spacing(1.5)
    },
    hidden: {
        display: 'none',
    }
});

class CustomizeText extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        const campaignLanguage = campaign.current.data.language
        return {
            ...props,
            current: campaign.current,
            presets: campaign.presets[Core.campaignLanguage(campaignLanguage, 'presets')],
            campaignLanguage: campaignLanguage,
        };
    }

    constructor(props) {
        super(props);

        const tempValue = this.cleanValue(props.value);
        const strippedValue = Core.stripHtml(tempValue);

        this.state = {
            tempValue,
            strippedValue
        };
    }

    UNSAFE_componentWillMount() {
        const {
            t,
            prodKey,
            value,
            field,
            presets,
            campaignLanguage,
            onHandleChangeField,
        } = this.props;

        if (value === undefined) {
            let newValue = '';
            const path = prodKey + '.' + field.field;
            // Check for defaults and aply them.
            if (field.default) {
                if (field.default.presetPath) {
                    if (Array.isArray(field.default.presetPath)) {
                        field.default.presetPath.forEach((item) => {
                            newValue = newValue + Core.getObjectKeyByString(presets, item) + ' ';
                        })
                    }
                    else {
                        newValue = Core.getObjectKeyByString(presets, path);
                    }
                }
                else if (field.default.value) {
                    newValue = t(`material.${prodKey}.fields.${field.field}.default`, { lng: campaignLanguage })
                }
            }

            onHandleChangeField(path, this.cleanValue(newValue));
            this.setState({
                tempValue: newValue,
            })
        }
    }

    /**
     * Removo emoij's from a string
     * @param {string} value 
     * @returns string
     */
    cleanValue = (value) => {
        let tempValue = value;
        if (!!value && typeof value == 'string') {
            tempValue = tempValue.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        }
        return tempValue;
    }

    /**
     * Calculate the max value for the input field.
     * The length of the used HTML in the input field is added to the max value specified.
     * @returns max vaue for input field
     */
    getFieldMax = () => {
        const { tempValue, strippedValue, } = this.state;
        const { field } = this.props;
        let max = undefined;
        if (field.max && field.max > 0) {
            const htmlLength = tempValue.length - strippedValue.length;
            max = field.max + htmlLength;
        }
        return max;
    }

    onHandleBlur = (path) => {
        const { onHandleChangeField } = this.props;
        const { tempValue } = this.state;
        onHandleChangeField(path, tempValue);
    }

    onHandleChange = (value) => {
        const tempValue = this.cleanValue(value);
        const strippedValue = Core.stripHtml(tempValue);
        this.setState({
            tempValue,
            strippedValue
        })
    }

    render() {
        const {
            classes,
            t,
            field,
            prodKey,
            error,
            errorText,
            required,
            multiline,
            hidden,
        } = this.props;
        const {
            tempValue,
            strippedValue
        } = this.state;

        return (
            <Grid
                container
                className={classNames({ [classes.hidden]: hidden })}
            >
                <Grid item xs={12} className={classes.root}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={classes.up}>
                            <Typography variant="body2">
                                {t(`material.${prodKey}.fields.${field.field}.label`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id={prodKey + '.' + field.field}
                                value={tempValue}
                                onChange={(e) => { this.onHandleChange(e.target.value) }}
                                onBlur={() => this.onHandleBlur(prodKey + '.' + field.field)}
                                helperText={error ? errorText : t(`material.${prodKey}.fields.${field.field}.tooltip`)}
                                multiline={multiline}
                                error={error}
                                required={required}
                                placeholder={field.placeholder}
                                endAdornment={field.max ? field.max - strippedValue.length : false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withStyles(styles)(
    connect(CustomizeText.mapStateToProps, {})(CustomizeText)
));