import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionActions';

const styles = theme => ({
    root: {
        display: 'block',
        textAlign: 'left',
        padding: 0,
    },
})

class ExpansionPanelDetails extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            children,
            classes,
            expanded,
            onChange,
            ...other
        } = this.props;
        // console.log('EH props', this.props);

        return (
            <AccordionDetails
                // expanded={expanded}
                onChange={onChange}
                classes={{
                    root: classes.root,
                }}
                {...other}
            >
                {children}
            </AccordionDetails>
        );
    }
}

export default withStyles(styles)(ExpansionPanelDetails);
