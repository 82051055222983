class Core {


    /**
     *
     * @param {string} paramName
     * @return {string}
     */
    static getParam(paramName) {
        var searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");
        
        for (i=0;i<params.length;i++) {
            val = params[i].split("=");
            if (val[0] == paramName) {
                return val[1];
            }
        }
        return null;
    }

    /**
     *
     * @param {string} string
     * @param {any} value
     * @return {object}
     */
    static createObjectFromString(string, value) {
        const parts = string.split('.');
        var last = parts[parts.length - 1];
        var tree = {};
      
        var node = parts.slice(0, -1).reduce(function (memo, current) {
           return (memo[current] = {});
        }, tree);
      
        node[last] = value;
        return tree;
    }

    /**
     *
     * @param {object} object
     * @param {string} string
     * @return {object}
     */
    static getObjectKeyByString(object, string) {
        string = string.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        string = string.replace(/^\./, '');           // strip a leading dot
        var a = string.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in object) {
                object = object[k];
            } else {
                return;
            }
        }
        return object;
    }
    /**
     *
     * @param {string} id
     * @param {int} offset
     */
    static scrollToId(id, offset = 0) {
        if (document.getElementById(id)) {
            const el = document.getElementById(id);
            let userInteraction = false;
            const userTrap = () => {
                userInteraction = true;
            } 
            document.addEventListener("mousewheel", userTrap);
            const callback = () => {
                const c = el.scrollTop;
                el.scrollTop = c - c / 8;
                if (c > offset && !userInteraction) {
                    window.requestAnimationFrame(callback)
                }
                else {
                    document.removeEventListener("mousewheel", userTrap);
                    userInteraction = false;
                };
            }
            window.requestAnimationFrame(callback)
        }
    }
    /**
     *
     * @param {array} array
     * @param {string} key
     * @return {object}
     */
    static arrayToObject(array, key) {
        const result = {};
        array.forEach( (item) => {
            result[String(item[key])] = item;
        })
        return result;
    }
    
    /**
     *
     * @return {number}
     */
    static mmToPixel() {
        // return window.devicePixelRatio * 96 / 25.4;
        return 96 / 25.4;
    }

     /**
     *
     * @param {string} str
     * @return {boolean}
     */
    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
    /**
     *
     * @param {string} lang
     * @param {string} format
     * @return {string}
     */
    static campaignLanguage(lang, format) {
        const languages = {
            "nl-nl": {
                course: 'NL',
                cat: 'nl-nl',
                display: 'nl-NL',
                presets: 'nl-nl'
            },
            "nl-be": {
                course: 'BE',
                cat: 'nl-be',
                display: 'nl-BE',
                presets: 'nl-be'
            },
            "fr-be": {
                course: 'WA',
                cat: 'fr-be',
                display: 'fr-FR', // Use french images in templates.
                presets: 'fr-be' 
            },
            "en-gb": {
                course: 'EN',
                cat: 'en-gb',
                display: 'en-GB',
                presets: 'en-gb'
            },
            "fr-fr": {
                course: 'FR',
                cat: 'fr-fr',
                display: 'fr-FR',
                presets: 'fr-fr'
            },
            "es-es": {
                course: 'ES',
                cat: 'es-es',
                display: 'es-ES',
                presets: 'es-es'
            },
            "de-de": {
                course: 'DE',
                cat: 'de-de',
                display: 'de-DE',
                presets: 'de-de'
            },
            "it-it": {
                course: 'IT',
                cat: 'it-it',
                display: 'it-IT',
                presets: 'it-it'
            },
            "pt-pt": {
                course: 'PT',
                cat: 'pt-pt',
                display: 'pt-PT',
                presets: 'pt-pt'
            },
            "de-at": {
                course: 'at',
                cat: 'de-at',
                display: 'de-DE',
                presets: 'de-de'
            },
            "de-ch": {
                course: 'CH',
                cat: 'de-ch',
                display: 'de-DE',
                presets: 'de-ch'
            },
            "pl-pl": {
                course: 'PL',
                cat: 'pl-pl',
                display: 'pl-PL',
                presets: 'pl-pl'
            },
            "da-dk": {
                course: 'da',
                cat: 'da-dk',
                display: 'da-DK',
                presets: 'dk-dk'
            },
            "sv-se": {
                course: 'SV',
                cat: 'sv-se',
                display: 'sv-SE',
                presets: 'sv-se'
            }
        }
        if (languages[lang] && languages[lang][format]) {
            return languages[lang][format];
        }
        else {
            console.error(`Language not found: ${lang} ${format}`);
            return null;
        }
    }

        /**
     *
     * @param {string} lang
     * @return {string}
     */
    static interfaceLanguage(lang) {
        const languages = new Map(
            [
                ['nl-nl', 'nl'],
                ['en-gb', 'en'],
                ['nl-be', 'be'],
                ['fr-fr', 'fr'],
                ['es-es', 'es'],
                ['it-it', 'it'],
                ['de-de', 'de'],
                ['pt-pt', 'pt'],
                ['fr-be', 'wa'],
                ['de-at', 'at'],
                ['de-ch', 'ch'],
                ['pl-pl', 'pl'],
                ['da-dk', 'da'],
                ['sv-se', 'sv']
            ]
        );
        return languages.get(lang.toLocaleLowerCase());
    }

    /**
     *
     * @param {string} name
     * @return {string}
     */
    static slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')
      
        return string.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
    }
    
    /**
     *
     * @param {number} trainingsNum
     * @param {number || array} fieldMax
     * @return {number}
     */
    static getTrainingsFieldLength(trainingsNum, fieldMax, fieldMin) {
        let max = fieldMin;
        if (Array.isArray(fieldMax)) {
            // It's a stepped max
            fieldMax.forEach(m => {
                if (trainingsNum >= m) {
                    max = m;
                }
            })
        }
        else {
            max = trainingsNum < fieldMax ? trainingsNum : fieldMax;
        }
        return max;
    }
    
    /**
    * Returns the pure text from a HTML string
    * @param {html} String The html string
    */
    static stripHtml(html) {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    /**
     * Capitalize a string (first character to upercase)
     * @param {string} text 
     * @returns 
     */
    static capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}

export default Core;
