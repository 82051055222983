import { v4 as uuidv4 } from "uuid";
import i18n from "../../i18n";
import { Core, Export } from "../../helpers";

class Tasks {
    static createParallel(tasks) {
        return {
            service: "publish",
            type: "parallel",
            tasks: tasks,
        };
    }

    static createUrlEncode(string, resourceId) {
        return {
            service: "utilities",
            type: "stringUrlEncode",
            string: string,
            resources: {
                string: resourceId,
            },
        };
    }

    static createHttpRequest(url, data, method, resourceId) {
        return {
            service: "utilities",
            type: "httpRequest",
            method: method,
            parameters: data,
            url: url,
            resourceId: resourceId,
        };
    }

    static createResourceToFile(source, extension, resourceId) {
        return {
            service: "files",
            type: "storeFile",
            source: source,
            extension: extension,
            resourceId: resourceId,
        };
    }

    static createString(template, resources, resourceId) {
        return {
            service: "utilities",
            type: "stringReplace",
            string: template,
            elements: resources,
            resources: {
                string: resourceId,
            },
        };
    }

    static createPDF(source, width, height, pageRange, resourceId) {
        return {
            service: "creatives",
            type: "HTMLToPDF",
            htmlUrl: source,
            width: width,
            height: height,
            resourceId: resourceId,
        };
    }

    static createImage(source, width, height, extension, resourceId) {
        return {
            service: "creatives",
            type: "HTMLToImage",
            url: source,
            width: width,
            height: height,
            extension: extension,
            resourceId: resourceId,
        };
    }

    static scaleImage(source, width, resourceId) {
        return {
            service: "creatives",
            type: "scaleImage",
            url: source,
            width: width,
            resourceId: resourceId,
        };
    }

    static createProduct(name, product) {
        return {
            service: "publish",
            type: "product",
            name: name,
            product: product,
        };
    }

    static createVideo(output, result, resourceId) {
        const settings = {
            comp: output.comp,
            priority: 1,
            tdtemplatedata: result.tdtemplatedata,
            template: {
                identifier: output.templateId,
                url: output.templateUrl,
            },
        };
        if (output.stillTime) settings.stillTime = output.stillTime;
        // if (output.outputFilename) settings.outputFilename = output.outputFilename
        return {
            service: "aftereffectsrenderer",
            type: "renderVideo",
            resourceId: resourceId,
            imageResourceId: `still_${resourceId}`,
            //projectName: output.projectName,
            settings: settings,
        };
    }

    //Be sure not to create empty parallel tasks.
    static getParallelTasks = (tasks, status, progress) => {
        if (Array.isArray(tasks) && tasks.length > 0) {
            return this.createParallel(tasks);
        } else {
            return {
                service: "publish",
                type: "status",
                status: status,
                progress: progress,
            };
        }
    };

    /**
     *
     * @param {object} products
     * @param {object} material
     * @param {object} trainings
     * @param {object} company
     * @param {object} presets
     * @param {string} lang
     * @return {array}
     *
     */
    static build(products, material, trainings, savedTrainings, company, presets, lang) {
        // console.log('EH build tasklist', products, material, trainings, company, presets);
        const buildVersion = uuidv4();

        const jsonTasks = [];
        const urlEncodeTasks = [];
        const httpRequestTasks = [];
        const resourceToFileTasks = [];
        const stringTasks = [];
        const productTasks = [];
        const thumbTasks = [];
        const publishTasks = [];
        const debugTasks = [];

        const archive = {
            service: "files",
            type: "archive",
            files: {
                mp4: {},
                pdf: {},
                images: {},
                html: {},
                eml: {},
                download: {},
            },
            resourceId: `zip_${buildVersion}`,
        };

        Object.keys(products).forEach((prodKey) => {
            const thisProduct = products[prodKey];
            const thisMaterial = material[prodKey];

            if (thisProduct && thisMaterial) {
                const mmToPixel = Core.mmToPixel();
                const fileName = Core.slugify(i18n.t(`material.${prodKey}.title`));

                let result;
                let resourceId = `${prodKey}_${buildVersion}`;
                let iterations = ["0"];
                let variant = 1;
                let iterateVariant = 1;

                switch (thisMaterial.output.type) {
                    case "pdf":
                        result = Export.toHtmlTemplate(
                            thisProduct,
                            trainings,
                            savedTrainings,
                            thisMaterial,
                            company,
                            presets,
                            lang,
                            iterateVariant
                        );
                        let width = Math.round(thisMaterial.output.width * mmToPixel);
                        let height = Math.round(thisMaterial.output.height * mmToPixel);
                        let pageRange = thisMaterial.output.pageRange;

                        // Care for products with orientation as an option.
                        if (thisProduct.orientation) {
                            const orientation = thisProduct.orientation;
                            if (orientation === "port") {
                                if (width > height) {
                                    height = Math.round(thisMaterial.output.width * mmToPixel);
                                    width = Math.round(thisMaterial.output.height * mmToPixel);
                                }
                            } else {
                                if (height > width) {
                                    height = Math.round(thisMaterial.output.width * mmToPixel);
                                    width = Math.round(thisMaterial.output.height * mmToPixel);
                                }
                            }
                        }

                        // Care for products which should produce multiple outputs.
                        if (thisMaterial.output.iterate && thisProduct[thisMaterial.output.iterate]) {
                            iterations = thisProduct[thisMaterial.output.iterate].filter(
                                (item) => {
                                    return item !== "-1";
                                }
                            );
                        }

                        while (iterations.length > 0) {
                            const thisResourceId = `${resourceId}_${variant}`;

                            jsonTasks.push(
                                this.createResourceToFile(
                                    JSON.stringify(result),
                                    "json",
                                    `json_${thisResourceId}`
                                )
                            );

                            urlEncodeTasks.push(
                                this.createUrlEncode(
                                    `{{json_${thisResourceId}}}`,
                                    `encoded_${thisResourceId}`
                                )
                            );

                            stringTasks.push(
                                this.createString(
                                    `${process.env.TEMPLATE_HOST}${prodKey}/index.html?resourceLoc={{id}}`,
                                    { id: `{{encoded_${thisResourceId}}}` },
                                    `url_${thisResourceId}`
                                )
                            );

                            productTasks.push(
                                this.createPDF(
                                    `{{url_${thisResourceId}}}`,
                                    width,
                                    height,
                                    pageRange,
                                    thisResourceId
                                )
                            );

                            productTasks.push(
                                this.createImage(
                                    `{{url_${thisResourceId}}}`,
                                    width,
                                    height,
                                    "jpg",
                                    `image_${thisResourceId}`
                                )
                            );

                            thumbTasks.push(
                                this.scaleImage(
                                    `{{image_${thisResourceId}}}`,
                                    60,
                                    `thumb_${thisResourceId}`
                                )
                            );

                            publishTasks.push(
                                this.createProduct(`${prodKey}_${variant}`, {
                                    fileName: `${fileName}_${variant}.pdf`,
                                    url: `{{${thisResourceId}}}`,
                                    thumb: `{{thumb_${thisResourceId}}}`,
                                    cat: prodKey,
                                    pages: `{{${thisResourceId}_pages}}`,
                                })
                            );

                            if (thisMaterial.output.download) {
                                archive.files.pdf[
                                    `${fileName}_${variant}.pdf`
                                ] = `{{${thisResourceId}}}`;
                            }

                            iterations.shift();
                            variant++;

                            // If there's an iteration left, edit product to build new result.
                            if (iterations.length > 0) {
                                if (
                                    thisMaterial.output &&
                                    thisMaterial.output.iterateVariant
                                ) {
                                    const max = thisMaterial.output.iterateVariant.num;
                                    iterateVariant++;
                                    iterateVariant = iterateVariant > max ? 1 : iterateVariant;
                                }

                                const newProduct = {
                                    ...thisProduct,
                                    [thisMaterial.output.iterate]: iterations,
                                };

                                result = Export.toHtmlTemplate(
                                    newProduct,
                                    trainings,
                                    savedTrainings,
                                    thisMaterial,
                                    company,
                                    presets,
                                    lang,
                                    iterateVariant
                                );
                            }
                        }
                        break;
                    case "image":
                        result = Export.toHtmlTemplate(
                            thisProduct,
                            trainings,
                            savedTrainings,
                            thisMaterial,
                            company,
                            presets,
                            lang,
                            iterateVariant
                        );

                        // Care for products which should produce multiple outputs.
                        if (thisMaterial.output.iterate && thisProduct[thisMaterial.output.iterate]) {
                            iterations = thisProduct[thisMaterial.output.iterate].filter(
                                (item) => {
                                    return item !== "-1";
                                }
                            );
                        }

                        while (iterations.length > 0) {
                            const thisResourceId = `${resourceId}_${variant}`;

                            jsonTasks.push(
                                this.createResourceToFile(
                                    JSON.stringify(result),
                                    "json",
                                    `json_${thisResourceId}`
                                )
                            );

                            urlEncodeTasks.push(
                                this.createUrlEncode(
                                    `{{json_${thisResourceId}}}`,
                                    `encoded_${thisResourceId}`
                                )
                            );

                            stringTasks.push(
                                this.createString(
                                    `${process.env.TEMPLATE_HOST}${prodKey}/index.html?resourceLoc={{id}}`,
                                    { id: `{{encoded_${thisResourceId}}}` },
                                    `url_${thisResourceId}`
                                )
                            );

                            productTasks.push(
                                this.createImage(
                                    `{{url_${thisResourceId}}}`,
                                    thisMaterial.output.width,
                                    thisMaterial.output.height,
                                    "png",
                                    `image_${thisResourceId}`
                                )
                            );

                            thumbTasks.push(
                                this.scaleImage(
                                    `{{image_${thisResourceId}}}`,
                                    60,
                                    `thumb_${thisResourceId}`
                                )
                            );

                            publishTasks.push(
                                this.createProduct(`${prodKey}_${variant}`, {
                                    fileName: `${fileName}_${variant}.png`,
                                    url: `{{image_${thisResourceId}}}`,
                                    thumb: `{{thumb_${thisResourceId}}}`,
                                    cat: prodKey,
                                })
                            );

                            if (thisMaterial.output.download) {
                                archive.files.images[
                                    `${fileName}_${variant}.png`
                                ] = `{{image_${thisResourceId}}}`;
                            }

                            iterations.shift();
                            variant++;

                            // If there's an iteration left, edit product to build new result.
                            if (iterations.length > 0) {
                                if (
                                    thisMaterial.output &&
                                    thisMaterial.output.iterateVariant
                                ) {
                                    const max = thisMaterial.output.iterateVariant.num;
                                    iterateVariant++;
                                    iterateVariant = iterateVariant > max ? 1 : iterateVariant;
                                }

                                const newProduct = {
                                    ...thisProduct,
                                    [thisMaterial.output.iterate]: iterations,
                                };

                                result = Export.toHtmlTemplate(
                                    newProduct,
                                    trainings,
                                    savedTrainings,
                                    thisMaterial,
                                    company,
                                    presets,
                                    lang,
                                    iterateVariant
                                );
                            }
                        }
                        break;
                    case "mp4":
                        result = Export.toMp4(
                            thisProduct,
                            trainings,
                            savedTrainings,
                            thisMaterial,
                            company,
                            presets,
                            lang
                        );

                        productTasks.push(
                            this.createVideo(thisMaterial.output, result, resourceId)
                        );

                        thumbTasks.push(
                            this.scaleImage(
                                `{{still_${resourceId}}}`,
                                60,
                                `thumb_${resourceId}`
                            )
                        );

                        publishTasks.push(
                            this.createProduct(prodKey, {
                                fileName: `${fileName}.mp4`,
                                url: `{{${resourceId}}}`,
                                thumb: `{{thumb_${resourceId}}}`,
                                cat: prodKey,
                            })
                        );

                        if (thisMaterial.output.download) {
                            archive.files.mp4[`${fileName}.mp4`] = `{{${resourceId}}}`;
                        }

                        break;
                    case "eml":
                        result = Export.toHtmlTemplate(
                            thisProduct,
                            trainings,
                            savedTrainings,
                            thisMaterial,
                            company,
                            presets,
                            lang,
                            iterateVariant
                        );

                        jsonTasks.push(
                            this.createResourceToFile(
                                JSON.stringify(result),
                                "json",
                                `json_${resourceId}`
                            )
                        );

                        urlEncodeTasks.push(
                            this.createUrlEncode(
                                `{{json_${resourceId}}}`,
                                `encoded_${resourceId}`
                            )
                        );

                        stringTasks.push(
                            this.createString(
                                `${process.env.TEMPLATE_HOST}${thisMaterial.output.template}/index.php?resourceLoc={{id}}`,
                                { id: `{{encoded_${resourceId}}}` },
                                `url_${resourceId}`
                            )
                        );

                        stringTasks.push(
                            this.createString(
                                `${process.env.TEMPLATE_HOST}${thisMaterial.output.template}/generateEML.php?resourceLoc={{id}}`,
                                { id: `{{encoded_${resourceId}}}` },
                                `urlEML_${resourceId}`
                            )
                        );

                        httpRequestTasks.push(
                            this.createHttpRequest(
                                `{{url_${resourceId}}}`,
                                {},
                                "GET",
                                `html_${resourceId}`
                            )
                        );

                        httpRequestTasks.push(
                            this.createHttpRequest(
                                `{{urlEML_${resourceId}}}`,
                                {},
                                "GET",
                                `eml_${resourceId}`
                            )
                        );

                        resourceToFileTasks.push(
                            this.createResourceToFile(
                                `{{html_${resourceId}}}`,
                                "html",
                                `htmlFile_${resourceId}`
                            )
                        );

                        resourceToFileTasks.push(
                            this.createResourceToFile(
                                `{{eml_${resourceId}}}`,
                                "eml",
                                `emlFile_${resourceId}`
                            )
                        );

                        productTasks.push(
                            this.createImage(
                                `{{url_${resourceId}}}`,
                                thisMaterial.output.width,
                                thisMaterial.output.height,
                                "jpg",
                                `image_${resourceId}`
                            )
                        );

                        thumbTasks.push(
                            this.scaleImage(
                                `{{image_${resourceId}}}`,
                                60,
                                `thumb_${resourceId}`
                            )
                        );

                        publishTasks.push(
                            this.createProduct(`${prodKey}_html`, {
                                fileName: `${fileName}_html.html`,
                                url: `{{htmlFile_${resourceId}}}`,
                                thumb: `{{thumb_${resourceId}}}`,
                                cat: prodKey,
                            })
                        );

                        publishTasks.push(
                            this.createProduct(`${prodKey}_eml`, {
                                fileName: `${fileName}_eml.eml`,
                                url: `{{emlFile_${resourceId}}}`,
                                thumb: `{{thumb_${resourceId}}}`,
                                cat: prodKey,
                            })
                        );

                        if (thisMaterial.output.download) {
                            archive.files.html[
                                `${fileName}_html.html`
                            ] = `{{htmlFile_${resourceId}}}`;
                            archive.files.eml[
                                `${fileName}_eml.eml`
                            ] = `{{emlFile_${resourceId}}}`;
                        }

                        break;
                    case "download":
                        result = Export.toDownload(thisMaterial, lang);

                        publishTasks.push(
                            this.createProduct(`${prodKey}_html`, {
                                fileName: result.fileName,
                                url: result.url,
                                thumb: result.thumbUrl,
                                cat: prodKey,
                            })
                        );

                        if (thisMaterial.output.download) {
                            archive.files.download[result.fileName] = result.url;
                        }
                        break;
                    default:
                        console.warn("undefined type:", thisMaterial.output.type);
                }
            }
        });
        return [
            {
                service: "publish",
                type: "status",
                status: "Preparing products",
                progress: 0,
            },
            ...jsonTasks,
            ...urlEncodeTasks,
            ...stringTasks,
            ...httpRequestTasks,
            ...resourceToFileTasks,
            {
                service: "publish",
                type: "status",
                status: "Creating products",
                progress: 10,
            },
            this.getParallelTasks(productTasks, "no products found to create", 15),
            {
                service: "publish",
                type: "status",
                status: "Creating thumbnails",
                progress: 60,
            },
            this.getParallelTasks(thumbTasks, "no thumbnails found to create", 65),
            {
                service: "publish",
                type: "status",
                status: "Publishing products",
                progress: 70,
            },
            ...publishTasks,
            {
                service: "publish",
                type: "status",
                status: "Compressing products",
                progress: 90,
            },
            archive,
            this.createProduct("zip", `{{zip_${buildVersion}}}`),
            ...debugTasks,
            {
                service: "publish",
                type: "status",
                status: "Finished!",
                progress: 100,
            },
        ];
    }

    /**
     * Calculate the number of output products that publih will create based on the selected products.
     * @param {object} products
     * @param {object} material
     * @returns number of products that will be created.
     */
    static resultsCount(products, material) {
        let count = 0;

        Object.keys(products).forEach((prodKey) => {
            const thisProduct = products[prodKey];
            const thisMaterial = material[prodKey];

            if (thisProduct && thisMaterial) {
                if (thisMaterial.output && thisMaterial.output.iterate) {
                    // Products that iterate, can output more than one product.
                    const productCount = thisProduct[thisMaterial.output.iterate].length;
                    count = count + productCount;
                } else {
                    count++;
                }
            }
        });

        return count;
    }
}

export default Tasks;
