import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';

export const styles = theme => ({
    root: {
        height: theme.spacing(10)
    },
});

function Toolbar(props) {
  const { classes } = props;

    return (
        <MuiToolbar
          className={classes.root}
            {...props}
        />
    )
}

export default withStyles(styles)(Toolbar);
