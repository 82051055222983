import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import StartIcon from './Start.icon.component.jsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../../components/Paper.component';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '../../../components/Button.component';

const styles = theme => ({
    root: {
        height: 'auto',
        transition: theme.transitions.create('height'),
    },
    paper: {
        transition: theme.transitions.create('transform'),
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.03)'
        }
    },
    icon: {
        transition: theme.transitions.create('background-color'),
        width: theme.spacing(8),
        height: theme.spacing(8),
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },
    iconFirst: {
        backgroundColor: theme.palette.secondary.main
    },
    iconSecond: {
        backgroundColor: theme.palette.primary.main,
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(.5) 
    },
    caption: {
        marginBottom: theme.spacing(2),
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    info: {
        position: 'relative',
        textAlign: 'left',
        '&:after': {
            bottom: ' 100%',
            border: 'solid transparent',
            content: '" "',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderWidth: theme.spacing(1), 
            marginLeft: theme.spacing(-1),
            transition: theme.transitions.create('left'),
        }
    },
    infoTxt: {
        color: theme.palette.common.white,
        opacity: .8,
    },
    startIcon: {
        width: '80%',
        height: '80%',
    },
    buttonSecondary: {
        color: theme.palette.common.black
    }
});

function SelectLane(props) {
    const {
        classes,
        t,
        lanes,
        onSelectLane
    } = props;

    return (
        <Grid container spacing={3}>
            {lanes.map((item, key) =>
                <Grid item xs={6} key={key}>
                    <Paper
                        fullHeight
                        onClick={() => onSelectLane(key)}
                        className={classNames(
                            classes.paper,
                            {
                                [classes.first]: key === 0,
                                [classes.second]: key === 1,
                                [classes.third]: key === 2
                            }
                        )}
                        paddingMedium
                    >
                        <Avatar
                            className={classNames(
                                classes.icon,
                                {
                                    [classes.iconFirst]: key === 0,
                                    [classes.iconSecond]: key === 1
                                }
                            )}
                        >
                            <StartIcon
                                icon={item.id}
                                className={classes.startIcon}
                            />
                        </Avatar>
                        <Typography
                            variant="h3"
                            className={classes.title}
                        >
                            {t(`start.lanes.${item.id}.title`)}
                        </Typography>
                        <Typography
                            variant="caption"
                            className={classes.caption}
                            component="div"
                        >
                            {t(`start.lanes.${item.id}.desc.short`)}
                        </Typography>

                        {key === 0 ? (
                            <Button
                                secondary
                                size="small"
                                style={{ color: '#000000', boxShadow: 'none' }}
                            >
                                {t('common.buttons.start')}
                            </Button>
                        ) : (
                            <Button
                                primary
                                size="small"
                                style={{ color: '#ffffff', boxShadow: 'none' }}
                            >
                                {t('common.buttons.start')}
                            </Button>
                        )}

                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}

export default withTranslation()(withStyles(styles)(SelectLane));