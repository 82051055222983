import React from 'react';
import CampaignAction from '../../reducers/Campaign/campaign.actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import store from '../../store';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import StartSelect from './components/Start.select.component.jsx';
import StartProducts from './components/Start.products.component.jsx';
import Body from '../../components/Body.component';
import TopBar from '../../components/TopBar';
import LayoutBody from '../../components/LayoutBody.component';
import Loader from '../../components/Loader.component';
import Dialog from '../../components/Dialog.component';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import lanes from '../../data/lanes.json';

const styles = theme => ({
    h1: {
        marginBottom: theme.spacing(1)
    },
    h2: {
        marginBottom: theme.spacing(1.25)
    },
    body: {
        marginBottom: theme.spacing(4)
    },
    companyName: {
        fontWeight: 'bold'
    }
});
class StartController extends React.Component {

    static mapStateToProps(state, props) {
        const { campaign } = state;
        return {
            ...props,
            loading:        campaign.loading,
            loadingPartial: campaign.loadingPartial,
            campaignList:   campaign.list,
            current:        campaign.current,
            company:        campaign.assets.Company,
            lane:           campaign.lane,
            courses:        campaign.assets.CourseList,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedLane: -1,
            lanes: lanes.lanes,
            itemToEdit: {},
        }

        if (props.loading === undefined) {
            props.init();
        }
        else {
            props.getList();
        }
    }

    selectLane = (laneKey) => {
        const { lanes } = this.state;
        const { t } = this.props;
        let { current } = this.props;
        if (laneKey >= 0) {
            const lane = lanes[laneKey];
            const newLane = {
                ...lane,
                steps: Object.assign(
                    [...lane.steps],
                    {
                        [0]:
                        Object.assign({}, lane.steps[0], {complete: true})
                    }
                )
            }
            current = {
                ...current,
                data: {
                    lane: newLane,
                }
            }
            this.props.setLane(newLane).then(
                store.dispatch(push(`/form${t(`steps.${lane.steps[1].id}.path`)}`))
            );
        }
    }

    getCampaign = async (e, campaign, copy, download) => {
        const { lanes } = this.state;

        await this.props.getSingle(campaign.id);
        
        const { current, t, courses } = this.props;

        if (copy) {
            await this.props.create(current);
            current.title = `${t('common.fields.copy')}: ${current.title}`;
            this.props.update(current);
        }

        if (current.type) {
            const type = current.type;
            let newLane = lanes.find(item => {
                return item.id === type;
            })

            // Legeacy code because custom lane has been removed.
            // Transfrom custom campaigns to product campaign.
            if (!newLane) {
                newLane = lanes.find(item => {
                    return item.id === 'products';
                })
            }

            let entryStep = 1;

            if (type === 'campaign') {
                newLane = {
                    ...newLane,
                    steps: Object.assign(
                        [...newLane.steps],
                        {
                            [entryStep]:
                            Object.assign({}, newLane.steps[entryStep], {complete: true})
                        }
                    )
                }
                entryStep = 2;
            }

            if (download) {
                // Set results step to complete to indicate results can be shown without checking the current campaign.
                const newSteps = [...newLane.steps];
                newSteps[newSteps.length - 1].complete = true
                newLane = {
                    ...newLane,
                    steps: newSteps
                }
                entryStep = newLane.steps.length - 1;
            }

            this.props.setLane(newLane).then(
                store.dispatch(push(`/form${t(`steps.${newLane.steps[entryStep].id}.path`)}${entryStep > 1 ? '?jump=' + entryStep : ''}`))
            )
        }

        else {
            console.warn('Campaign not complete.')
        }
    }

    removeCampaign = (e, campaign) => {
        const { t } = this.props;
        const itemToEdit = {
            actionType: 'remove',
            actionText: t('start.archive.remove'),
            ...campaign
        }
        this.setState({
            itemToEdit,
        })
    }

    handleCancel = () => {
        this.setState({
            itemToEdit: {}
        })
    }

    handleOK = async (e, item) => {
        switch(item.actionType) {
            case 'remove':
                await this.props.remove(item.id);
                this.props.getList();
                this.handleCancel();
                break;
            // case 'copy':

            //     break;
            // case 'edit':

            //     break;
            default:
                this.handleCancel();
        }
    }

    getIntroText = () => {
        const introText = this.props.t('start.intro');
        const companyName = this.props.company.CompanyName || this.props.company.Alias || 'Company Name';
        return introText.split(' ').map((word, index) => {
            if (word === '[COMPANY_NAME]') {
                return <span className={this.props.classes.companyName} key={index}>{companyName} </span>
            }
            else if (word === '[COMPANY_NAME],') {
                return <React.Fragment key={index}><span className={this.props.classes.companyName}>{companyName}</span>, </React.Fragment>
            }
            else {
                return word + " "
            }
        });
    }

    render() {
        const {
            classes,
            t,
            campaignList,
            loading,
            loadingPartial,
        } = this.props;
        const {
            lanes,
            itemToEdit,
        } = this.state;

        return (
            <React.Fragment>
                <TopBar
                    backText={t('common.header.title')}
                    helpText={t('common.header.button')}
                    showHelp
                    loading={loading}
                />
                <Body>
                    <Loader isLoading={loading}>
                        <LayoutBody
                            component="section"
                            width="medium"
                            paddingTop
                            dark
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" classes={{ root: classes.h1 }}>{t('start.title')}</Typography>
                                    <Typography variant="body1" classes={{ root: classes.body }}>
                                        {this.getIntroText()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </LayoutBody>
                        <LayoutBody
                            component="section"
                            width="medium"
                            textAlignCenter
                            paddingBottom
                            dark
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <StartSelect
                                        onSelectLane={this.selectLane}
                                        lanes={lanes}
                                    />
                                </Grid>
                            </Grid>
                        </LayoutBody>
                        <Divider />
                        <LayoutBody
                            component="section"
                            width="medium"
                            paddingTop
                            paddingBottom
                        >
                            <Typography variant="h2" classes={{ root: classes.h2 }}>
                                {t('start.archive.title')}
                            </Typography>
                            <Typography variant="body1" classes={{ root: classes.body }}>
                                {t('start.archive.desc')}
                            </Typography>
                            <StartProducts
                                onGetCampaign={this.getCampaign}
                                onRemoveCampaign={this.removeCampaign}
                                loading={loadingPartial}
                                data={campaignList}
                            />
                        </LayoutBody>
                        <Dialog
                            open={Object.keys(itemToEdit).length > 0}
                            title={t('start.archive.delete.title')}
                            text={t('start.archive.delete.description')}
                            onCancel={this.handleCancel}
                            cancelText={t('common.buttons.cancel')}
                            onConfirm={(e) => this.handleOK(e, itemToEdit)}
                            confirmText={t('common.buttons.delete')}
                            type="delete"
                        />
                    </Loader>
                </Body>
            </React.Fragment>
        );
    }
}


export default withTranslation()(withStyles(styles)(connect(StartController.mapStateToProps, {
    init: CampaignAction.init,
    update: CampaignAction.update,
    create: CampaignAction.create,
    remove: CampaignAction.remove,
    clear:  CampaignAction.clear,
    getList: CampaignAction.getList,
    getSingle: CampaignAction.getSingle,
    setLane:  CampaignAction.setLane
})(StartController)));