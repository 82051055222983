import React from 'react';
import LayoutBody from './LayoutBody.component';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends React.Component {

    render() {
        const { children, isLoading } = this.props;

        if (!isLoading) {
            return children;
        }

        return (
            <LayoutBody
                component="section"
                width="small"
                textAlignCenter
                fullHeight
            >
                <CircularProgress />
            </LayoutBody>
        );
    }
}

export default Loader;
